import React from 'react'

const WelcomeFr = () => {
  return (
    <section className="intro-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="intro-title pt-60 pb-60 text-center">
              <h3 className="title">
                Bienvenue à l’outil d’inscription et de suivi du programme
                Expérience découverte Lincoln!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeFr
