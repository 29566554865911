import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Navbar from '../components/navbar'
import Banner from '../components/banner'

class Header extends Component {
  render() {
    const { profile, auth, showNavbar } = this.props

    return (
      <React.Fragment>
        <Navbar profile={profile} auth={auth} showNavbar={showNavbar} />
        <Banner />
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  showNavbar: PropTypes.bool,
}
Header.defaultProps = {
  showNavbar: true,
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(Header)
