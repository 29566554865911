import React, { useState, useEffect } from 'react'
import { updateLddUser } from '../data/actions/authActions'
import { connect } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Header from '../containers/header'
import Footer from '../components/footer'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { compose } from 'redux'
import { BallTriangle } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const UpdateUserAccount = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [dealerName, setDealerName] = useState('')
  const [dealerCode, setDealerCode] = useState('')
  const [region, setRegion] = useState('')
  const [dealerType, setDealerType] = useState('')
  const [userRole, setUserRole] = useState('')
  const [password, setPassword] = useState('')
  const [id, setId] = useState('')

  useEffect(() => {
    if (location.state?.user) {
      setEmail(location.state.user.email)
      setDealerName(location.state.user.dealerName)
      setDealerCode(location.state.user.dealerCode)
      setRegion(location.state.user.region)
      setDealerType(location.state.user.dealerType)
      setUserRole(location.state.user.userRole)
      setId(location.state.user.id)
    }
  }, [location])

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const hasWhiteSpace = s => {
    return s && s.indexOf(' ') >= 0
  }

  const validateEmail = mail => {
    if (mail && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,16})+$/.test(mail)) {
      return true
    }
    return false
  }

  const updateUserSuccessCallback = data => {
    if (data) {
      toast.success(`${data}`)
    } else {
      toast.success('Success.')
    }
    navigate('/thank-you')
  }

  const updateUserErrorCallback = err => {
    console.log(err)
    if (err) {
      toast.error(`${err}`)
    } else {
      toast.error(
        'An unexpeccted error occurred. Please review the form fields and retry.'
      )
    }
  }

  const updatePassword = () => {
    if (!password || hasWhiteSpace(password) || password.length < 6) {
      updateUserErrorCallback(
        'Password has invalid characters or spaces or does not meet the minimum length of 6 characters.'
      )
      return false
    }
    props.updateLddUser(
      {
        email,
        dealerName,
        dealerCode,
        region,
        dealerType,
        userRole,
        password,
        id,
        updateStatus: 2,
      },
      updateUserSuccessCallback,
      updateUserErrorCallback
    )
  }

  const updateEmail = () => {
    if (!email || hasWhiteSpace(email) || !validateEmail(email)) {
      updateUserErrorCallback(
        'Email has invalid characters or spaces or does not conform to an email pattern.'
      )
      return false
    }
    props.updateLddUser(
      {
        email,
        dealerName,
        dealerCode,
        region,
        dealerType,
        userRole,
        password,
        id,
        updateStatus: 1,
      },
      updateUserSuccessCallback,
      updateUserErrorCallback
    )
  }

  const updateUserInfo = () => {
    if (!email || hasWhiteSpace(email) || !validateEmail(email)) {
      updateUserErrorCallback(
        'Email has invalid characters or spaces or does not conform to an email pattern.'
      )
      return false
    }
    props.updateLddUser(
      {
        dealerName,
        dealerCode,
        region,
        dealerType,
        userRole,
        id,
        updateStatus: 3,
      },
      updateUserSuccessCallback,
      updateUserErrorCallback
    )
  }

  const confirmSubmit = (e, message, action) => {
    e.preventDefault()
    confirmAlert({
      title: 'Confirm Action',
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => action(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  const { auth } = props
  if (!auth.uid) return <Navigate to="/" />
  if (
    props.profile &&
    props.profile.userRole &&
    props.profile.userRole === 'Admin'
  ) {
    return (
      <React.Fragment>
        <Header />
        <div className="container mt-20 mb-20">
          <form>
            <h5 className="grey-text text-darken-3">Update User Account</h5>
            <div className="input-field">
              <label htmlFor="dealerName">Dealer Name</label>
              <input
                className="form-control"
                type="text"
                id="dealerName"
                value={dealerName}
                onChange={e => setDealerName(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor="dealerCode">Dealer Code</label>
              <input
                className="form-control"
                type="text"
                id="dealerCode"
                value={dealerCode}
                onChange={e => setDealerCode(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor="region">Region</label>
              <select
                className="form-control"
                type="text"
                id="region"
                name="region"
                onChange={e => setRegion(e.target.value)}
                value={region}
                required
              >
                <option value="">Please select</option>
                <option value="Central">Central</option>
                <option value="Eastern">Eastern</option>
                <option value="Western">Western</option>
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="dealerType">Dealer Type</label>
              <select
                required
                className="form-control"
                type="text"
                id="dealerType"
                name="dealerType"
                onChange={e => setDealerType(e.target.value)}
                value={dealerType}
              >
                <option value="">Please select</option>
                <option value="Contact">Contact</option>
                <option value="Non-Contact">Non-Contact</option>
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="userRole">User Role</label>
              <select
                required
                className="form-control"
                type="text"
                id="userRole"
                name="userRole"
                value={userRole}
                onChange={e => setUserRole(e.target.value)}
              >
                <option value="">Please select</option>
                <option value="Admin">Admin</option>
                <option value="Dealer">Dealer</option>
                <option value="Regional">Regional</option>
                <option value="National">National</option>
              </select>
            </div>
            <div className="row form-group" style={{ marginTop: '1em' }}>
              <div className="col-md-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  id="updateUserInfo"
                  onClick={e => {
                    confirmSubmit(
                      e,
                      'Are you sure you want to update this user info?',
                      updateUserInfo
                    )
                  }}
                >
                  Update User Info
                </button>
              </div>
            </div>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                required
                className="form-control"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="row form-group" style={{ marginTop: '1em' }}>
              <div className="col-md-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  id="updateEmail"
                  onClick={e => {
                    confirmSubmit(
                      e,
                      'Are you sure you want to update this email?',
                      updateEmail
                    )
                  }}
                >
                  Update Email
                </button>
              </div>
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                className="form-control"
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="row form-group" style={{ marginTop: '1em' }}>
              <div className="col-md-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  id="updatePassword"
                  onClick={e => {
                    confirmSubmit(
                      e,
                      'Are you sure you want to update this pasword?',
                      updatePassword
                    )
                  }}
                >
                  Update Password
                </button>
              </div>
            </div>
          </form>
        </div>
        <Footer />
        <ToastContainer
          position="top-center"
          hideProgressBar="true"
          autoClose="3000"
          style={{ width: '600px' }}
        />
      </React.Fragment>
    )
  } else {
    return (
      <div
        style={{
          minWidth: '100% !important',
          minHeight: '100% !important',
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          top: '120px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '5em' }}>
          <BallTriangle
            color="#c1bb00"
            height={80}
            width={80}
            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
          />
          <h1 style={{ paddingTop: '1em' }}>Loading</h1>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateLddUser: (
      newUser,
      updateUserSuccessCallback,
      updateUserErrorCallback
    ) =>
      dispatch(
        updateLddUser(
          newUser,
          updateUserSuccessCallback,
          updateUserErrorCallback
        )
      ),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpdateUserAccount
)
