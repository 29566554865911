import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import Header from '../containers/header'
import Footer from '../components/footer'

const ClientDetails = props => {
  const params = useParams()
  const { id } = params
  const [client, setClient] = React.useState()

  React.useEffect(() => {
    if (props.clients) {
      const _client = props.clients[id] || null
      setClient(_client)
    }
  }, [id, props.clients])

  const { auth } = props
  if (!auth.uid) return <Navigate to="/" />

  if (client) {
    return (
      <React.Fragment>
        <Header />
        <div class="container pt-60 pb-60">
          <div class="row">
            <div class="col-sm-12 registration-section-title pb-20">
              <h4 class="section-title">Client Details</h4>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Dealer Code</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.dealerCode}</p>
            </div>
            <div class="col-md-3 form-label">
              <p class="label">Order Number</p>
            </div>
            <div class="col-md-3">
              <p class="read-only text-right">{id}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Client First Name</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.clientFirstName}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Client Last Name</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.clientLastName}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Address</p>
            </div>
            <div class="col-md-9">
              <p class="read-only">{client.clientAddress}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">City</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.clientCity}</p>
            </div>
            <div class="col-md-1 form-label">
              <p class="label">Province</p>
            </div>
            <div class="col-md-2">
              <p class="read-only">{client.clientProvince}</p>
            </div>
            <div class="col-md-1 form-label">
              <p class="label">Postal Code</p>
            </div>
            <div class="col-md-2">
              <p class="read-only">{client.clientPostalCode}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Client Email</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.clientEmail}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Client Cell Phone</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.clientCellPhone}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Client's Current Vehicle</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.currentVehicle}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Purchase intent timeframe</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.purchaseTimeframe}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Which Vehicle is the client interested in?</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.vehicleInterest}</p>
            </div>
          </div>
          {/* <div class="row form-group">
              <div class="col-md-3 form-label">
                <p class="label">What brought you to the dealership?</p>
              </div>
              <div class="col-md-3">
                <p class="read-only">{client.broughtDealership}</p>
              </div>
            </div> */}

          <div class="row form-group">
            <div class="col-md-3 form-label">
              <p class="label">Prefered Method of Communication</p>
            </div>
            <div class="col-md-3">
              <p class="read-only">{client.communicationMethod}</p>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-9 offset-md-3" />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  } else {
    return <div>Opps no client details here!</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  const clients = state.firestore.data.clients
  return {
    clients: clients,
    auth: state.firebase.auth,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'clients' }])
)(ClientDetails)
