import React from 'react'
import styled from 'styled-components'

const UpdatedOnInput = styled.input`
  width: 60%;
  border: none;
`

const UpdatedClientFormFr = props => {
  let { client, handleSubmit, handleChange, handleCheckbox } = props

  const follow = !client.followUp ? (
    <input
      type="date"
      className="form-control"
      id="followUp"
      name="followUp"
      required
      onChange={handleChange}
      value={client.followUp}
      //defaultValue={client.followUp}
    />
  ) : (
    <input
      type="date"
      className="form-control"
      id="followUp"
      name="followUp"
      onChange={handleChange}
      //defaultValue={client.followUp}
      required
      value={client.followUp}
    />
  )

  if (client) {
    return (
      <React.Fragment>
        <div className="container pt-60 pb-60">
          <form className="interior-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 registration-section-title pb-20">
                <h4 className="section-title">
                  FORMULAIRE DE MISE À JOUR DU CLIENT
                </h4>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Indicatif Détaillant</p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="dealerCode"
                  onChange={handleChange}
                  name="dealerCode"
                  defaultValue={client.dealerCode}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Prénom du client</p>
              </div>
              <div className="col-md-9 input-form">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="clientFirstName"
                  onChange={handleChange}
                  name="clientFirstName"
                  value={client.clientFirstName}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Nom du client</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  id="clientLastName"
                  className="form-control"
                  onChange={handleChange}
                  name="clientLastName"
                  value={client.clientLastName}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Adresse</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="clientAddress"
                  name="clientAddress"
                  onChange={handleChange}
                  value={client.clientAddress}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Ville</p>
              </div>
              <div className="col-md-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  id="clientCity"
                  name="clientCity"
                  value={client.clientCity}
                />
              </div>
              <div className="col-md-1 form-label">
                <p className="label">Province</p>
              </div>
              <div className="col-md-2">
                {/* <select className="form-control" onChange={handleChange} multiple={false} name="clientProvince" id="clientProvince">
                  <option value="" disabled>Select Province</option>
                  <option value="AB" >Alberta</option>
                  <option value="BC" >British Columbia</option>
                  <option value="MB" >Manitoba</option>
                  <option value="NB" >New Brunswick</option>
                  <option value="NL" >Newfoundland</option>
                  <option value="NS" >Nova Scotia</option>
                  <option value="NW" >Northwest Territories</option>
                  <option value="ON" >Ontario</option>
                  <option value="QC" >Quebec</option>
                  <option value="SK" >Saskatchewan</option>
                  <option value="YK" >Yukon</option>
                </select> */}
                <input
                  required
                  maxLength={2}
                  type="text"
                  className="form-control"
                  id="clientProvince"
                  onChange={handleChange}
                  name="clientProvince"
                  defaultValue={client.clientProvince}
                />
              </div>
              <div className="col-md-1 form-label">
                <p className="label">Code postal</p>
              </div>
              <div className="col-md-2">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="clientPostalCode"
                  onChange={handleChange}
                  name="clientPostalCode"
                  value={client.clientPostalCode}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Adresse électronique</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  id="clientEmail"
                  name="clientEmail"
                  value={client.clientEmail}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Téléphone portable du client</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="clientCellPhone"
                  onChange={handleChange}
                  name="clientCellPhone"
                  value={client.clientCellPhone}
                />
              </div>
            </div>
            <div className="row form-group mt-30">
              <div className="col-md-3 form-label">
                <p className="label">*CURRENT COMPETITIVE VEHICLE BRAND</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="currentVehicle"
                  onChange={handleChange}
                  name="currentVehicle"
                  defaultValue={client.currentVehicle}
                  placeholder="Current Competitive Brand"
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">*CURRENT COMPETITIVE VEHICLE VIN</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="testDriveVIN"
                  onChange={handleChange}
                  name="testDriveVIN"
                  defaultValue={client.testDriveVIN}
                  pattern="[A-Za-z0-9]{17}"
                  placeholder="VIN must be a 17-digit alphanumeric value"
                  maxLength={17}
                />
              </div>
            </div>
            <div className="row form-group mt-15">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">SUIVI EFFECTUÉ</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      {/* <input type="date" className="form-control" id="testDriveDate1" name="testDriveDate1" onChange={handleChange} />
                      <div className="input-group-append">
                        <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                      </div> */}
                      {follow}
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="fa fa-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-3 form-label">
                      <p className="label">Date Out</p>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input type="date" className="form-control " name="testDriveDate2" id="testDriveDate2" onChange={handleChange} />
                          <div className="input-group-append">
                            <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>             */}
              <div className="col-md-5">
                {/* <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">VÉHICULE ACHETÉ</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <input type="checkbox" name="hasPurchased" id="hasPurchased" onChange={handleChange} />
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">24h or 48h</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <select
                        value={client.maxLddMonth}
                        required
                        className="form-control"
                        onChange={handleChange}
                        multiple={false}
                        name="maxLddMonth"
                        id="maxLddMonth"
                      >
                        <option value="" disabled>
                          Sélectionner une Option
                        </option>
                        <option value="24h">24h</option>
                        <option value="48h">48h</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row form-group">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">APPROUVÉ</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <input type="checkbox" name="hasPurchased" id="hasPurchased" onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">REFUSÉ</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <input type="checkbox" name="hasPurchased" id="hasPurchased" onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="row form-group">
                  <div className="col-md-2 form-label">
                    <p className="label">Suprise And Delight Element Description</p>
                  </div>
                  <div className="col-md-10">
                    <input type="text" className="form-control" id="clientCellPhone" onChange={handleChange} name="clientCellPhone" value="Enter Description" />
                  </div>
                </div> 
 */}
            <div className="row form-group">
              <div className="col-md-2 form-label">
                <p className="label">Dealer Comments</p>
              </div>
              <div className="col-md-10">
                {/* <textarea
                    rows="7"
                    type="text"
                    className="form-control"
                    id="dealerComments"
                    onChange={this.handleChange}
                    name="dealerComments"
                    value={client.dealerComments}
                  >
                    Enter your comments here...
                  </textarea> */}
                <p>{client.dealerComments}</p>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-2 form-label">
                <p className="label">Comments</p>
              </div>
              <div className="col-md-10">
                <textarea
                  rows="7"
                  type="text"
                  className="form-control"
                  id="comments"
                  onChange={handleChange}
                  name="comments"
                  value={client.comments}
                ></textarea>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">APPROUVÉ / REFUSÉ</p>
                  </div>
                  <div className="col-md-3">
                    <div className="input-group approved">
                      <select
                        value={client.validated}
                        required
                        className="form-control"
                        onChange={handleChange}
                        multiple={false}
                        name="validated"
                        id="validated"
                      >
                        <option value="">Sélectionner une Option</option>
                        <option value="true">APPROUVÉ</option>
                        <option value="false">REFUSÉ</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">VÉHICULE ACHETÉ</p>
                  </div>
                  <div className="col-md-3">
                    <div className="input-group">
                      <input
                        type="checkbox"
                        name="hasPurchased"
                        id="hasPurchased"
                        value={client.hasPurchased}
                        onChange={handleCheckbox}
                        checked={
                          client.hasPurchased === 'on' ||
                          client.hasPurchased === 'true' ||
                          client.hasPurchased === true
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-group mt-15 pb-10">
              <div className="col-md-12">
                <div className="row">
                  <div className="form-label col-md-6">
                    <p className="label">
                      Cette Expérience découverte Lincoln a-t-elle eu lieu grâce
                      à un certificat de recommandation?
                    </p>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={handleCheckbox}
                      id="isReferralCert"
                      name="isReferralCert"
                      checked={
                        client.isReferralCert === 'on' ||
                        client.isReferralCert === 'true' ||
                        client.isReferralCert === true
                      }
                    />
                  </div>
                  <div className="form-label col-md-6">
                    <p className="label">
                      Si OUI, entrer le numéro de certificat de recommandation.
                    </p>
                    <input
                      disabled={!client.isReferralCert}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      id="referralCertNum"
                      name="referralCertNum"
                      value={client.referralCertNum}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-sm-12 text-center">
                <p>
                  All information must be completed in full in order to submit.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 text-right">
                <button className="btn btn-primary">Soumettre</button>
              </div>
              <div className="col-sm-6 text-right">
                <p>
                  Updated on:{' '}
                  <UpdatedOnInput
                    type="text"
                    name="updatedOn"
                    id="updatedOn"
                    value={client.updatedOn}
                    readOnly
                  />
                </p>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  } else {
    return <div>Opps no client details here!</div>
  }
}

export default UpdatedClientFormFr
