import React, { useEffect, useState, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import styled from 'styled-components'
import Select from 'react-select'
import { BallTriangle } from 'react-loader-spinner'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'

import _ from 'lodash'

import Header from '../containers/header'
import Footer from '../components/footer'
import NationalCSV from './nationalCSV'
import { Filter } from '../components/tableFilter'

import { firestore as db } from '../config/fbConfig'

import '../styles/table.css'
import './customStyles.css'

const TableDiv = styled.div`
  width: 100;
`

const NumberOfRecords = styled.div`
  font-weight: bold;
  display: inline-flex;
  margin-left: 2em;
`

const RegionalReportNew = props => {
  const [selectedYear, setSelectedYear] = useState({
    label: 'All',
    value: 'All',
  })
  const [totalRegisteredByYear, setTotalRegisteredByYear] = useState([])
  const [totalApprovedByYear, setTotalApprovedByYear] = useState([])
  const [totalSalesByYear, setTotalSalesByYear] = useState([])
  const [totalCreditsListForYear, setTotalCreditsListForYear] = useState(0)
  const [users, setUsers] = useState()
  const [usersAll, setUsersAll] = useState()
  const [clients, setClients] = useState()

  const columns = useMemo(
    () => [
      {
        header: 'Retailer',
        accessorKey: 'dealerCode',
      },
      {
        header: 'Retailer Name',
        accessorKey: 'dealerName',
      },
      {
        header: 'Region',
        accessorKey: 'region',
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: 'Total Registered Test drives',
        accessorKey: 'registeredTestDrives',
        enableColumnFilter: false,
      },
      {
        header: 'Approved Test Drives',
        accessorKey: 'approvedTestDrives',
        enableColumnFilter: false,
      },
      {
        header: 'Retailer LDD Allocation',
        accessorKey: 'lddMonth',
        enableColumnFilter: false,
      },
      {
        header: 'Total Retailer Credits',
        accessorKey: 'totalDealerCredits',
        enableColumnFilter: false,
      },
    ],
    []
  )
  const table = useReactTable({
    data: users,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  const [sortedTableData, setSortedTableData] = useState([])

  useEffect(() => {
    db.collection('users').onSnapshot(snapshot => {
      setUsersAll(
        snapshot.docs.map(doc => {
          const data = doc.data()
          return data
        })
      )
    })
    calculateClientSubmissionYear(props.clients)
  }, [])

  useEffect(() => {
    calculateUserTotals(usersAll, props.clients)
  }, [usersAll])

  useEffect(() => {
    if (!clients) {
      calculateClientSubmissionYear(props.clients)
    }
  }, [clients, props.clients])

  useEffect(() => {
    if (usersAll && !users && clients) {
      calculateUserTotals(usersAll, clients)
    }
  }, [users, clients, usersAll])

  useEffect(() => {
    calculateUserTotals(usersAll, clients)
  }, [selectedYear])

  useEffect(() => {
    if (users && users.length > 0) {
      const sortedData = table.getSortedRowModel().rows.map(row => row.original)
      setSortedTableData(sortedData)
    }
  }, [
    table.getState().pagination.pageIndex,
    table.getState().pagination.pageSize,
    table.getState().columnFilters,
    table.getState().sorting,
  ])

  const calculateClientSubmissionYear = clients => {
    if (clients) {
      clients = clients.map((client, index) => {
        client = _.cloneDeep(client)
        if (client.submission && !client.submissionYear) {
          try {
            let submissionYear = new Date(client.submission).getFullYear()
            client.submissionYear = submissionYear
          } catch (error) {
            //ignore
            console.error(error)
          }
        }
        return client
      })
      setClients(clients)
    }
  }

  const calculateUserTotals = (usersAll, clients) => {
    let year = 'All'
    try {
      if (selectedYear && selectedYear.value !== 'All') {
        year = Number(selectedYear.value)
      }
    } catch (err) {
      console.error(err)
    }

    if (usersAll && clients) {
      let profile = props.profile
      let _users = []
      _users = usersAll.filter(
        user =>
          (profile.userRole === 'Regional' &&
            profile.region === user.region &&
            user.userRole !== 'Admin') ||
          profile.userRole === 'Admin' ||
          profile.userRole === 'National'
      )

      _users = _users.map(user => {
        user = _.cloneDeep(user)
        user.clientMatch = []
        user.clientApproved = []
        user.clientCredits = [0]
        user.lddMonth = user.dealerType === 'Contact' ? 240 : 120
        if (clients) {
          clients.forEach(client => {
            if (client.dealerCode === user.dealerCode) {
              if (selectedYear.value === 'All') {
                user.clientMatch.push(client)
              } else if (
                client.submissionYear &&
                client.submissionYear === year
              ) {
                user.clientMatch.push(client)
              }
              if (client.validated === 'true') {
                let contact
                contact = client.maxLddMonth === '24h' ? 150 : 250
                if (selectedYear.value === 'All') {
                  user.clientApproved.push(client)
                  user.clientCredits.push(contact)
                } else if (
                  client.submissionYear &&
                  client.submissionYear === year
                ) {
                  user.clientApproved.push(client)
                  user.clientCredits.push(contact)
                }
              }
            }
          })
        }
        user.registeredTestDrives = user.clientMatch.length
        user.approvedTestDrives = user.clientApproved.length
        user.totalDealerCredits = user.clientCredits.reduce((a, b) => a + b)
        return user
      })
      setUsers(_users)
    }
  }

  let profile = props.profile
  if (props.profile.isLoaded && clients && users && users.length > 0) {
    let clientOptionArray = []
    clientOptionArray.push({ label: 'All', value: 'All' })
    // const searchScroll = () => {
    //   document.getElementById(searchLink).scrollIntoView(false)
    // }

    const { auth } = props
    if (!auth.uid) return <Navigate to="/" />

    //  Starting Varriables
    let totalRegistered = []
    let totalApproved = []
    let totalSales = []
    let totalCreditsList = [0]
    let totalCreditsContact
    let totalCredits
    let clientByYearMap = new Map()
    //let users = [];

    if (clients) {
      clients.forEach(client => {
        client = _.cloneDeep(client)
        if (profile.userRole === 'Regional') {
          if (profile.region === client.region) {
            totalRegistered.push(client)
            if (client.validated === 'true') {
              totalApproved.push(client)
              totalCreditsContact = client.maxLddMonth === '24h' ? 150 : 250
              totalCreditsList.push(totalCreditsContact)
            }
            if (
              client.hasPurchased &&
              (client.hasPurchased === 'on' ||
                client.hasPurchased === 'true' ||
                client.hasPurchased === true)
            ) {
              totalSales.push(client)
            }
            if (client.submission) {
              try {
                let submissionYear = new Date(client.submission).getFullYear()
                client.submissionYear = submissionYear
                if (clientByYearMap.get(submissionYear)) {
                  clientByYearMap.get(submissionYear).push(client)
                } else {
                  let clientsArray = []
                  clientsArray.push(client)
                  clientByYearMap.set(submissionYear, clientsArray)
                }
              } catch (error) {
                //ignore
                console.error(error)
              }
            }
          }
        }
        if (profile.userRole === 'Admin' || profile.userRole === 'National') {
          totalRegistered.push(client)
          if (client.validated === 'true') {
            totalApproved.push(client)
            totalCreditsContact = client.maxLddMonth === '24h' ? 150 : 250
            totalCreditsList.push(totalCreditsContact)
          }
          if (
            client.hasPurchased &&
            (client.hasPurchased === 'on' ||
              client.hasPurchased === 'true' ||
              client.hasPurchased === true)
          ) {
            //if (client.hasPurchased) {
            totalSales.push(client)
          }
          if (client.submission) {
            try {
              let submissionYear = new Date(client.submission).getFullYear()
              client.submissionYear = submissionYear
              if (clientByYearMap.get(submissionYear)) {
                clientByYearMap.get(submissionYear).push(client)
              } else {
                let clientsArray = []
                clientsArray.push(client)
                clientByYearMap.set(submissionYear, clientsArray)
              }
            } catch (error) {
              //ignore
              console.error(error)
            }
          }
        }
      })
    }

    //  Functions
    let yearsList = Array.from(clientByYearMap.keys())
    yearsList = yearsList.sort()
    yearsList.forEach(ele => {
      let opt = clientOptionArray.find(ele2 => ele2.value === ele.toString())
      if (!opt) {
        clientOptionArray.push({
          value: ele.toString(),
          label: ele.toString(),
        })
      }
    })

    if (totalCreditsList) {
      totalCredits = totalCreditsList.reduce((a, b) => a + b)
    }

    // Final Varriables
    let totalRegisteredFinal = totalRegistered.length
    let totalApprovedFinal = totalApproved.length
    let totalSalesFinal = totalSales.length

    const fileName =
      profile.userRole === 'Regional'
        ? `${profile.region}-Export`
        : 'Regional-Export'

    // Debugging
    // totalApproved.forEach((x, index) => {
    //   let id = index + 1
    //   let name = `${x.clientFirstName} ${x.clientLastName}`
    //   let region = x.region
    //   let dealerCode = x.dealerCode
    //   if(x.pendingValidation === true) {
    //     console.log(id, name, region, dealerCode)
    //   }
    // })

    const updateTotals = option => {
      let totalRegisteredByYear = []
      let totalApprovedByYear = []
      let totalSalesByYear = []
      let totalCreditsListForYear = 0
      try {
        if (users && option.value !== 'All') {
          let year = Number(option.value)
          totalRegistered.forEach(ele => {
            if (ele.submissionYear && ele.submissionYear === year) {
              totalRegisteredByYear.push(ele)
            }
          })
          totalApproved.forEach(ele => {
            if (ele.submissionYear && ele.submissionYear === year) {
              totalApprovedByYear.push(ele)
              let credit = ele.maxLddMonth === '24h' ? 150 : 250
              totalCreditsListForYear += credit
            }
          })
          totalSales.forEach(ele => {
            if (ele.submissionYear && ele.submissionYear === year) {
              totalSalesByYear.push(ele)
            }
          })
          totalRegisteredFinal = totalRegisteredByYear.length
          totalApprovedFinal = totalApproved.length
          totalSalesFinal = totalSalesByYear.length
          totalCredits = totalCreditsListForYear
        } else {
          totalRegisteredByYear = totalRegistered
          totalApprovedByYear = totalApproved
          totalSalesByYear = totalSales
          totalCreditsListForYear = totalCredits
        }
      } catch (error) {
        console.error(error)
      }
      setSelectedYear(option)
      setTotalRegisteredByYear(totalRegisteredByYear)
      setTotalApprovedByYear(totalApprovedByYear)
      setTotalSalesByYear(totalSalesByYear)
      setTotalCreditsListForYear(totalCreditsListForYear)
    }
    return (
      <React.Fragment>
        <Header />
        <div className="container pt-60 pb-60">
          <div className="row">
            <div className="col-sm-12 registration-section-title pb-20">
              <h4 className="section-title">Regional Report</h4>
              <div className="col-sm-12 pt-20">
                <h4>
                  WELCOME TO YOUR REGIONAL VIEW OF LINCOLN DISCOVERY DRIVE
                  DASHBOARD!
                </h4>
                <p>
                  Below you will see a summary of Lincoln Discovery Drives to
                  date, in your region, including Parts Statements credits.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 registration-section-title pb-20">
              <Select
                id="submissionYear"
                options={clientOptionArray}
                onChange={value => updateTotals(value)}
                value={selectedYear}
              />
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-sm-12">
              <div className="row gutters">
                <div className="col-md-3 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Total Registered{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}
                      <br />
                      Test drives
                    </p>
                    <p className="count">
                      {selectedYear.value !== 'All'
                        ? totalRegisteredByYear.length
                        : totalRegisteredFinal}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Total{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}
                      <br />
                      Approved{' '}
                    </p>
                    <p className="count">
                      {selectedYear.value !== 'All'
                        ? totalApprovedByYear.length
                        : totalApprovedFinal}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Total{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}{' '}
                      <br />
                      Retailer Credits
                    </p>
                    <p className="count">
                      $
                      {selectedYear.value !== 'All'
                        ? totalCreditsListForYear
                        : totalCredits}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Confirmed{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}
                      <br />
                      sales
                    </p>
                    <p className="count">
                      {selectedYear.value !== 'All'
                        ? totalSalesByYear.length
                        : totalSalesFinal}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-10">
            {/* <div className="col-sm-6 form-inline">
              <input className="form-control" type="text" onChange={this.handleChange} value={searchLink} id="searchLink" name="searchLink" placeholder="Search by Dealer Code" /><Link to='#!' onClick={searchScroll}><button className="btn-primary">Search</button></Link>
            </div> */}
            <div className="col-sm-6 form-inline">
              <NationalCSV
                clients={
                  selectedYear.value === 'All'
                    ? clients
                    : clientByYearMap.get(Number(selectedYear.value))
                }
                users={sortedTableData}
                profile={profile}
                filename={fileName}
                isFrench={false}
              />
              {sortedTableData.length > 0 ? (
                <NumberOfRecords>{`${sortedTableData.length} Retailer records`}</NumberOfRecords>
              ) : null}
            </div>
            <div className="col-sm-6 pb-20 pt-20 form-inline">
              <span className="text-right">
                A full summary of Retailer LDD's can be exported for your review
                as well.
              </span>
            </div>
          </div>
          <TableDiv>
            <table style={{ textAlign: 'center' }}>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th
                        className={
                          header.column.getCanSort()
                            ? header.column.getIsSorted().toString()
                            : undefined
                        }
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          textTransform: 'uppercase',
                          color: 'white',
                          backgroundColor: '#c1bb00',
                          borderBottom: '1px solid #c1bb00',
                          borderTop: '1px solid #c1bb00',
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              <div
                                {...{
                                  onMouseDown: header.getResizeHandler(),
                                  onTouchStart: header.getResizeHandler(),
                                  className: `resizer ${
                                    header.column.getIsResizing()
                                      ? 'isResizing'
                                      : ''
                                  }`,
                                }}
                              ></div>
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null}
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="pagination">
              <div className="previous">
                <button
                  className="border rounded p-1"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  Previous
                </button>
              </div>
              <div className="center">
                <span className="pageInfo">
                  {'Page '}
                  <div className="pageJump">
                    <input
                      type="number"
                      onChange={e => {
                        let page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0
                        page =
                          page > table.getPageCount() - 1
                            ? table.getPageCount() - 1
                            : page
                        table.setPageIndex(page)
                      }}
                      value={table.getState().pagination.pageIndex + 1}
                      className="border p-1 rounded w-16"
                    />
                  </div>
                  {' of '}
                  <span className="totalPages">{table.getPageCount()}</span>
                </span>
                <span className="select-wrap pageSizeOptions">
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value))
                    }}
                  >
                    {[5, 10, 20, 25, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize} rows
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="next">
                <button
                  className="border rounded p-1"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  Next
                </button>
              </div>
            </div>
          </TableDiv>
        </div>
        <Footer />
      </React.Fragment>
    )
  } else {
    return (
      <div
        style={{
          minWidth: '100% !important',
          minHeight: '100% !important',
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          top: '120px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '5em' }}>
          <BallTriangle
            color="#c1bb00"
            height={80}
            width={80}
            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
          />
          <h1 style={{ paddingTop: '1em' }}>Loading</h1>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.firestore.ordered.clients,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
})

export default compose(
  firestoreConnect(() => ['users', 'clients']),
  connect(mapStateToProps)
)(RegionalReportNew)
