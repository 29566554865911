import React from 'react'
import intro1 from '../images/intro-2/intro-1.jpg'
import intro2 from '../images/intro-2/intro-2.jpg'
import intro3 from '../images/intro-2/intro-3.jpg'
import intro4 from '../images/intro-2/intro-4.jpg'
import intro5 from '../images/intro-2/intro-5.jpg'
const IntroWelcomeFr = () => {
  // const boxStyle = {
  // 	height: 200
  // }

  return (
    <section className="intro-area pt-60 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="intro-title">
              <h3 className="title text-center">
                Bienvenue à l’Expérience découverte Lincoln
              </h3>
              <h5 className="sub-title text-left">
                Merci d’intégrer l’Expérience découverte Lincoln à votre plan
                visant à présenter des expériences sans effort de Lincoln qui
                sont chaleureuses, humaines et personnalisées. L’Expérience
                découverte Lincoln nous distingue des autres marques de luxe et
                a joué un rôle important en attirant des acheteurs de véhicule
                de luxe vers la marque Lincoln.
                <br />
                <br />
                <i>
                  "L’Expérience découverte a totalement changé mon opinion au
                  sujet de Lincoln. Auparavant, je conduisais une Mercedes, mais
                  après mon Expérience, j’ai acheté un Corsair!" A. Brook,
                  Québec
                </i>
                <br />
                <br />
                L’outil du Programme d’essai routier à la découverte de Lincoln
                vous fournit tout ce dont vous avez besoin pour connaître du
                succès dans ce programme. Commençons.{' '}
              </h5>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro1} alt="intro" />
              </div>
              {/* <div className="intro-content-2 text-center" style={boxStyle}>
													<p>PRÉSENTERONS L’EXPÉRIENCE SANS EFFORT QU’OFFRENT LES SERVICES PERSONNALISÉS DE LINCOLN COMME LE SERVICE DE CONCIERGE ET LE SERVICE DE COLLECTE ET DE LIVRAISON</p>
											</div> */}
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro2} alt="intro" />
              </div>
              {/* <div className="intro-content-2 text-center" style={boxStyle}>
								<p>FAISONS VIVRE UNE EXPÉRIENCE CHALEUREUSE, HUMAINE ET PERSONNALISÉE</p>
							</div> */}
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro3} alt="intro" />
              </div>
              {/* <div className="intro-content-2 text-center" style={boxStyle}>
								<p>AMÉLIORERONS L’OPINION DE LA MARQUE GRÂCE À L’AUGMENTATION DES EXPÉRIENCES DE CONDUITE UNIQUES</p>
							</div> */}
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro4} alt="intro" />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro5} alt="intro" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroWelcomeFr
