import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from '../containers/login'
import Home from '../containers/home'
import WhatsNew from '../containers/whatsNew'
import Faq from '../containers/faq'
import Support from '../containers/support'
import DealerForm from '../components/dealerForm'
import DealerFormFr from '../components/dealerFormFr'
import ClientValidation from '../containers/clientValidation'
import DealerReport from '../containers/dealerReport'
import AdminReport from '../containers/adminReport'
import ClientDetails from '../containers/clientDetails'
import ClientUpdateValidation from '../containers/clientUpdateValidation'
import ClientUpdateForm from '../containers/updateClientForm'
import ClientUpdateSearch from '../containers/clientUpdateSearch'
import RegionalReportNew from '../containers/regionalReportNew'
import Thankyou from '../containers/thankyou'
import AdminUpdateSearch from '../containers/adminUpdateSearch'
import NationalReportNew from '../containers/nationalReportNew'
import Register from '../containers/register'
import ProgramParameters from '../containers/ProgramParameters'
import ProgramParametersFr from '../containers/ProgramParametersFr'
import QuickTipsEn from '../containers/QuickTipsContainer'
// French Pages
import HomeFr from '../containers/homeFr'
import WhatsNewFR from '../containers/whatsNewFr'
import FaqFr from '../containers/faqFr'
import SupportFr from '../containers/supportFr'
import ClientValidationFr from '../containers/clientValidationFr'
import ClientDetailsFr from '../containers/clientDetailsFr'
import ClientUpdateSearchFr from '../containers/clientUpdateSearchFr'
import ClientUpdateFormFr from '../containers/updateClientFormFr'
import DealerReportFr from '../containers/dealerReportFr'
import NationalReportFr from '../containers/nationalReportFr'
import ThankyouFr from '../components/thankyouFr'
import ClientUpdateValidationFr from '../containers/clientUpdateValidationFr'
import AdminReportFr from '../containers/adminReportFr'
import HttpsRedirect from 'react-https-redirect'
import RegionalReportNewFr from '../containers/regionalReportNewFr'
import RiskList from '../containers/riskList'
import QuickTipsFr from '../containers/QuickTipsContainerFr'
import Users from '../containers/users'
import UpdateUserAccount from '../containers/updateUserAccount'
import TestDriveForm from '../containers/testDriveForm'
import TestDriveFormFr from '../containers/testDriveFormFr'

const Routers = () => {
  return (
    <HttpsRedirect>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/whats-new" element={<WhatsNew />} />
          <Route
            exact
            path="/program-parameters"
            element={<ProgramParameters />}
          />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/client-new" element={<DealerForm />} />
          <Route
            exact
            path="/client-validation"
            element={<ClientValidation />}
          />
          <Route exact path="/client/:id" element={<ClientDetails />} />
          <Route exact path="/dealer-report" element={<DealerReport />} />
          <Route exact path="/admin-report" element={<AdminReport />} />
          <Route
            exact
            path="/client-validation/:id"
            element={<ClientUpdateValidation />}
          />
          <Route exact path="/client-update" element={<ClientUpdateSearch />} />
          <Route
            exact
            path="/client-update/:id"
            element={<ClientUpdateForm />}
          />
          <Route
            exact
            path="/national-report"
            element={<NationalReportNew />}
          />
          <Route
            exact
            path="/regional-report"
            element={<RegionalReportNew />}
          />
          <Route exact path="/thank-you" element={<Thankyou />} />
          <Route exact path="/admin-update" element={<AdminUpdateSearch />} />
          <Route exact path="/risk-list" element={<RiskList />} />
          <Route exact path="/create-account" element={<Register />} />
          <Route exact path="/quick-tips" element={<QuickTipsEn />} />
          <Route exact path="/user-accounts" element={<Users />} />
          <Route
            exact
            path="/user-account/:id"
            element={<UpdateUserAccount />}
          />
          <Route exact path="/test-drive-form" element={<TestDriveForm />} />

          <Route exact path="/fr" element={<HomeFr />} />
          <Route exact path="/whats-new-fr" element={<WhatsNewFR />} />
          <Route
            exact
            path="/program-parameters-fr"
            element={<ProgramParametersFr />}
          />
          <Route exact path="/faq-fr" element={<FaqFr />} />
          <Route exact path="/support-fr" element={<SupportFr />} />
          <Route exact path="/client-new-fr" element={<DealerFormFr />} />
          <Route
            exact
            path="/client-validation-fr"
            element={<ClientValidationFr />}
          />
          <Route
            exact
            path="/client-validation-fr/:id"
            element={<ClientUpdateValidationFr />}
          />
          <Route exact path="/client-fr/:id" element={<ClientDetailsFr />} />
          <Route
            exact
            path="/client-update-fr"
            element={<ClientUpdateSearchFr />}
          />
          <Route
            exact
            path="/client-update-fr/:id"
            element={<ClientUpdateFormFr />}
          />
          <Route exact path="/dealer-report-fr" element={<DealerReportFr />} />
          <Route
            exact
            path="/regional-report-fr"
            element={<RegionalReportNewFr />}
          />
          <Route
            exact
            path="/national-report-fr"
            element={<NationalReportFr />}
          />
          <Route exact path="/thank-you-fr" element={<ThankyouFr />} />
          <Route exact path="/admin-report-fr" element={<AdminReportFr />} />
          <Route exact path="/quick-tips-fr" element={<QuickTipsFr />} />
          <Route
            exact
            path="/test-drive-form-fr"
            element={<TestDriveFormFr />}
          />
        </Routes>
      </Router>
    </HttpsRedirect>
  )
}

export default Routers
