import React from 'react'
import LoggedOutMenuFr from '../components/loggedOutMenuFr'
import LoggedInMenuFr from '../components/loggedInMenuFr'
import { connect } from 'react-redux'
import { signOutFr } from '../data/actions/authActions'

const MainMenuFr = ({ auth, profile }) => {
  const menu = auth.uid ? (
    <LoggedInMenuFr profile={profile} />
  ) : (
    <LoggedOutMenuFr />
  )

  return (
    <React.Fragment>
      <div className="navigation">
        <nav id="mobile-menu">
          <div className="main-menu text-center">{menu}</div>
        </nav>
        <div className="mobile-menu"></div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOutFr()),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuFr)
