import React from 'react'
import SupportFR from '../components/supportFR'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'

const SupportFr = () => {
  const Support = <SupportFR />

  return (
    <React.Fragment>
      <HeaderFr />
      {Support}
      <FooterFr />
    </React.Fragment>
  )
}

export default SupportFr
