import React from 'react'

const ProgramParametersEn = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row mt-30">
          <div className="col-lg-12">
            <div className="faq-section-title text-center col-lg-12 mb-20">
              <h3 className="section-title">
                2024 Lincoln Discovery Drive Program Details
              </h3>
            </div>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Program Period</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <p className="static-dark-grey">January 1 – December 31, 2024</p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Retailer Eligibility</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> All Lincoln Retailers are eligible to
                  participate in the Lincoln Discovery Drive Program
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailers wishing to participate in the
                  2024 Program, MUST complete the Registration form and Surprise
                  & Delight form and submit to Program Headquarters before being
                  eligible to receive Lincoln Canada support
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailer monthly test drive allocation:
                  Contact - 20/month, Non-Contact - 10/month
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Vehicle Eligibility</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> New 2023MY Corsair, Nautilus, Aviator,
                  Navigator{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> New 2024MY Corsair, Nautilus, Aviator,
                  Navigator
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">NEW FOR 2024</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Fresh program creative – new advertising
                  assets and client welcome letter are available on the Lincoln
                  Discovery Drive portal and in the Retailer advertising toolbox
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Reimbursement from Lincoln Canada is $200
                  for both 24-hour and 48-hour test drives <br />
                  {`\u2003`} o Dealers are still expected to contribute a minimum of
                  $50 towards each test drive; Surprise & Delight should be a
                  minimum of $100
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Client Eligibility: Only clients driving
                  competitive automotive brands
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Additional Key Program Highlights
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Program elements will demonstrate
                  Lincoln’s vision of Warm, Human and Personally Crafted
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Program to be highlighted through three
                  exposure points: LincolnCanada.com, Retailer websites and
                  Lincoln Retailer showrooms
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Participating Retailers will be eligible
                  to receive inquiries from LincolnCanada.com site (where
                  appropriate)
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> A bi-lingual Program Ambassador will
                  manage inquiries from the national website (and other retail
                  touchpoints), working with participating Retailers to set up
                  test drive experiences
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Participating Retailers to access client
                  welcome letter and program creative for their websites &
                  social media channels{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailers are asked to offer each program
                  participant a unique and personalized drive experience with
                  surprise and delight element that appropriately reflects the
                  Lincoln Brand{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailers are encouraged to tailor their
                  surprise and delight element to reflect their communities{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> The online registration and tracking tool
                  ensures effective client and program management{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Each Discovery Drive test drive will
                  count towards 1 Service Loaner day in order to achieve 50%
                  usage requirements{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> An iteration of Lincoln’s Pickup and
                  Delivery service is strongly suggested{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Client’s current vehicle to be washed{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Discovery Drive Funding Model</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> 24-hour test drive: $200 reimbursement
                  from Lincoln Canada*{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> 48-hour test drive: $200 reimbursement
                  from Lincoln Canada*{' '}
                </p>
              </li>
            </ul>
            <p className="static-dark-grey">
              *Retailers are also required to commit to a minimum of $50 towards
              each drive experience. Reimbursement from Lincoln Canada will
              appear on your Parts Statements.
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Discovery Drive Program Ambassador/Program Headquarters
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <p className="static-dark-grey">
              Program Headquarters Hours of Operation: 9:00am-5:00pm EST <br />
              Email:{' '}
              <a href="mailto:lddambassador@lincolndiscoverydrive.com">
                lddambassador@lincolndiscoverydrive.com
              </a>{' '}
              <br />
              Phone: 833-717-0017 <br />
              <b>
                Please note the LDD Ambassador works from their home office so
                email is your best method of contact
              </b>
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Program Headquarter Support</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Receive inquiries from Lincolncanada.com
                  and connect prospects to the closest participating Lincoln
                  Retailer
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Work with Lincoln Concierge to ensure a
                  seamless transition from inquiry to test drive experience
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Receive all test drive data from
                  Retailers, review for accuracy/compliance and approve for
                  payment
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Conduct a follow-up call to test drive
                  participants to validate test drive and gauge interest in the
                  product and Lincoln Brand
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Process all test drives for DSI payment
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Submit test drive data for sales match
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10 mb-50">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Participating Retailer Responsibilities
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Receive test drive inquiries from Program
                  Ambassador and follow up with clients to book their test drive
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> AND: Offer the test drive experience to
                  conquest prospects through advertising, partnerships or those
                  visiting the store
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Complete the online registration form
                  (participant contact information, dates, drive length, current
                  competitive vehicle) for submission to Program Headquarters
                  for processing
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Implement Pick Up & Delivery where
                  possible to demonstrate Lincoln’s incredible client services
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Wash test drive participant’s vehicle
                  (where applicable)
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Place a personalized letter on the
                  driver’s seat of the test drive vehicle
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Provide a surprise and delight element
                  that appropriately reflects the Lincoln Brand
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span>{' '}
                  <strong>
                    Track your LDD program activity – and sales conversions –
                    through the program’s online tool!
                  </strong>{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgramParametersEn
