import React from 'react'
import Header from '../containers/header'
import Footer from '../components/footer'
import FaqEN from '../components/faqEN'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'

const Faq = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/" />

  const Faq = <FaqEN />

  return (
    <React.Fragment>
      <Header />
      {Faq}
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default compose(connect(mapStateToProps))(Faq)
