import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../data/actions/authActions'
import DealerMenu from '../components/dealerMenu'
import AdminMenu from '../components/adminMenu'
import RegionalMenu from '../components/regionalMenu'

const LoggedInMenu = props => {
  const { profile, signOut, auth } = props
  let links

  if (profile.userRole === 'Dealer') {
    links = <DealerMenu auth={auth} profile={profile} />
  }

  if (profile.userRole === 'Regional' || profile.userRole === 'National') {
    links = <RegionalMenu auth={auth} profile={profile} />
  }

  if (profile.userRole === 'Admin') {
    links = <AdminMenu auth={auth} profile={profile} />
  }

  return (
    <ul>
      {links}
      <li>
        <Link
          to="#!"
          onClick={e => {
            signOut()
            window.location.href = '/'
          }}
        >
          Logout
        </Link>
      </li>
    </ul>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInMenu)
