import React, { Component } from 'react'
import { connect } from 'react-redux'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'
import WelcomeFr from '../components/welcome_fr'
import LoginFr from '../containers/loginFr'
import IntroWelcomeFr from '../components/introWelcomeFr'
import ProgramAssetsFr from '../components/programAssetsFr'

class HomeFr extends Component {
  render() {
    const { auth, profile } = this.props
    const intro = auth.uid ? <IntroWelcomeFr /> : <></>
    const WelcomeCardsEn = auth.uid ? <></> : <WelcomeFr />
    const login = auth.uid ? <></> : <LoginFr />
    const assets = profile.userRole === 'Dealer' ? <ProgramAssetsFr /> : null

    return (
      <React.Fragment>
        <HeaderFr />
        {WelcomeCardsEn}
        {intro}
        {assets}
        {login}
        <FooterFr />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(HomeFr)
