import React from 'react'
//import BannerImg from '../images/banner-text-fr-2.png'
//import BannerBg from '../images/bannge-bg.jpg'
import DesktopBanner from '../images/banner-fr.jpg'
import MobileBanner from '../images/mobile_banner-fr.jpg'

const BannerFr = () => {
  return (
    <div className="banner-area bg_cover d-flex align-items-center">
      <img
        className="desktop-banner"
        src={DesktopBanner}
        alt="Lincoln Banner"
      />
      <img className="mobile-banner" src={MobileBanner} alt="Lincoln Banner" />
    </div>
  )
}

export default BannerFr
