import React from 'react'

const SupportFR = () => {
  return (
    <section className="support pt-60 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="support text-center">
              <p className="mb-25">
                Pour obtenir du soutien ou de l’aide avec le programme
                Expérience découverte Lincoln, veuillez communiquer avec
                l’ambassadeur du programme, au Secrétariat du programme :
              </p>
              <h5>
                <a href="mailto:LDDambassador@Lincolndiscoverydrive.com">
                  LDDambassador@Lincolndiscoverydrive.com
                </a>{' '}
                <br /> 1.833.717.0017
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SupportFR
