import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const DealerMenu = props => {
  return (
    <React.Fragment>
      {/* <li>
        <NavLink to="/">Home</NavLink>
      </li> */}
      <li>
        <Link to="#!">LDD Program</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/whats-new">PROGRAM ASSETS</NavLink>
          </li>
          <li>
            <NavLink to="/program-parameters">PROGRAM DETAILS</NavLink>
          </li>
          <li>
            <NavLink to="/faq">FAQ's</NavLink>
          </li>
          <li>
            <NavLink to="/quick-tips">QUICK TIP'S</NavLink>
          </li>
          {/* <li>
            <NavLink to="/support">Support</NavLink>
          </li> */}
        </ul>
      </li>
      <li>
        <Link to="#!">Forms</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/client-new">Registration</NavLink>
          </li>
          {/* <li>
            <NavLink to="/client-validation">Validation</NavLink>
          </li> */}
        </ul>
      </li>
      <li>
        <NavLink to="/dealer-report">Reports</NavLink>
      </li>
    </React.Fragment>
  )
}

export default DealerMenu
