import React from 'react'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'
import WhatsNewFr from '../components/whatsNewFr'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'

const WhatsNewFR = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/fr" />
  const content = <WhatsNewFr />
  return (
    <React.Fragment>
      <HeaderFr />
      {content}
      <FooterFr />
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default compose(connect(mapStateToProps))(WhatsNewFR)
