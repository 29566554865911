import React from 'react'
import LoggedOutMenu from '../components/loggedOutMenu'
import LoggedInMenu from '../components/loggedInMenu'
import { connect } from 'react-redux'
import { signOut } from '../data/actions/authActions'

const MainMenu = ({ auth, profile }) => {
  const menu = auth.uid ? <LoggedInMenu profile={profile} /> : <LoggedOutMenu />

  return (
    <React.Fragment>
      <div className="navigation">
        <nav id="mobile-menu">
          <div className="main-menu text-center">{menu}</div>
        </nav>
        <div className="mobile-menu"></div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)
