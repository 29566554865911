import React, { Component } from 'react'
import Header from '../containers/header'
import Footer from '../components/footer'
//import SupportEN from "../components/supportEN";
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import AllRiskList from '../components/allRiskList'
import { BallTriangle } from 'react-loader-spinner'

class RiskList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
    }
  }

  render() {
    const { clients, profile, auth } = this.props

    if (clients) {
      if (!auth.uid) return <Navigate to="/" />

      return (
        <React.Fragment>
          <Header />
          <div className="container pt-60 pb-60">
            <div className="row">
              <div className="col-sm-12 registration-section-title pb-20">
                <h4 className="section-title">Risk List</h4>
              </div>
              <div className="col-sm-12 pb-20">
                <h4>
                  WELCOME TO YOUR DEALERSHIP'S LINCOLN DISCOVERY DRIVE
                  DASHBOARD!
                </h4>
                <p>
                  Below you will see a summary of Lincoln Discovery Drives that
                  match an existing postal code and last name combination.
                </p>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-sm-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">Submission Date</th>
                      <th className="text-center">Order Number</th>
                      <th className="text-center">Client name</th>
                      <th className="text-center">Approved</th>
                      <th className="text-center">
                        Test drive
                        <br />
                        date
                      </th>
                      {/* <th>Approved</th> */}
                      <th className="text-center">
                        Test Drive
                        <br />
                        Length
                      </th>
                      <th className="text-center">Test drive VIN</th>
                      <th className="text-center">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <AllRiskList clients={clients} profile={profile} />
                  </tbody>
                </table>
                <p>
                  <br />
                </p>
                <p>
                  Lincoln Discovery Drive Client information will be tracked for
                  vehicle purchase and your Dealership &quot;View Vehicles
                  Purchased Report&quot; will be updated as sales information
                  becomes available.
                </p>
              </div>
            </div>
          </div>
          {/* <SupportEN /> */}
          <Footer />
        </React.Fragment>
      )
    } else {
      return (
        <div
          style={{
            minWidth: '100% !important',
            minHeight: '100% !important',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: '120px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ padding: '5em' }}>
            <BallTriangle
              color="#c1bb00"
              height={80}
              width={80}
              wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
            />
            <h1 style={{ paddingTop: '1em' }}>Loading</h1>
          </div>
        </div>
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    users: state.firestore.ordered.users,
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'clients' }, { collection: 'users' }])
)(RiskList)
