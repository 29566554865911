import React from 'react'
import Header from '../containers/headerFr'
import Footer from '../components/footerFr'
import QuickTipsFr from '../components/QuickTipsFr'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'

const QuickTipsContainerFr = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/fr" />

  const quickTipsFr = <QuickTipsFr />

  return (
    <React.Fragment>
      <Header />
      {quickTipsFr}
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default compose(connect(mapStateToProps))(QuickTipsContainerFr)
