import React from 'react'
import { NavLink } from 'react-router-dom'

const LoggedOutMenu = props => {
  return (
    <ul>
      {props & props.auth ? (
        <li>
          <NavLink to="/whats-new">PROGRAM ASSETS</NavLink>
        </li>
      ) : null}
      {props & props.auth ? (
        <li>
          <NavLink to="/faq">FAQ</NavLink>
        </li>
      ) : null}

      {/* <li>
        <NavLink to='/support'>Support</NavLink>
      </li> */}
      <li>
        <NavLink to="/#login">Login</NavLink>
      </li>
    </ul>
  )
}

export default LoggedOutMenu
