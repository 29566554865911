import React from 'react'
import { Link } from 'react-router-dom'

const ProgramAssetsFr = () => {
  return (
    <React.Fragment>
      <section className="card-area pt-30 pb-30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card-section-title text-center">
                <h4 className="section-title mb-30">
                  EXPÉRIENCE DÉCOUVERTE LINCOLN - INSCRIPTION ET SUIVI
                </h4>
                <p>
                  Vous avez accès ci-dessous à tous les formulaires en lien avec
                  l’Expérience découverte Lincoln, à des les statistiques à jour
                  sur l’Expérience découverte Lincoln et à des renseignements
                  pour votre établissement.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-card mt-30">
                <div className="card-titles">
                  <h3>INSCRIRE UNE EXPÉRIENCE DÉCOUVERTE</h3>
                </div>
                <div className="card-text">
                  <p>
                    <strong>
                      Accédez au formulaire d’inscription en ligne
                    </strong>
                  </p>
                  <p style={{ height: '95px' }}>
                    Vous devez remplir tous les champs de ce formulaire pour
                    chaque client qui fait un essai routier Expérience
                    découverte Lincoln
                  </p>
                  <Link to="/client-new-fr">CLIQUEZ ICI</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-card mt-30">
                <div className="card-titles">
                  <h3>RECHERCHE DE FORMULAIRE EXISTANT</h3>
                </div>
                <div className="card-text">
                  <p>
                    <strong>Mettez à jour une inscription</strong>
                  </p>
                  <p>
                    Utilisez le numéro de commande généré par l’inscription
                    (code alphanumérique aléatoire) pour chercher une
                    inscription à l’Expérience découverte Lincoln déjà effectuée
                  </p>
                  <Link to="/client-validation-fr">CLIQUEZ ICI</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-card mt-30">
                <div className="card-titles">
                  <h3>
                    RAPPORT DE L’EXPÉRIENCE DÉCOUVERTE LINCOLN POUR LE
                    CONCESSIONNAIRE
                  </h3>
                </div>
                <div className="card-text">
                  <p>
                    <strong>
                      Rendez-vous sur le tableau de bord des rapports de votre
                      concessionnaire
                    </strong>
                  </p>
                  <p style={{ height: '95px' }}>
                    Vous y trouverez les plus récents renseignements au sujet de
                    vos essais routiers Expérience découverte Lincoln
                  </p>
                  <Link to="/dealer-report-fr">CLIQUEZ ICI</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ProgramAssetsFr
