import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Navigate, Link } from 'react-router-dom'
import '../styles/login.css'
import { signIn, resetAuthErrorState } from '../data/actions/authActions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class LoginFr extends Component {
  state = {
    email: '',
    password: '',
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }
  handleSubmit = e => {
    e.preventDefault()
    this.props.signIn(this.state)
    // this.props.history.push('/test')
  }

  showToast(message) {
    toast.error(message)
    this.props.resetAuthErrorState()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.authErrorResponse && this.props.authErrorResponse.message) {
      this.showToast(
        `${this.props.authErrorResponse.message} Please contact the LDD Ambassador at LDDambassador@Lincolndiscoverydrive.com`
      )
    }
  }

  render() {
    // const { authError, auth } = this.props
    // if (auth.uid) return <Navigate to='/fr' />

    const titleStyle = {
      marginBottom: '20px',
    }

    const textStyle = {
      width: '30%',
    }

    const loginStyle = {
      width: '70%',
    }

    return (
      <React.Fragment>
        <div className="log-in-area pt-60 pb-40">
          <div className="container">
            <div className="row">
              <div id="#login" className="col-lg-8 offset-lg-1">
                <h4 className="log-in-title" style={titleStyle}>
                  Ouvrir une session
                </h4>
                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                  <div className="single-log-in clearfix pt-30{{ $errors->has('username') ? ' has-error' : '' }}">
                    <div className="user" style={textStyle}>
                      <label htmlFor="username">Nom d’utilisateur</label>
                    </div>
                    <div className="log-inform" style={loginStyle}>
                      <input
                        id="email"
                        onChange={this.handleChange}
                        type="text"
                        className="form-control"
                        name="email"
                        value={this.state.email}
                        required
                        autoFocus
                      />
                    </div>
                  </div>
                  <div className="single-log-in clearfix pt-30 {{ $errors->has('password') ? ' has-error' : '' }}">
                    <div className="user" style={textStyle}>
                      <label htmlFor="">Mot de passe</label>
                    </div>
                    <div className="log-inform" style={loginStyle}>
                      <input
                        id="password"
                        onChange={this.handleChange}
                        type="password"
                        className="form-control"
                        name="password"
                        required
                      />
                    </div>
                  </div>
                  {/* add error handeler here  */}
                  <div className="single-log-in clearfix pt-50">
                    <div className="log-inform text-center">
                      <button>Soumettre</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="intro-text text-center">
                  <p>
                    Commencez par ouvrir une session ici avec votre indicatif
                    concessionnaire et votre mot de passe spécifique fourni par
                    le Secrétariat du programme Expérience découverte Lincoln.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          hideProgressBar="true"
          autoClose="5000"
          style={{ width: '600px' }}
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    resetAuthErrorState: () => dispatch(resetAuthErrorState()),
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    authErrorResponse: state.auth.authErrorResponse,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFr)
