import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import M from 'materialize-css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BallTriangle } from 'react-loader-spinner'

import { createClient } from '../data/actions/clientActions'

import Header from '../containers/header'
import Footer from '../components/footer'

import '../styles/timepicker.css'
import '../containers/customStyles.css'

const DealerForm = props => {
  const navigate = useNavigate()
  const now = new Date()
  const d = now.getUTCDate()
  const m = now.getUTCMonth() + 1
  const y = now.getUTCFullYear()
  const date = y + '/' + m + '/' + d
  const [processing, setProcessing] = useState(false)
  const [dealerCode, setDealerCode] = useState(props.profile.dealerCode)
  const [dealerName, setDealerName] = useState(props.profile.dealerName)
  const [region, setRegion] = useState(props.profile.region)
  const [clientFirstName, setClientFirstName] = useState('')
  const [clientLastName, setClientLastName] = useState('')
  const [clientAddress, setClientAddress] = useState('')
  const [clientCity, setClientCity] = useState('')
  const [clientProvince, setClientProvince] = useState('')
  const [clientPostalCode, setClientPostalCode] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [clientCellPhone, setClientCellPhone] = useState('')
  const [broughtDealership, setBroughtDealership] = useState('')
  const [currentVehicle, setCurrentVehicle] = useState('')
  const [vehicleInterest, setVehicleInterest] = useState('')
  const [testDriveDate1, setTestDriveDate1] = useState('')
  const [testDriveDate2, setTestDriveDate2] = useState('')
  const [dropoffLocation, setDropoffLocation] = useState('')
  const [dropoffAltAddress, setDropoffAltAddress] = useState('')
  const [communicationMethod, setCommunicationMethod] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [testDriveVIN, setTestDriveVIN] = useState('')
  const [currentCompetitiveVehicleVIN, setCurrentCompetitiveVehicleVIN] =
    useState('')
  const [supriseGift, setSupriseGift] = useState('')
  const [comments, setComments] = useState('')
  const [orderID, setOrderID] = useState('')
  const [pendingValidation, setPendingValidation] = useState(true)
  const [validated, setValidated] = useState(null)
  const [hasPurchased, setHasPurchased] = useState(false)
  const [redList, setRedList] = useState('')
  const [submission, setSubmission] = useState(date)
  const [uid, setUid] = useState('')
  const [maxLddMonth, setMaxLddMonth] = useState('')
  const [madePurchaseAfterLDD, setMadePurchaseAfterLDD] = useState(false)
  const [referralCertNum, setReferralCertNum] = useState('')
  const [isReferralCert, setisReferralCert] = useState(false)
  const [timeStart, setTimeStart] = useState('')
  const [timeEnd, setTimeEnd] = useState('')
  const [followUp, setFollowUp] = useState('')
  const [updatedOn, setUpdatedOn] = useState('')
  const [dealerCredit, setDealerCredit] = useState(0)
  const [fullName, setFullName] = useState('')
  const [validationLink, setValidationLink] = useState(null)
  const [details, setDetails] = useState(null)
  const [update, setUpdate] = useState(null)
  const [adminEdit, setAdminEdit] = useState(null)
  const [vinNum, setVinNum] = useState(null)
  const [testDriveDate, setTestDriveDate] = useState(null)
  const [testDriveLength, setTestDriveLength] = useState(null)
  const [isValid, setIsValid] = useState(null)
  const [timeSlot, setTimeSlot] = useState(null)
  const [commentsParsed, setCommentsParsed] = useState(null)
  const [hasPurchasedParsed, setHasPurchasedParsed] = useState(null)
  const [supriseGiftParsed, setSupriseGiftParsed] = useState(null)
  const [dealerComments, setDealerComments] = useState('')

  useEffect(() => {
    if (props.dealerCode !== props.profile.dealerCode) {
      setDealerCode(props.profile.dealerCode)
      setRegion(props.profile.region)
      setDealerName(props.profile.dealerName)
    }
  }, [props.dealerCode, props.profile.dealerCode])

  const createClientSuccessCallback = () => {
    setProcessing(false)
    navigate('/thank-you')
  }

  const createClientErrorCallback = err => {
    setProcessing(false)

    if (err && err.message) {
      toast.error(`${err.message}`)
    } else {
      toast.error(
        'An unexpeccted error occurred. Please review the form fields and retry.'
      )
    }
  }

  const formValidate = () => {
    if (!vehicleInterest) {
      toast.error('Vehicle of interest is required.')
      return false
    }
    if (!dropoffLocation) {
      toast.error('Pickup / drop off location is required.')
      return false
    }
    if (!communicationMethod) {
      toast.error('Preferred method of communication is required.')
      return false
    }
    return true
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!formValidate()) {
      return false
    }
    const clients = props.clients
    const clientMatch = []
    let isRisk
    console.log('Postal Search Starting....')
    clients.forEach(client => {
      if (client.clientPostalCode === clientPostalCode) {
        console.log('Search Match ====>')
        clientMatch.push(client)
      }
    })
    clientMatch.forEach(matchedClient => {
      if (matchedClient.clientLastName === clientLastName) {
        isRisk = true
      }
    })
    if (isRisk === true) {
      alert(
        'This client may have been previously registered and will be manually reviewed.'
      )
      setRedList('true')
    } else {
      setRedList('false')
    }
    setProcessing(true)
    props.createClient(
      {
        dealerCode,
        dealerName,
        region,
        clientFirstName,
        clientLastName,
        clientAddress,
        clientCity,
        clientProvince,
        clientPostalCode,
        clientEmail,
        clientCellPhone,
        broughtDealership,
        currentVehicle,
        vehicleInterest,
        testDriveDate1,
        testDriveDate2,
        dropoffLocation,
        dropoffAltAddress,
        communicationMethod,
        dateStart,
        dateEnd,
        testDriveVIN,
        currentCompetitiveVehicleVIN,
        supriseGift,
        comments,
        orderID,
        pendingValidation,
        validated,
        hasPurchased,
        redList,
        submission,
        uid,
        maxLddMonth,
        madePurchaseAfterLDD,
        referralCertNum,
        isReferralCert,
        timeStart,
        timeEnd,
        followUp,
        updatedOn,
        dealerCredit,
        fullName,
        validationLink,
        details,
        update,
        adminEdit,
        vinNum,
        testDriveDate,
        testDriveLength,
        isValid,
        timeSlot,
        commentsParsed,
        hasPurchasedParsed,
        supriseGiftParsed,
        dealerComments,
      },
      createClientSuccessCallback,
      createClientErrorCallback
    )
  }

  const setTimeField = (s, e) => {
    setTimeStart(s.value)
    setTimeEnd(e.value)
  }

  const { profile, auth } = props
  if (!auth.uid) return <Navigate to="/" />

  const elems = document.querySelectorAll('.timepicker')
  const ts = document.getElementById('timeStart')
  const te = document.getElementById('timeEnd')
  const options = {
    onCloseStart: () => {
      setTimeField(ts, te)
    },
  }
  M.Timepicker.init(elems, options)

  return (
    <React.Fragment>
      <Header />
      <div className="container pt-60 pb-60" style={{ position: 'relative' }}>
        {processing && (
          <div
            style={{
              backdropFilter: 'blur(10px)',
              width: '100vw',
              height: '100vh',
              left: 0,
              position: 'fixed',
              zIndex: 10,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <BallTriangle
              color="#c1bb00"
              height={70}
              width={70}
              wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
            />
            <h3 style={{ paddingTop: '1em', textAlign: 'center' }}>
              Processing
            </h3>
          </div>
        )}
        <form className="interior-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 registration-section-title pb-20">
              <h4 className="section-title">Registration Form</h4>
              <span className="pt-20">
                This form must be filled out for In-Retailer Clients taking a
                Lincoln Discovery Drive (who have not been registered by Program
                Headquarters)
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*Retailer Code</p>
            </div>
            <div className="col-md-3">
              <p className="read-only text-right">{dealerCode}</p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">Security Captcha</p>
            </div>
            <div className="col-md-9">
              <p>
                *For security purposes please enter your Retailer code displayed
                above.
              </p>
              <div className="col-md-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="dealerCode"
                  onChange={e => setDealerCode(e.target.value)}
                  name="dealerCode"
                  defaultValue={dealerCode}
                />
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*Retailer Name</p>
            </div>
            <div className="col-md-3">
              <p className="read-only text-right">{dealerName}</p>
              {/*<input className="form-control" id="dealerName" name="dealerName" defaultValue={profile.dealername} /> */}
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6">
              <div className="row form-group">
                <div className="col-md-6 form-label">
                  <p className="label">*Client First Name</p>
                </div>
                <div className="col-md-6 input-form">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="clientFirstName"
                    onChange={e => setClientFirstName(e.target.value)}
                    name="clientFirstName"
                    value={clientFirstName}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row form-group">
                <div className="col-md-6 form-label">
                  <p className="label">*Client Last Name</p>
                </div>
                <div className="col-md-6">
                  <input
                    required
                    type="text"
                    id="clientLastName"
                    className="form-control"
                    onChange={e => setClientLastName(e.target.value)}
                    name="clientLastName"
                    value={clientLastName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*Address</p>
            </div>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="clientAddress"
                name="clientAddress"
                onChange={e => setClientAddress(e.target.value)}
                value={clientAddress}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*City</p>
            </div>
            <div className="col-md-3">
              <input
                required
                type="text"
                className="form-control"
                onChange={e => setClientCity(e.target.value)}
                id="clientCity"
                name="clientCity"
                value={clientCity}
              />
            </div>
            <div className="col-md-1 form-label">
              <p className="label">*Province</p>
            </div>
            <div className="col-md-2">
              <select
                required
                className="form-control"
                onChange={e => setClientProvince(e.target.value)}
                multiple={false}
                name="clientProvince"
                id="clientProvince"
                defaultValue={''}
              >
                <option value="" disabled>
                  Select Province
                </option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland</option>
                <option value="NS">Nova Scotia</option>
                <option value="NW">Northwest Territories</option>
                <option value="NV">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YK">Yukon</option>
              </select>
            </div>
            <div className="col-md-1 form-label">
              <p className="label">*Postal Code</p>
            </div>
            <div className="col-md-2">
              <input
                required
                type="text"
                className="form-control"
                id="clientPostalCode"
                onChange={e => setClientPostalCode(e.target.value)}
                name="clientPostalCode"
                value={clientPostalCode}
                pattern="[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="row form-group">
                <div className="col-md-6 form-label">
                  <p className="label">*Client Email</p>
                </div>
                <div className="col-md-6">
                  <input
                    required
                    type="text"
                    className="form-control"
                    onChange={e => setClientEmail(e.target.value)}
                    id="clientEmail"
                    name="clientEmail"
                    value={clientEmail}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row form-group">
                <div className="col-md-6 form-label">
                  <p className="label">*Client Cell Phone</p>
                </div>
                <div className="col-md-6">
                  <input
                    required
                    type="tel"
                    className="form-control"
                    id="clientCellPhone"
                    onChange={e => setClientCellPhone(e.target.value)}
                    name="clientCellPhone"
                    value={clientCellPhone}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-md-12">
              <div className="radio-title form-label">
                <p>
                  * WHICH ELIGIBLE VEHICLE IS THE CLIENT INTERESTED IN (2023 or
                  2024 MY)?{' '}
                </p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div className="invalid-feedback">*Select an item</div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="radio_common radio_style1">
                <input
                  type="radio"
                  name="vehicleInterest"
                  id="Lincoln MKX / Nautilus"
                  onChange={e => setVehicleInterest(e.target.id)}
                  value={vehicleInterest}
                />
                <label htmlFor="Lincoln MKX / Nautilus">
                  <span></span>Lincoln Nautilus
                </label>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="radio_common radio_style1">
                <input
                  type="radio"
                  name="vehicleInterest"
                  id="Lincoln Aviator"
                  onChange={e => setVehicleInterest(e.target.id)}
                  value={vehicleInterest}
                />
                <label htmlFor="Lincoln Aviator">
                  <span></span>Lincoln Aviator
                </label>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="radio_common radio_style1">
                <input
                  type="radio"
                  name="vehicleInterest"
                  id="Lincoln Navigator"
                  onChange={e => setVehicleInterest(e.target.id)}
                  value={vehicleInterest}
                />
                <label htmlFor="Lincoln Navigator">
                  <span></span>Lincoln Navigator
                </label>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="radio_common radio_style1">
                <input
                  type="radio"
                  name="vehicleInterest"
                  id="Lincoln Corsair"
                  onChange={e => setVehicleInterest(e.target.id)}
                  value={vehicleInterest}
                />
                <label htmlFor="Lincoln Corsair">
                  <span></span>Lincoln Corsair
                </label>
              </div>
            </div>
          </div>
          <div className="row form-group mt-30">
            <div className="col-sm-12 form-label">
              <p className="label">*PICKUP/DELIVERY LOCATION (IF APPLICABLE)</p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-2 form-label">
              <div className="radio_common radio_style1 form-label">
                <input
                  type="radio"
                  name="dropoffLocation"
                  id="dealership"
                  value={dropoffLocation}
                  onChange={e => setDropoffLocation(e.target.id)}
                />
                <label htmlFor="dealership">
                  <span></span>Retailer
                </label>
              </div>
            </div>
            <div className="col-md-2 text-left">
              <div className="radio_common radio_style1 form-label">
                <input
                  type="radio"
                  name="dropoffLocation"
                  value={dropoffLocation}
                  onChange={e => setDropoffLocation(e.target.id)}
                  id="otherDropoff"
                />
                <label htmlFor="otherDropoff">
                  <span></span>Other
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                id="dropoffAltAddress"
                name="dropoffAltAddress"
                value={dropoffAltAddress}
                onChange={e => setDropoffAltAddress(e.target.value)}
                placeholder="Alternative Address"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-12">
              <p className="label">*PREFERRED METHOD OF COMMUNICATION</p>
            </div>
            <div className="col-md-2 col-sm-4">
              <div className="radio_common radio_style1">
                <input
                  name="communicationMethod"
                  type="radio"
                  value={communicationMethod}
                  onChange={e => setCommunicationMethod(e.target.id)}
                  id="Phone"
                />
                <label htmlFor="Phone">
                  <span></span>Phone
                </label>
              </div>
            </div>
            <div className="col-md-2 col-sm-4">
              <div className="radio_common radio_style1">
                <input
                  name="communicationMethod"
                  type="radio"
                  value={communicationMethod}
                  onChange={e => setCommunicationMethod(e.target.id)}
                  id="Email"
                />
                <label htmlFor="Email">
                  <span></span>Email
                </label>
              </div>
            </div>
            <div className="col-md-2 col-sm-4">
              <div className="radio_common radio_style1">
                <input
                  name="communicationMethod"
                  type="radio"
                  value={communicationMethod}
                  onChange={e => setCommunicationMethod(e.target.id)}
                  id="Text"
                />
                <label htmlFor="Text">
                  <span></span>Text
                </label>
              </div>
            </div>
          </div>

          {/*New Form Content from Validation */}

          <div className="row">
            <div className="col sm-12 col-md-6">
              <div className="row form-group mt-15 pb-10">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 form-label">
                      <p className="label">*Test Drive Start Date &amp; Time</p>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          required
                          type="date"
                          placeholder="MM/DD/YYYY"
                          className="form-control"
                          id="dateStart"
                          name="dateStart"
                          defautvalue={dateStart}
                          onChange={e => setDateStart(e.target.value)}
                        />
                      </div>
                      <div className="input-group">
                        <input
                          required
                          type="text"
                          className="timepicker form-control"
                          id="timeStart"
                          name="timeStart"
                          defaultValue={timeStart}
                          onChange={e => setTimeStart(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col sm-12 col-md-6">
              <div className="col-md-12 pt-10">
                <div className="row">
                  <div className="col-md-6 form-label">
                    <p className="label">*Test Drive End Date &amp; Time</p>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        required
                        placeholder="MM/DD/YYYY"
                        type="date"
                        className="form-control "
                        name="dateEnd"
                        id="dateEnd"
                        defaultValue={dateEnd}
                        onChange={e => setDateEnd(e.target.value)}
                      />
                    </div>
                    <div className="input-group">
                      <input
                        required
                        type="text"
                        className="timepicker form-control"
                        name="timeEnd"
                        id="timeEnd"
                        defaultValue={timeEnd}
                        onChange={e => setTimeEnd(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*Test Drive VIN</p>
            </div>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="testDriveVIN"
                onChange={e => setTestDriveVIN(e.target.value)}
                name="testDriveVIN"
                defaultValue={testDriveVIN}
                // pattern="\[a-zA-Z0-9]+{2}[\-]\[a-zA-Z0-9]+{8}"
                placeholder="First 2 - Last 8 (e.g. K1-GUL2S342)"
              />
            </div>
          </div>

          <div className="row form-group mt-30">
            <div className="col-md-3 form-label">
              <p className="label">*CURRENT COMPETITIVE VEHICLE BRAND</p>
            </div>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="currentVehicle"
                onChange={e => setCurrentVehicle(e.target.value)}
                name="currentVehicle"
                defaultValue={currentVehicle}
                placeholder="Current Competitive Brand"
              />
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*CURRENT COMPETITIVE VEHICLE VIN</p>
            </div>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="currentCompetitiveVehicleVIN"
                onChange={e => setCurrentCompetitiveVehicleVIN(e.target.value)}
                name="currentCompetitiveVehicleVIN"
                defaultValue={currentCompetitiveVehicleVIN}
                pattern="[A-Za-z0-9]{17}"
                placeholder="VIN must be a 17-digit alphanumeric value"
                maxLength={17}
              />
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">*Suprise And Delight Element Description</p>
            </div>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="supriseGift"
                onChange={e => setSupriseGift(e.target.value)}
                name="supriseGift"
                placeholder="Enter Description"
                defaultValue={supriseGift}
              />
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">Purchased a Lincoln after LDD?</p>
            </div>
            <div className="col-sm-1">
              <input
                type="checkbox"
                checked={madePurchaseAfterLDD}
                className="checkbox"
                onChange={() => setMadePurchaseAfterLDD(!madePurchaseAfterLDD)}
                id="madePurchaseAfterLDD"
                name="madePurchaseAfterLDD"
                defaultValue={madePurchaseAfterLDD}
              />
            </div>
          </div>

          <div className="row form-group mt-15 pb-10">
            <div className="col-md-12">
              <div className="row">
                <div className="form-label col-md-6">
                  <p className="label">
                    Was this LDD through a Referral Certificate?
                  </p>
                  <input
                    type="checkbox"
                    checked={isReferralCert}
                    className="checkbox"
                    onChange={() => setisReferralCert(!isReferralCert)}
                    id="isReferralCert"
                    name="isReferralCert"
                    defaultValue={isReferralCert}
                  />
                </div>
                <div className="form-label col-md-6">
                  <p className="label">If YES enter Referral Certificate #</p>
                  <input
                    disabled={!isReferralCert}
                    type="text"
                    className="form-control"
                    onChange={e => setReferralCertNum(e.target.value)}
                    id="referralCertNum"
                    name="referralCertNum"
                    value={referralCertNum}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-3 form-label">
              <p className="label">Comments</p>
            </div>
            <div className="col-md-9">
              <input
                type="text"
                className="form-control"
                id="dealerComments"
                onChange={e => setDealerComments(e.target.value)}
                name="dealerComments"
                defaultValue={dealerComments}
                placeholder="Enter Comments Here..."
              />
            </div>
          </div>

          <div className="row mb-10">
            <div className="col-sm-12 text-center">
              <p>
                All information must be completed in full in order to submit.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <button className="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        hideProgressBar="true"
        autoClose="5000"
        style={{ width: '600px' }}
      />
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    dealerCode: state.firebase.profile.dealerCode,
    dealerName: state.firebase.profile.dealerName,
    clients: state.firestore.ordered.clients,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createClient: (
      client,
      createClientSuccessCallback,
      createClientErrorCallback
    ) =>
      dispatch(
        createClient(
          client,
          createClientSuccessCallback,
          createClientErrorCallback
        )
      ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'clients' }])
)(DealerForm)
