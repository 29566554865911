import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const DealerMenuFr = props => {
  return (
    <React.Fragment>
      {/* <li>
        <NavLink to="/">Home</NavLink>
      </li> */}
      <li>
        <Link to="#!">PROGRAMME</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/whats-new-fr">ACTIFS DU PROGRAMME</NavLink>
          </li>
          <li>
            <NavLink to="/program-parameters-fr">DÉTAILS DU PROGRAMME</NavLink>
          </li>
          <li>
            <NavLink to="/faq-fr">FAQ</NavLink>
          </li>
          <li>
            <NavLink to="/quick-tips-fr">ASTUCES RAPIDES</NavLink>
          </li>
          {/* <li>
        <NavLink to="/support-fr">Soutien</NavLink>
      </li> */}
        </ul>
      </li>
      <li>
        <Link to="#!">Formulaires</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/client-new-fr">FORMULAIRE D'INSCRIPTION</NavLink>
          </li>
          {/* <li>
            <NavLink to="/client-validation-fr">Formulaire de validation</NavLink>
          </li> */}
        </ul>
      </li>
      <li>
        <NavLink to="/dealer-report-fr">Rapports</NavLink>
      </li>
    </React.Fragment>
  )
}

export default DealerMenuFr
