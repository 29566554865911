import React from 'react'

const QuickTips = () => {
  return (
    <section className="support pt-60 pb-60">
      <div className="container">
        <div className="row mt-20">
          <div className="faq-section-title text-center col-lg-12 mb-20">
            <h3 className="section-title">
              Lincoln Discovery Drive – Retailer Quick Tips
            </h3>
          </div>
          <div className="col-lg-12 mt-15 single-faq">
            <p className="sub-title">
              The tips below will help you get started and easily navigate
              through all the resources on the tool quickly and effortlessly.
            </p>
            <ul>
              <li>
                <p>
                  <span>1.</span> Under the “LDD Program”, in “Program Assets”,
                  you will find the following resources:
                </p>
                <ul>
                  <li>
                    <p>
                      <span>a.</span>Vehicle Quick Reference Cards (to be left
                      in vehicle for client)
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>b.</span>Customizable client letter (personalized
                      letter to be left in vehicle for client)
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>c.</span>Creative assets including web banner,
                      program letterhead and program logos
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <span>2.</span>You can access a new client Registration Form
                  from the “Home Page” or from the dropdown “Forms” menu at the
                  top of the page.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>3.</span> Once you have completed a new client
                  Registration Form, a random Order Number will be created for
                  that client. This Order Number, shown in your Retailer Report
                  (under the “Reports” tab) can be used to access your client’s
                  registration information.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>4.</span> The generated Order Number will also be
                  referenced on your Parts Statement so you can confirm payment
                  for your approved LDDs.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>5.</span>The Retailer Report page will save all your
                  test drive participant information and you can also click on
                  the “View Details” to see all client information.{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuickTips
