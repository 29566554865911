import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const AdminMenu = props => {
  const uisList = ['el4VWYwksAS9MryJ3ogkCqIAQmg1']
  return (
    <React.Fragment>
      {/* <li>
        <NavLink to="/">Home</NavLink>
      </li> */}
      <li>
        <Link to="#!">LDD Program</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/whats-new">PROGRAM ASSETS</NavLink>
          </li>
          <li>
            <NavLink to="/program-parameters">PROGRAM DETAILS</NavLink>
          </li>
          <li>
            <NavLink to="/faq">FAQ's</NavLink>
          </li>
          <li>
            <NavLink to="/quick-tips">QUICK TIP'S</NavLink>
          </li>
          {/* <li>
              <NavLink to="/support">Support</NavLink>
            </li> */}
        </ul>
      </li>
      {props && props.auth && uisList.indexOf(props.auth.uid) === -1 ? (
        <li>
          <Link to="#!">Forms</Link>
          <ul className="sub-menu">
            <li>
              <NavLink to="/client-new">Registration Form</NavLink>
            </li>
            {/*<li>
            <NavLink to="/client-validation">Validation</NavLink>
          </li>*/}
            <li>
              <NavLink to="/admin-update">Update a Registration Form</NavLink>
            </li>
          </ul>
        </li>
      ) : null}
      {props &&
      props.auth &&
      uisList.indexOf(props.auth.uid) === -1 &&
      props.profile &&
      props.profile.userRole &&
      props.profile.userRole === 'Admin' ? (
        <li>
          <Link to="#!">User Management</Link>
          <ul className="sub-menu">
            <li>
              <NavLink to="/create-account">Create User</NavLink>
            </li>
            <li>
              <NavLink to="/user-accounts">Update Users</NavLink>
            </li>
          </ul>
        </li>
      ) : null}
      <li>
        <Link to="#!">Reports</Link>
        <ul className="sub-menu">
          {props && props.auth && uisList.indexOf(props.auth.uid) === -1 ? (
            <li>
              <NavLink to="/admin-report">Admin Report</NavLink>
            </li>
          ) : null}
          {/* <li>
            <NavLink to='/risk-list'>Risk List</NavLink>
          </li> */}
          <li>
            <NavLink to="/regional-report">Regional Report</NavLink>
          </li>
          <li>
            <NavLink to="/national-report">National Report</NavLink>
          </li>
        </ul>
      </li>
    </React.Fragment>
  )
}

export default AdminMenu
