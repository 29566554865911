import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Logo from '../images/logo.png'
import MainMenu from '../containers/mainMenu'

const Navbar = ({ profile, auth }) => {
  let location = useLocation()
  const [isTestDriveForm, setIsTestDriveForm] = React.useState(false)

  React.useEffect(() => {
    const path = location.pathname.split('/')[1]
    setIsTestDriveForm(path === 'test-drive-form')
  }, [location])

  return (
    <React.Fragment>
      <div className="header-area">
        <div className="container">
          <div className="row">
            <div className="col-8 col-sm-4 col-lg-2 order-0 order-sm-0">
              <div className="logo">
                <a href="/fr">
                  <img src={Logo} alt="Logo" />
                </a>
              </div>
            </div>
            <div
              className={
                (isTestDriveForm ? 'hidden' : 'col-12') +
                ' col-sm-6 col-lg-8 order-2 order-sm-1'
              }
            >
              {!isTestDriveForm && <MainMenu profile={profile} auth={auth} />}
            </div>
            <div className="col-4 col-sm-2 col-lg-2 order-1 order-sm-2">
              <div className="language text-right">
                <ul>
                  {isTestDriveForm ? (
                    <>
                      <li>
                        <a href="/test-drive-form">En</a>
                      </li>
                      <NavLink
                        className={'navBarCustom'}
                        to="/test-drive-form-fr"
                      >
                        Fr
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <li>
                        <a href="/">En</a>
                      </li>
                      <NavLink className={'navBarCustom'} to="/fr">
                        Fr
                      </NavLink>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
