import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import Select from 'react-select'

import _ from 'lodash'

import Header from '../containers/headerFr'
import Footer from '../components/footerFr'
import NationalCSV from '../containers/nationalCSV'
import { firestore as db } from '../config/fbConfig'

const NationalReportFr = props => {
  const [selectedYear, setSelectedYear] = useState({
    label: 'Tout',
    value: 'All',
  })
  const [totalRegisteredByYear, setTotalRegisteredByYear] = useState([])
  const [totalApprovedByYear, setTotalApprovedByYear] = useState([])
  const [totalSalesByYear, setTotalSalesByYear] = useState([])
  const [totalCreditsListForYear, setTotalCreditsListForYear] = useState(0)
  const [totalRegisteredWesternByYear, setTotalRegisteredWesternByYear] =
    useState([])
  const [totalApprovedWesternByYear, setTotalApprovedWesternByYear] = useState(
    []
  )
  const [totalSalesWesternByYear, setTotalSalesWesternByYear] = useState([])
  const [totalCreditsWesternByYear, setTotalCreditsWesternByYear] = useState(0)
  const [totalRegisteredCentralByYear, setTotalRegisteredCentralByYear] =
    useState([])
  const [totalApprovedCentralByYear, setTotalApprovedCentralByYear] = useState(
    []
  )
  const [totalSalesCentralByYear, setTotalSalesCentralByYear] = useState([])
  const [totalCreditsCentralByYear, setTotalCreditsCentralByYear] = useState(0)
  const [totalRegisteredEasternByYear, setTotalRegisteredEasternByYear] =
    useState([])
  const [totalApprovedEasternByYear, setTotalApprovedEasternByYear] = useState(
    []
  )
  const [totalSalesEasternByYear, setTotalSalesEasternByYear] = useState([])
  const [totalCreditsEasternByYear, setTotalCreditsEasternByYear] = useState(0)
  const [users, setUsers] = useState([])

  useEffect(() => {
    db.collection('users').onSnapshot(snapshot => {
      setUsers(snapshot.docs.map(doc => doc.data()))
    })
  }, [])

  let clientOptionArray = []
  clientOptionArray.push({ label: 'Tout', value: 'All' })
  const { clients, profile, auth } = props
  if (!auth.uid) return <Navigate to="/" />
  //  Starting Varriables

  let totalRegistered = []
  let totalApproved = []
  let totalSales = []
  let totalCreditsList = [0]
  let totalCreditsContact
  let totalCredits

  let totalRegisteredEastern = []
  let totalApprovedEastern = []
  let totalSalesEastern = []
  let totalCreditsEasternList = [0]
  let totalCreditsEasternContact
  let totalCreditsEastern

  let totalRegisteredCentral = []
  let totalApprovedCentral = []
  let totalSalesCentral = []
  let totalCreditsCentralList = [0]
  let totalCreditsCentralContact
  let totalCreditsCentral

  let totalRegisteredWestern = []
  let totalApprovedWestern = []
  let totalSalesWestern = []
  let totalCreditsWesternList = [0]
  let totalCreditsWesternContact
  let totalCreditsWestern

  let clientByYearMap = new Map()

  //  Functions & Calculations
  if (clients) {
    clients.forEach((client, index) => {
      client = _.cloneDeep(client)
      totalRegistered.push(client)
      if (client.validated === 'true') {
        totalApproved.push(client)
        totalCreditsContact = client.maxLddMonth === '24h' ? 150 : 250
        totalCreditsList.push(totalCreditsContact)
      }
      if (
        client.hasPurchased &&
        (client.hasPurchased === 'on' ||
          client.hasPurchased === 'true' ||
          client.hasPurchased === true)
      ) {
        totalSales.push(client)
      }
      if (client.region === 'Eastern') {
        totalRegisteredEastern.push(client)
        if (client.validated === 'true') {
          totalApprovedEastern.push(client)
          totalCreditsEasternContact = client.maxLddMonth === '24h' ? 150 : 250
          totalCreditsEasternList.push(totalCreditsEasternContact)
        }
        if (
          client.hasPurchased &&
          (client.hasPurchased === 'on' ||
            client.hasPurchased === 'true' ||
            client.hasPurchased === true)
        ) {
          totalSalesEastern.push(client)
        }
      }
      if (client.region === 'Central') {
        totalRegisteredCentral.push(client)
        if (client.validated === 'true') {
          totalApprovedCentral.push(client)
          totalCreditsCentralContact = client.maxLddMonth === '24h' ? 150 : 250
          totalCreditsCentralList.push(totalCreditsCentralContact)
        }
        if (
          client.hasPurchased &&
          (client.hasPurchased === 'on' ||
            client.hasPurchased === 'true' ||
            client.hasPurchased === true)
        ) {
          totalSalesCentral.push(client)
        }
      }
      if (client.region === 'Western') {
        totalRegisteredWestern.push(client)
        if (client.validated === 'true') {
          totalApprovedWestern.push(client)
          totalCreditsWesternContact = client.maxLddMonth === '24h' ? 150 : 250
          totalCreditsWesternList.push(totalCreditsWesternContact)
        }
        if (
          client.hasPurchased &&
          (client.hasPurchased === 'on' ||
            client.hasPurchased === 'true' ||
            client.hasPurchased === true)
        ) {
          totalSalesWestern.push(client)
        }
      }
      if (client.submission) {
        try {
          let submissionYear = new Date(client.submission).getFullYear()
          client.submissionYear = submissionYear
          if (clientByYearMap.get(submissionYear)) {
            clientByYearMap.get(submissionYear).push(client)
          } else {
            let clientsArray = []
            clientsArray.push(client)
            clientByYearMap.set(submissionYear, clientsArray)
          }
        } catch (error) {
          //ignore
          console.log(error)
        }
      }
      return client
    })
  }

  let yearsList = Array.from(clientByYearMap.keys())
  yearsList = yearsList.sort()
  yearsList.forEach(ele => {
    let opt = clientOptionArray.find(ele2 => ele2.value === ele.toString())
    if (!opt) {
      clientOptionArray.push({ value: ele.toString(), label: ele.toString() })
    }
  })

  //  Total Credits Function
  if (totalCreditsList) {
    totalCredits = totalCreditsList.reduce((a, b) => a + b)
  }

  if (totalCreditsEasternList) {
    totalCreditsEastern = totalCreditsEasternList.reduce((a, b) => a + b)
  }

  if (totalCreditsCentralList) {
    totalCreditsCentral = totalCreditsCentralList.reduce((a, b) => a + b)
  }

  if (totalCreditsWesternList) {
    totalCreditsWestern = totalCreditsWesternList.reduce((a, b) => a + b)
  }

  //  Final Varriables
  let totalRegisteredFinal = totalRegistered.length
  let totalApprovedFinal = totalApproved.length
  let totalSalesFinal = totalSales.length

  //  Eastern
  let totalRegisteredEasternFinal = totalRegisteredEastern.length
  let totalApprovedEasternFinal = totalApprovedEastern.length
  let totalSalesEasternFinal = totalSalesEastern.length

  //  Central
  let totalRegisteredCentralFinal = totalRegisteredCentral.length
  let totalApprovedCentralFinal = totalApprovedCentral.length
  let totalSalesCentralFinal = totalSalesCentral.length

  //  Western
  let totalRegisteredWesternFinal = totalRegisteredWestern.length
  let totalApprovedWesternFinal = totalApprovedWestern.length
  let totalSalesWesternFinal = totalSalesWestern.length

  const updateTotals = option => {
    let totalRegisteredByYear = []
    let totalApprovedByYear = []
    let totalSalesByYear = []
    let totalCreditsListForYear = 0
    let year = 0
    let totalRegisteredWesternByYear = []
    let totalApprovedWesternByYear = []
    let totalSalesWesternByYear = []
    let totalCreditsWesternByYear = 0
    let totalRegisteredCentralByYear = []
    let totalApprovedCentralByYear = []
    let totalSalesCentralByYear = []
    let totalCreditsCentralByYear = 0
    let totalRegisteredEasternByYear = []
    let totalApprovedEasternByYear = []
    let totalSalesEasternByYear = []
    let totalCreditsEasternByYear = 0
    if (users && option.value !== 'All') {
      year = Number(option.value)
      totalRegistered.forEach(ele => {
        if (ele.submissionYear && ele.submissionYear === year) {
          totalRegisteredByYear.push(ele)
        }
      })
      totalApproved.forEach(ele => {
        if (ele.submissionYear && ele.submissionYear === year) {
          totalApprovedByYear.push(ele)
          let credit = ele.maxLddMonth === '24h' ? 150 : 250
          totalCreditsListForYear += credit
        }
      })
      totalSales.forEach(ele => {
        if (ele.submissionYear && ele.submissionYear === year) {
          totalSalesByYear.push(ele)
        }
      })
      let clientsByYear = clientByYearMap.get(year)
      clientsByYear.forEach(client => {
        let credit = client.maxLddMonth === '24h' ? 150 : 250
        if (client.region === 'Central') {
          totalRegisteredCentralByYear.push(client)
          if (client.validated === 'true') {
            totalApprovedCentralByYear.push(client)
            totalCreditsCentralByYear += credit
          }
          if (
            client.hasPurchased &&
            (client.hasPurchased === 'on' ||
              client.hasPurchased === 'true' ||
              client.hasPurchased === true)
          ) {
            totalSalesCentralByYear.push(client)
          }
        }
        if (client.region === 'Eastern') {
          totalRegisteredEasternByYear.push(client)
          if (client.validated === 'true') {
            totalApprovedEasternByYear.push(client)
            totalCreditsEasternByYear += credit
          }
          if (
            client.hasPurchased &&
            (client.hasPurchased === 'on' ||
              client.hasPurchased === 'true' ||
              client.hasPurchased === true)
          ) {
            totalSalesEasternByYear.push(client)
          }
        }
        if (client.region === 'Western') {
          totalRegisteredWesternByYear.push(client)
          if (client.validated === 'true') {
            totalApprovedWesternByYear.push(client)
            totalCreditsWesternByYear += credit
          }
          if (
            client.hasPurchased &&
            (client.hasPurchased === 'on' ||
              client.hasPurchased === 'true' ||
              client.hasPurchased === true)
          ) {
            totalSalesWesternByYear.push(client)
          }
        }
      })
      totalRegisteredFinal = totalRegisteredByYear.length
      totalApprovedFinal = totalApproved.length
      totalSalesFinal = totalSalesByYear.length
      totalCredits = totalCreditsListForYear
    } else {
      totalRegisteredByYear = totalRegistered
      totalApprovedByYear = totalApproved
      totalSalesByYear = totalSales
      totalCreditsListForYear = totalCredits
      totalRegisteredWesternByYear = totalRegisteredWestern
      totalApprovedWesternByYear = totalApprovedWestern
      totalSalesWesternByYear = totalSalesWestern
      totalCreditsWesternByYear = totalCreditsWestern
      totalRegisteredCentralByYear = totalRegisteredCentral
      totalApprovedCentralByYear = totalApprovedCentral
      totalSalesCentralByYear = totalSalesCentral
      totalCreditsCentralByYear = totalCreditsCentral
      totalRegisteredEasternByYear = totalRegisteredEastern
      totalApprovedEasternByYear = totalApprovedEastern
      totalSalesEasternByYear = totalSalesEastern
      totalCreditsEasternByYear = totalCreditsEastern
    }
    setSelectedYear(option)
    setTotalRegisteredByYear(totalRegisteredByYear)
    setTotalApprovedByYear(totalApprovedByYear)
    setTotalSalesByYear(totalSalesByYear)
    setTotalCreditsListForYear(totalCreditsListForYear)
    setTotalRegisteredWesternByYear(totalRegisteredWesternByYear)
    setTotalApprovedWesternByYear(totalApprovedWesternByYear)
    setTotalSalesWesternByYear(totalSalesWesternByYear)
    setTotalCreditsWesternByYear(totalCreditsWesternByYear)
    setTotalRegisteredCentralByYear(totalRegisteredCentralByYear)
    setTotalApprovedCentralByYear(totalApprovedCentralByYear)
    setTotalSalesCentralByYear(totalSalesCentralByYear)
    setTotalCreditsCentralByYear(totalCreditsCentralByYear)
    setTotalRegisteredEasternByYear(totalRegisteredEasternByYear)
    setTotalApprovedEasternByYear(totalApprovedEasternByYear)
    setTotalSalesEasternByYear(totalSalesEasternByYear)
    setTotalCreditsEasternByYear(totalCreditsEasternByYear)
  }

  return (
    <React.Fragment>
      <Header />
      <div className="container pt-60 pb-60">
        <div className="row">
          <div className="col-sm-12 registration-section-title pb-20">
            <h4 className="section-title">RAPPORT À L’ÉCHELLE NATIONALE</h4>
            <div className="col-sm-12 pt-20">
              <h4>
                BIENVENUE À LA VUE D’ENSEMBLE NATIONALE SUR LE TABLEAU DE BORD
                EXPÉRIENCE DÉCOUVERTE LINCOLN!
              </h4>
              <p>
                Vous trouverez ci-dessous le bilan des Expériences
                découvertes Lincoln.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 registration-section-title pb-20">
            <Select
              id="submissionYear"
              options={clientOptionArray}
              onChange={value => updateTotals(value)}
              value={selectedYear}
            />
          </div>
        </div>

        <div className="row mt-30">
          <div className="col-sm-12">
            <div className="row gutters">
              <div className="col-md-3 col-sm-6">
                <div className=" number-feature">
                  <p className="title">
                    Nombre total
                    <br />
                    d'essais enregistrés
                  </p>
                  <p className="count">
                    {selectedYear.value !== 'All'
                      ? totalRegisteredByYear.length
                      : totalRegisteredFinal}
                  </p>
                </div>
              </div>
              <div className="col-md-3  col-sm-6">
                <div className=" number-feature">
                  <p className="title">
                    TOTAL
                    <br />
                    DES APPROBATIONS
                  </p>
                  <p className="count">
                    {selectedYear.value !== 'All'
                      ? totalApprovedByYear.length
                      : totalApprovedFinal}
                  </p>
                </div>
              </div>
              <div className="col-md-3  col-sm-6">
                <div className=" number-feature">
                  <p className="title">
                    TOTAL DES CRÉDITS <br />
                    DU CONCESSIONNAIRE
                  </p>
                  <p className="count">
                    $
                    {selectedYear.value !== 'All'
                      ? totalCreditsListForYear
                      : totalCredits}
                  </p>
                </div>
              </div>
              <div className="col-md-3  col-sm-6">
                <div className=" number-feature">
                  <p className="title">
                    VENTES
                    <br />
                    CONFIRMÉES
                  </p>
                  <p className="count">
                    {selectedYear.value !== 'All'
                      ? totalSalesByYear.length
                      : totalSalesFinal}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 pt-20 pb-20"></div>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-sm-12">
            <span>
              Vous pouvez également exporter un résumé complet des essais
              routiers menés pour ce concessionnaire, afin d'en prendre
              connaissance.
            </span>
          </div>
          <div className="col-sm-12 text-right">
            {profile.userRole === 'Admin' ||
            profile.userRole === 'Regional' ||
            profile.userRole === 'National' ? (
              <NationalCSV
                users={users}
                clients={
                  selectedYear.value === 'All'
                    ? clients
                    : clientByYearMap.get(Number(selectedYear.value)) || clients
                }
                profile={profile}
                filename={'National-Export'}
                isFrench={false}
              />
            ) : null}
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-sm-12">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">
                    NOMBRE TOTAL
                    <br />
                    D'ESSAIS ENREGISTRÉS
                  </th>
                  <th className="text-center">TOTAL DES APPROBATIONS </th>
                  <th className="text-center">
                    ESSAIS TOTAUX DE CONDUITE
                    <br />
                    QUI RESTE POUR L’ANNÉE CIVILE
                  </th>
                  <th className="text-center">
                    TOTAL DES CRÉDITS DU CONCESSIONNAIRE{' '}
                  </th>
                  <th className="text-center">VENTES CONFIRMÉES</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">Est</td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalRegisteredEasternFinal
                      : totalRegisteredEasternByYear.length}
                  </td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalApprovedEasternFinal
                      : totalApprovedEasternByYear.length}
                  </td>
                  <td className="text-center">2640</td>
                  <td className="text-center">
                    $
                    {selectedYear.value === 'All'
                      ? totalCreditsEastern
                      : totalCreditsEasternByYear}
                  </td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalSalesEasternFinal
                      : totalSalesEasternByYear.length}
                  </td>
                </tr>
                <tr>
                  <td className="text-center">Centre</td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalRegisteredCentralFinal
                      : totalRegisteredCentralByYear.length}
                  </td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalApprovedCentralFinal
                      : totalApprovedCentralByYear.length}
                  </td>
                  <td className="text-center">3040</td>
                  <td className="text-center">
                    $
                    {selectedYear.value === 'All'
                      ? totalCreditsCentral
                      : totalCreditsCentralByYear}
                  </td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalSalesCentralFinal
                      : totalSalesCentralByYear.length}
                  </td>
                </tr>
                <tr>
                  <td className="text-center">Quest</td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalRegisteredWesternFinal
                      : totalRegisteredWesternByYear.length}
                  </td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalApprovedWesternFinal
                      : totalApprovedWesternByYear.length}
                  </td>
                  <td className="text-center">2160</td>
                  <td className="text-center">
                    $
                    {selectedYear.value === 'All'
                      ? totalCreditsWestern
                      : totalCreditsWesternByYear}
                  </td>
                  <td className="text-center">
                    {selectedYear.value === 'All'
                      ? totalSalesWesternFinal
                      : totalSalesWesternByYear.length}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  clients: state.firestore.ordered.clients,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
})

export default compose(
  firestoreConnect(() => ['clients']),
  connect(mapStateToProps)
)(NationalReportFr)
