import React from 'react'
import ZipImg from '../images/zip.png'
import DocImg from '../images/docx.png'

const WhatsNewEn = () => {
  return (
    <React.Fragment>
      <section class="card-area pt-60 pb-60">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="card-section-title text-center">
                <h4 class="section-title mb-45">PROGRAM ASSETS</h4>
                <p>
                  Below you have access to all Lincoln Discovery Drive creative
                  and client support materials.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="single-card mt-30">
                <div class="card-titles">
                  <h3>LDD CREATIVE ASSETS</h3>
                </div>
                <div class="card-text">
                  <p>
                    Creative Assets for Retailers include: LDD logos, LDD
                    letterhead banners, LDD web banners.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-card mt-30">
                <div class="card-titles">
                  <h3>
                    LDD CliEnt <br /> Welcome letter
                  </h3>
                </div>
                <div class="card-text">
                  <p>
                    LDD Welcome Letter is customizable for each Client, and is
                    to be signed by the Retailer. It is to be left on the
                    driver’s seat (with the Vehicle Quick Refence Card) for each
                    Discovery Drive.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-card mt-30">
                <div class="card-titles">
                  <h3>LDD vehicle quick reference card</h3>
                </div>
                <div class="card-text">
                  <p>
                    LDD Vehicle Quick Refence Card is available for each program
                    vehicle and the applicable Vehicle Card is to be left on the
                    driver’s seat (with the Client Welcome Letter) for each
                    Discovery Drive.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">ENGLISH</h6>
                <a href="downloads/Retailer Assets - English.zip">
                  <img src={ZipImg} alt="" />
                  CLICK HERE
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">ENGLISH</h6>
                <a href="downloads/LDD Client Welcome Letter.docx">
                  <img src={DocImg} alt="" />
                  CLICK HERE
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2023 ENGLISH</h6>
                <a href="downloads/2023 Vehicle Quick Reference Cards.zip">
                  <img src={ZipImg} alt="" />
                  <span>CLICK HERE</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2024 ENGLISH</h6>
                <a href="downloads/LDD Client Spec Card - EN.pdf">
                  <img src={ZipImg} alt="" />
                  <span>CLICK HERE</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">FRENCH</h6>
                <a href="downloads/Retailer Assets - French.zip">
                  <img src={ZipImg} alt="" />
                  CLICK HERE
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">FRENCH</h6>
                <a href="downloads/Lettre de Bienvenue du Client.docx">
                  <img src={DocImg} alt="" />
                  CLICK HERE
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2023 FRENCH</h6>
                <a href="downloads/2023 Cartes de Référence Rapide des Véhicules.zip">
                  <img src={ZipImg} alt="" />
                  <span>CLICK HERE</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2024 FRENCH</h6>
                <a href="downloads/LDD Client Spec Card - FR.pdf">
                  <img src={ZipImg} alt="" />
                  <span>CLICK HERE</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default WhatsNewEn
