import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const RegionalMenu = props => {
  return (
    <React.Fragment>
      {/* <li>
        <NavLink to="/">Home</NavLink>
      </li> */}
      <li>
        <Link to="#!">LDD Program</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/whats-new">PROGRAM ASSETS</NavLink>
          </li>
          <li>
            <NavLink to="/program-parameters">PROGRAM DETAILS</NavLink>
          </li>
          <li>
            <NavLink to="/faq">FAQ's</NavLink>
          </li>
          <li>
            <NavLink to="/quick-tips">QUICK TIP'S</NavLink>
          </li>
          {/* <li>
              <NavLink to="/support">Support</NavLink>
            </li> */}
        </ul>
      </li>
      {/* <li>
        <Link to="#!">Forms</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/client-new">Register Client</NavLink>
          </li>
          <li>
            <NavLink to="/client-validation">Validation</NavLink>
          </li>
          <li>
            <NavLink to="/admin-update">Client Update</NavLink>
          </li>
        </ul>
      </li> */}
      <li>
        <Link to="#!">Reports</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/regional-report">Regional Report</NavLink>
          </li>
          {props && props.profile && props.profile.userRole === 'National' ? (
            <li>
              <NavLink to="/national-report">National Report</NavLink>
            </li>
          ) : null}
        </ul>
      </li>
    </React.Fragment>
  )
}

export default RegionalMenu
