import React from 'react'

const QuickTipsFr = () => {
  return (
    <section className="support pt-60 pb-60">
      <div className="container">
        <div className="row mt-20">
          <div className="faq-section-title text-center col-lg-12 mb-20">
            <h3 className="section-title">
              L’Expérience découverte Lincoln – Auto-conseils du Détaillant
            </h3>
          </div>
          <div className="col-lg-12 mt-15 single-faq">
            <p className="sub-title">
              Les conseils ci-dessous vous aideront à commencer à utiliser les
              ressources sur l’outil et à vous y retrouver rapidement et sans
              effort.
            </p>
            <ul>
              <li>
                <p>
                  <span>1.</span>À l’onglet « Programme », sous « Actifs du
                  programme », vous trouverez les ressources suivantes:
                </p>
                <ul>
                  <li>
                    <p>
                      <span>a.</span>Des fiches de référence rapide du véhicule
                      (à laisser dans le véhicule pour le client)
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>b.</span>Une lettre au client personnalisable
                      (lettre personnalisée à laisser dans le véhicule pour le
                      client)
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>c.</span>Du matériel publicitaire, par exemple des
                      bannières Web, du papier à en-tête de programmes et des
                      logos de programmes
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <span>2.</span>Vous pouvez accéder à un nouveau formulaire
                  d’inscription de client à partir de la "paged’accueil" ou du
                  menu déroulant « Formulaires » dans le haut de la page.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>3.</span> Lorsque vous avez rempli un nouveau formulaire
                  d’inscription de client, un numéro de commande aléatoire sera
                  créé pour ce client. Ce numéro de commande, affiché dans votre
                  rapport du Détaillant (à l’onglet « Rapports ») peut servir à
                  accéder aux renseignements d’inscription de votre client.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>4.</span> Le numéro de commande généré figurera aussi
                  sur votre relevé des pièces de manière à ce que vous puissiez
                  confirmer le paiement pour vos événements Expérience
                  découverte Lincoln approuvés.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>5.</span>La page du rapport du Détaillant présentera
                  tous les renseignements des participants à des essais
                  routiers, et vous pouvez aussi cliquer sur « Voir les détails
                  » pour voir tous les renseignements sur le client.{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuickTipsFr
