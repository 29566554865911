import React from 'react'
import Header from '../containers/headerFr'
import Footer from '../components/footerFr'
import ProgramParameters from '../components/programParametersFr'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'

const ProgramParametersFr = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/fr" />

  const programParametersFr = <ProgramParameters />
  return (
    <React.Fragment>
      <Header />
      {programParametersFr}
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default compose(connect(mapStateToProps))(ProgramParametersFr)
