import React from 'react'

const FooterFr = () => {
  return (
    <div className="footer-area pt-30 pb-30 custom-footer">
      <div className="container">
        <div className="row">
          <div className="text-center mb-25">
            <p className="">
              Pour obtenir du soutien ou de l’aide avec le programme Expérience
              découverte Lincoln,veuillez communiquer avec l’ambassadeur du
              programme,
              <br /> au Secrétariat du programme:{' '}
              <b>
                <a href="mailto:LDDambassador@Lincolndiscoverydrive.com">
                  LDDambassador@Lincolndiscoverydrive.com
                </a>{' '}
                1.833.717.0017
              </b>
            </p>
          </div>
          <div className="col-lg-12">
            <p style={{ textAlign: 'center' }}>
              Lincoln Canada peut décider, à tout moment, de modifier ou
              d’annuler ce programme. La participation des détaillants est
              volontaire. Le Secrétariat du programme Expérience découverte
              Lincoln associera des participants au détaillant participant le
              plus près. L’accès au système est limité aux utilisateurs
              autorisés, soit les détaillants Lincoln Canada, leurs clients et
              les acheteurs au détail potentiels de véhicules de luxe. Quiconque
              tente d’accéder au système sans y être autorisé sera poursuivi.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterFr
