import React from 'react'
import { NavLink } from 'react-router-dom'

const LoggedOutMenuFr = props => {
  return (
    <ul>
      {props & props.auth ? (
        <li>
          <NavLink to="/whats-new-fr">ACTIFS DU PROGRAMME</NavLink>
        </li>
      ) : null}
      {props & props.auth ? (
        <li>
          <NavLink to="/faq-fr">FAQ</NavLink>
        </li>
      ) : null}
      {/* <li>
        <NavLink to='/support-fr'>Soutien</NavLink>
      </li> */}
      <li>
        <NavLink to="/fr#login-">Connexion</NavLink>
      </li>
    </ul>
  )
}

export default LoggedOutMenuFr
