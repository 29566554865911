import React from 'react'

export function Filter({ column, filterType = 'input', options = [] }) {
  const columnFilterValue = column.getFilterValue()

  return filterType === 'input' ? (
    <DebouncedInput
      type="text"
      value={columnFilterValue ?? ''}
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      className="border shadow rounded"
      style={{ width: '100%', padding: '4px' }}
      list={column.id + 'list'}
    />
  ) : (
    <select
      defaultValue={columnFilterValue || options[0]}
      onChange={e => {
        if (e.target.value === 'null') {
          column.setFilterValue(null)
        } else {
          column.setFilterValue(e.target.value)
        }
      }}
    >
      {options.map(option => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
