import React from 'react'
import intro1 from '../images/intro-2/intro-1.jpg'
import intro2 from '../images/intro-2/intro-2.jpg'
import intro3 from '../images/intro-2/intro-3.jpg'
import intro4 from '../images/intro-2/intro-4.jpg'
import intro5 from '../images/intro-2/intro-5.jpg'

const IntroWelcome = () => {
  return (
    <section className="intro-area pt-60 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="intro-title">
              <h3 className="title text-center">
                Welcome To Lincoln Discovery Drive
              </h3>
              <h5 className="sub-title text-left mt-30">
                Thank you for making Lincoln Discovery Drive part of your plan
                to demonstrate Lincoln’s effortless experiences that are Warm,
                Human and Personally Crafted. Lincoln Discovery Drive sets us
                apart from other luxury brands and has played an important role
                in bringing competitive luxury vehicle shoppers to the Lincoln
                Brand.
                <br />
                <br />
                <i>
                  “Discovery Drive totally changed my opinion of Lincoln. I
                  previously drove a Mercedes but bought the Corsair after my
                  Discovery Drive experience!” A. Brook, Quebec
                </i>
                <br />
                <br />
                The Lincoln Discovery Drive Online Tool provides you with
                everything you need to see success from this program. Let’s get
                started.{' '}
              </h5>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro1} alt="intro" />
              </div>
              {/* <div className="intro-content-2 text-center">
                                <p>Showcase Lincoln’s personalized services such as Concierge, Pickup & Delivery for an “effortless” experience</p>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro2} alt="intro" />
              </div>
              {/* <div className="intro-content-2 text-center">
                                <p>Demonstrate “Warm, Human and Personally Crafted” </p>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro3} alt="intro" />
              </div>
              {/* <div className="intro-content-2 text-center">
                                <p>Improve Brand opinion through increased unique drive experiences</p>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro4} alt="intro" />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="single-intro-2 mt-30">
              <div className="intro-image text-center">
                <img src={intro5} alt="intro" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroWelcome
