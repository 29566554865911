import React from 'react'
import { Link } from 'react-router-dom'

const AllRiskList = ({ clients, profile }) => {
  if (clients) {
    return (
      <>
        {clients &&
          clients.forEach(client => {
            if (client.redList === 'true') {
              const validationLink = '/client-validation/' + client.id

              const details = (
                <Link to={'/client/' + client.id} key={client.id}>
                  View Details
                </Link>
              )
              const update = (
                <Link to={'/client-update/' + client.id} key={client.id}>
                  Update Client
                </Link>
              )
              const adminEdit = profile.userRole === 'Admin' ? update : details

              const vinNum = client.testDriveVIN ? client.testDriveVIN : '--'
              const testDriveDate = client.dateStart ? client.dateStart : '--'
              const testDriveLength = client.maxLddMonth
                ? client.maxLddMonth
                : '--'

              let isValid

              if (client.validated === null) {
                isValid = '--'
              }
              if (client.validated === 'true') {
                isValid = <span className="fa fa-check text-success"></span>
              }
              if (client.validated === 'false') {
                isValid = (
                  <span className="fa fa-window-close text-danger"></span>
                )
              }

              return (
                <React.Fragment key={client.id}>
                  <tr>
                    {/*
        * we are going to make use of this.
        <td>{timeToDisplay}</td> 
        */}
                    <td className="text-center">{client.submission}</td>
                    <td className="text-center">
                      <Link to={validationLink}>{client.id}</Link>
                    </td>
                    <td className="text-center">
                      {client.clientFirstName} {client.clientLastName}
                    </td>
                    <td className="text-center">{isValid}</td>
                    <td className="text-center">{testDriveDate}</td>
                    <td className="text-center">{testDriveLength}</td>
                    <td className="text-center">{vinNum}</td>
                    <td className="text-center">{adminEdit}</td>
                  </tr>
                </React.Fragment>
              )
            }
          })}
      </>
    )
  } else {
    return <tr>Opps something went wrong!</tr>
  }
}

export default AllRiskList
