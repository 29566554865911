import React from 'react'
import DesktopBanner from '../images/banner-en.jpg'
import MobileBanner from '../images/mobile_banner-en.jpg'

const Banner = () => {
  return (
    <div className="banner-area bg_cover d-flex align-items-center">
      <img
        className="desktop-banner"
        src={DesktopBanner}
        alt="Lincoln Banner"
      />
      <img className="mobile-banner" src={MobileBanner} alt="Lincoln Banner" />
    </div>
  )
}

export default Banner
