import React from 'react'
import Header from '../containers/header'
import Footer from '../components/footer'
import QuickTips from '../components/QuickTips'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'

const QuickTipsContainer = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/" />

  const quickTips = <QuickTips />

  return (
    <React.Fragment>
      <Header />
      {quickTips}
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default compose(connect(mapStateToProps))(QuickTipsContainer)
