import axios from 'axios'
import { config } from '../../config/fbConfig'

export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(success => {
        dispatch({ type: 'LOGIN_SUCCESS' })
      })
      .catch(err => {
        dispatch({ type: 'LOGIN_ERROR', payload: err })
        return err
      })
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' })
      })
  }
}

export const signOutFr = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' })
      })
  }
}

export const register = (
  newUser,
  registerSuccessCallback,
  registerErrorCallback
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()
    let signUpApp = null
    if (firebase.apps && firebase.apps.length > 0) {
      signUpApp = firebase.apps.find(ele => {
        return ele && ele.name === 'SignUpOnly'
      })
    }
    if (signUpApp === undefined || signUpApp === null) {
      signUpApp = firebase.initializeApp(config, 'SignUpOnly')
    }
    signUpApp
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        signUpApp.auth().signOut()
        return firestore.collection('users').doc(resp.user.uid).set({
          dealerName: newUser.dealerName,
          email: newUser.email,
          dealerCode: newUser.dealerCode,
          region: newUser.region,
          dealerType: newUser.dealerType,
          userRole: newUser.userRole,
        })
      })
      .then(resp => {
        dispatch({ type: 'SIGNUP_SUCCESS' })
        if (registerSuccessCallback) {
          try {
            registerSuccessCallback()
          } catch (error) {
            console.log(error)
          }
        }
        return resp
      })
      .catch(err => {
        dispatch({ type: 'SIGNUP_ERROR', err })
        if (registerErrorCallback) {
          try {
            registerErrorCallback(err)
          } catch (error) {
            console.log(error)
          }
        }
        return err
      })
  }
}

export const resetAuthErrorState = () => {
  return (dispatch, { getFirebase }) => {
    dispatch({ type: 'RESET_AUTH_ERROR' })
  }
}

export const updateLddUser = (
  user,
  updateUserSuccessCallback,
  updateUserErrorCallback
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(token => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
        axios
          .put(
            'https://us-central1-lincoln-discover.cloudfunctions.net/app/lddUser',
            user,
            { headers: headers }
          )
          .then(data => {
            console.log(data)
            if (updateUserSuccessCallback) {
              try {
                updateUserSuccessCallback(data.data)
              } catch (err) {
                console.log(err)
              }
            }
            dispatch({ type: 'UPDATE_USER', payload: data })
          })
          .catch(err => {
            console.log(err)
            if (updateUserErrorCallback) {
              try {
                let msg =
                  err && err.response && err.response.data
                    ? err.response.data
                    : err
                updateUserErrorCallback(msg)
              } catch (err) {
                console.log(err)
              }
            }
            dispatch({ type: 'UPDATE_USER_ERROR' })
            return err
          })
      })
      .catch(err => {
        console.log(err)
        if (updateUserErrorCallback) {
          try {
            let msg =
              err && err.response && err.response.data ? err.response.data : err
            updateUserErrorCallback(msg)
          } catch (err) {
            console.log(err)
          }
        }
        dispatch({ type: 'UPDATE_USER_ERROR' })
        return err
      })
  }
}
