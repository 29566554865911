import React from 'react'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'
import DealerValidationFr from '../components/dealerValidationFr'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

const ClientValidationFr = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/fr" />

  const form = <DealerValidationFr />

  return (
    <React.Fragment>
      <HeaderFr />
      {form}
      <FooterFr />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'clients' }])
)(ClientValidationFr)
