import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const RegionalMenuFr = props => {
  return (
    <React.Fragment>
      {/* <li>
        <NavLink to="/">Home</NavLink>
      </li> */}
      <li>
        <Link to="#!">PROGRAMME</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/whats-new-fr">Actifs du programme</NavLink>
          </li>
          <li>
            <NavLink to="/program-parameters-fr">DÉTAILS DU PROGRAMME</NavLink>
          </li>
          <li>
            <NavLink to="/faq-fr">FAQ</NavLink>
          </li>
          <li>
            <NavLink to="/quick-tips-fr">ASTUCES RAPIDES</NavLink>
          </li>
          {/* <li>
              <NavLink to="/support-fr">Soutien</NavLink>
            </li> */}
        </ul>
      </li>
      {/* <li>
        <Link to="#!">Forms</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/client-new">Register Client</NavLink>
          </li>
          <li>
            <NavLink to="/client-validation">Validation</NavLink>
          </li>
          <li>
            <NavLink to="/admin-update">Client Update</NavLink>
          </li>
        </ul>
      </li> */}
      <li>
        <Link to="#!">Rapports</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/regional-report-fr">Rapports Regional</NavLink>
          </li>
          {props && props.profile && props.profile.userRole === 'National' ? (
            <li>
              <NavLink to="/national-report-fr">National Report</NavLink>
            </li>
          ) : null}
        </ul>
      </li>
    </React.Fragment>
  )
}

export default RegionalMenuFr
