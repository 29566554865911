import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../containers/header'
import Footer from '../components/footer'
import WelcomeEn from '../components/welcome_en'
import Login from '../containers/login'
//import WelcomeCardsEn from '../components/welcomeCards_en'
import IntroWelcome from '../components/introWelcome'
//import { Navigate } from 'react-router-dom'
import ProgramAssets from '../components/programAssets'

class Home extends Component {
  render() {
    const { auth, profile } = this.props

    const intro = auth.uid ? <IntroWelcome /> : <></>
    // const welcomeEn = auth.uid ? <WelcomeEn /> : <></>
    // if (auth.uid) return <Navigate to='/' />
    const WelcomeCardsEn = auth.uid ? <></> : <WelcomeEn />
    const assets = profile.userRole === 'Dealer' ? <ProgramAssets /> : null
    return (
      <React.Fragment>
        <Header />
        {/* {welcomeEn} */}
        {WelcomeCardsEn}
        {intro}
        {assets}
        {!auth.uid && <Login />}
        <Footer />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(Home)
