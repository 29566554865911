import React, { useState, useEffect, useMemo } from 'react'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BallTriangle } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'

import _ from 'lodash'

import Header from '../containers/headerFr'
import Footer from '../components/footerFr'
import {
  updateAdminReportFilter,
  updateAdminReportSort,
} from '../data/actions/reportActions'
import { deleteClient } from '../data/actions/clientActions'
import { Filter } from '../components/tableFilter'

import { firestore as db } from '../config/fbConfig'

import '../styles/table.css'
import './customStyles.css'

const DownloadDiv = styled.div`
  width: 100;
  margin-top: 2em;
`

const TableDiv = styled.div`
  width: 100%;
`

const NumberOfRecords = styled.div`
  font-weight: bold;
  display: inline-flex;
  margin-left: 2em;
`

const date = new Date()
const AdminReportFr = props => {
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const [columnFilters, setColumnFilters] = useState(props.adminReportFilter)

  const [csvMonth, setCSVMonth] = useState((date.getMonth() + 1).toString())
  const [csvYear, setCSVYear] = useState(date.getFullYear().toString())
  const [sortedTableData, setSortedTableData] = useState([])
  const [selectedYear, setSelectedYear] = useState({
    label: 'All',
    value: 'All',
  })
  const [usersAll, setUsersAll] = useState()
  const [usersMap, setUsersMap] = useState(null)
  const [data, setData] = useState([])
  const [clientMap, setClientMap] = useState(new Map())
  const [allContact, setAllContact] = useState([0])
  const [numberOfObjects, setNumberOfObjects] = useState([])
  const [validatedDealers, setValidatedDealers] = useState([])
  const [newClient, setNewClient] = useState([])

  const { clients, profile } = props

  const columns = useMemo(
    () => [
      {
        header: 'Follow-up Date',
        accessorKey: 'followUp',
        size: 100,
      },
      {
        header: 'SUBMISSION DATE',
        accessorKey: 'submission',
        size: 120,
      },
      {
        header: 'Retailer',
        accessorKey: 'dealerCode',
        size: 100,
      },
      {
        header: 'ORDER NUMBER',
        accessorKey: 'validationLink',
        enableSorting: false,
      },
      {
        header: 'CLIENT NAME',
        accessorKey: 'fullName',
      },
      {
        header: 'COMPETITIVE VEHICLE VIN',
        accessorKey: 'currentCompetitiveVehicleVIN'
      },
      {
        header: 'APPROVED',
        accessorKey: 'validated',
        enableSorting: false,
        size: 80,
        cell: props => {
          if (props.getValue() === 'true') {
            return <span className="fa fa-check text-success"></span>
          } else if (props.getValue() === 'false') {
            return <span className="fa fa-window-close text-danger"></span>
          } else {
            return '--'
          }
        },
      },
      {
        header: 'TEST DRIVE LENGTH',
        accessorKey: 'maxLddMonth',
        size: 120,
        cell: props => {
          if (props.getValue()) {
            return props.getValue()
          }
          return '--'
        },
      },
      {
        header: 'Purchased Vehicle',
        accessorKey: 'hasPurchased',
        enableColumnFilter: true,
        enableSorting: false,
        size: 110,
        cell: props => {
          if (props.getValue() === 'true') {
            return <span className="fa fa-check text-success"></span>
          }
          return <span>--</span>
        },
      },
      {
        header: 'ACTIONS',
        accessorKey: 'adminEdit',
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    initialState: {
      sorting: [
        {
          id: props.adminReportSort.id,
          desc: props.adminReportSort.desc,
        },
      ],
    },
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  useEffect(() => {
    db.collection('users').onSnapshot(snapshot => {
      setUsersAll(
        snapshot.docs.map(doc => {
          const data = doc.data()
          return data
        })
      )
    })
  }, [])

  useEffect(() => {
    if (props.clients && sortedTableData.length === 0) {
      setSortedTableData(props.clients)
    }
  }, [props.clients])

  useEffect(() => {
    const _usersMap = new Map()
    if (usersAll?.length > 0) {
      usersAll.forEach(user => {
        if (user.dealerCode) {
          _usersMap[user.dealerCode] = user
        } else {
          _usersMap[user.id] = user
        }
      })
    }
    setUsersMap(_usersMap)
  }, [usersAll])

  useEffect(() => {
    if (usersAll && usersAll.length > 0) {
      const sortedData = table.getSortedRowModel().rows.map(row => row.original)
      setSortedTableData(sortedData)
    }
  }, [
    table.getState().pagination.pageIndex,
    table.getState().pagination.pageSize,
    table.getState().columnFilters,
    table.getState().sorting,
  ])

  useEffect(() => {
    props.updateAdminReportFilter(columnFilters)
  }, [columnFilters])

  useEffect(() => {
    const csvClients = []
    const cx = []
    let xc
    let xy
    if (newClient && newClient.length > 0) {
      newClient.forEach(customer => {
        if (customer.maxLddMonth === '24h') {
          customer.dealerCredit = 150
        }
        if (customer.maxLddMonth === '48h') {
          customer.dealerCredit = 250
        }

        if (customer.followUp) {
          xc = customer.followUp.slice(5, -3)
          xy = customer.followUp.slice(0, 4)
          if (csvMonth === xc && csvYear === xy) {
            customer.csvExportMonth = customer.followUp.slice(5, -3)
            customer.csvExportcsvYear = customer.followUp.slice(0, 4)
            csvClients.push(customer)
            cx.push(customer.csvExportMonth)
            cx.push(customer.csvExportcsvYear)
          }
        }
      })
    }
  }, [newClient])

  const deleteClientSucccessCallback = () => {
    setProcessing(false)
    navigate('/thank-you')
  }

  const deleteClientErrorCallback = err => {
    setProcessing(false)
    if (err && err.message) {
      toast.error(`${err.message}`)
    } else {
      toast.error('An unexpeccted error occurred while removing the client.')
    }
  }

  const handleDelete = client => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this client?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setProcessing(true)
            props.deleteClient(
              client,
              deleteClientSucccessCallback,
              deleteClientErrorCallback
            )
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  const clientOptionArray = []
  clientOptionArray.push({ label: 'All', value: 'All' })
  if (
    usersAll &&
    clients &&
    clients.length > 0 &&
    profile &&
    profile.isLoaded
  ) {
    let numberOfValidatedDealers
    let contact
    let clientsNull = []
    let clientsValid = []
    const generateClientJsx = cls => {
      const _clientMap = new Map()
      const _allContact = [0]
      const _numberOfObjects = []
      const _validatedDealers = []
      const _newClient = []
      let clientsArray = cls.map(client => {
        client = _.cloneDeep(client)
        if (client.clientFirstName && client.clientLastName) {
          client.fullName = client.clientFirstName + ' ' + client.clientLastName
        } else if (client.clientFirstName) {
          client.fullName = client.clientFirstName
        } else {
          client.fullName = client.clientLastName
        }
        client.validationLink = (
          <span style={{ wordBreak: 'break-all' }}>{client.id}</span>
        )
        client.details = (
          <Link
            style={{
              fontSize: '14px',
              fontWeight: 500,
              wordBreak: 'break-word',
            }}
            to={'/client/' + client.id}
            key={client.id}
          >
            View Details
          </Link>
        )
        client.update = (
          <div>
            <Link
              style={{
                fontSize: '14px',
                fontWeight: 500,
                wordBreak: 'break-word',
              }}
              to={'/client-update/' + client.id}
              key={'update-' + client.id}
            >
              Update
            </Link>
            <Link
              style={{
                fontSize: '14px',
                fontWeight: 500,
                wordBreak: 'break-word',
                color: 'red',
                marginLeft: '8px',
              }}
              onClick={() => handleDelete(client)}
              key={'delete-' + client.id}
            >
              Delete
            </Link>
          </div>
        )
        client.adminEdit =
          profile.userRole === 'Admin' ? client.update : client.details
        client.vinNum = client.testDriveVIN ? client.testDriveVIN : '--'
        client.testDriveDate = client.dateStart ? client.dateStart : '--'
        if (client.timeStart && client.client && !client.client.timeStart) {
          client.client.timeStart = client.timeStart
        }
        if (client.timeEnd && client.client && !client.client.timeEnd) {
          client.client.timeEnd = client.timeEnd
        }
        client.timeSlot =
          client.client && client.client.timeStart && client.client.timeEnd
            ? client.client.timeStart + ' - ' + client.client.timeEnd
            : ''
        client.commentsParsed = client.comments
          ? `${client.comments
              .replaceAll(',', ';')
              .replaceAll('"', '')
              .replaceAll('\\', '-')
              .replaceAll('/', '-')}`
          : ''
        client.hasPurchased = client.hasPurchased + ''
        client.supriseGiftParsed = client.supriseGift
          ? `${client.supriseGift
              .replaceAll(',', ';')
              .replaceAll('"', '')
              .replaceAll('\\', '-')
              .replaceAll('/', '-')}`
          : ''

        if (
          usersMap &&
          client.dealerCode &&
          usersMap[client.dealerCode] &&
          usersMap[client.dealerCode].dealerCode === client.dealerCode
        ) {
          client.dealerType = usersMap[client.dealerCode].dealerType
        }

        if (client.validated === 'true') {
          // Assign each client a maxLddMonth
          _newClient.push(client)
          contact = client.maxLddMonth === '24h' ? 150 : 250
          _allContact.push(contact)
          _validatedDealers.push(client)
        }
        if (client.validated === null) {
          clientsNull.push(client)
        } else {
          clientsValid.push(client)
        }
        if (client.submission) {
          try {
            let submissionYear = new Date(client.submission).getFullYear()
            if (_clientMap.get(submissionYear)) {
              _clientMap.get(submissionYear).push(client)
            } else {
              let clientsArray = []
              clientsArray.push(client)
              _clientMap.set(submissionYear, clientsArray)
            }
          } catch (error) {
            //ignore
            console.error(error)
          }
        }
        _numberOfObjects.push(client)
        return client
      })
      setClientMap(_clientMap)
      setAllContact(_allContact)
      setNumberOfObjects(_numberOfObjects)
      setValidatedDealers(_validatedDealers)
      setNewClient(_newClient)
      return clientsArray
    }

    if (data.length === 0) {
      setData(() => generateClientJsx(clients))
    }

    let yearsList = Array.from(clientMap.keys())
    yearsList = yearsList.sort()
    yearsList.forEach(ele => {
      let opt = clientOptionArray.find(ele2 => ele2.value === ele.toString())
      if (!opt) {
        clientOptionArray.push({
          value: ele.toString(),
          label: ele.toString(),
        })
      }
    })

    !validatedDealers || validatedDealers.length < 1
      ? (numberOfValidatedDealers = 0)
      : (numberOfValidatedDealers = validatedDealers.length)

    // Total Dealer Credits calculations
    let totalCreds = allContact.reduce((partial_sum, a) => partial_sum + a)
    let totalTestDrives = numberOfObjects.length

    if (
      selectedYear.value !== 'All' &&
      clientMap.size > 0 &&
      clientMap.get(Number(selectedYear.value))
    ) {
      let dealerCredits = 0
      let clientsInYear = clientMap.get(Number(selectedYear.value))
      totalTestDrives = clientsInYear.length
      let validClientsInYear = []
      clientsInYear.forEach(ele => {
        if (ele.validated === 'true') {
          if (ele.maxLddMonth && ele.maxLddMonth === '24h') {
            dealerCredits += 150
          } else if (ele.maxLddMonth && ele.maxLddMonth === '48h') {
            dealerCredits += 250
          }
          validClientsInYear.push(ele)
        }
      })
      totalCreds = dealerCredits
      numberOfValidatedDealers = validClientsInYear.length
    }

    const { auth } = props
    if (!auth.uid) return <Navigate to="/" />

    const csvHeaders = [
      { label: 'Customer Number', key: 'id' },
      { label: 'Retailer Code', key: 'dealerCode' },
      { label: 'Retailer Name', key: 'dealerName' },
      { label: 'Region', key: 'region' },
      { label: 'First Name', key: 'clientFirstName' },
      { label: 'Last Name', key: 'clientLastName' },
      { label: 'Full Name', key: 'fullName' },
      { label: 'Postal Code', key: 'clientPostalCode' },
      { label: 'Follow-up Date', key: 'followUp' },
      { label: 'Retailer Credit', key: 'dealerCredit' },
      { label: 'Retailer Type', key: 'dealerType' },
      { label: 'Time slot', key: 'timeSlot' },
      { label: 'Client Address', key: 'clientAddress' },
      { label: 'Client Cell Phone', key: 'clientCellPhone' },
      { label: 'Client City', key: 'clientCity' },
      { label: 'Client Email', key: 'clientEmail' },
      { label: 'Client Province', key: 'clientProvince' },
      { label: 'Comments', key: 'commentsParsed' },
      { label: 'Communication Method', key: 'communicationMethod' },
      { label: 'Date End', key: 'dateEnd' },
      { label: 'Date Start', key: 'dateStart' },
      { label: 'Drop Off Alt Address', key: 'dropoffAltAddress' },
      { label: 'Drop Off Location', key: 'dropoffLocation' },
      { label: 'Follow Up', key: 'followUp' },
      { label: 'Purchased Vehicle', key: 'hasPurchased' },
      { label: 'Order ID', key: 'orderID' },
      { label: 'Pending Validation', key: 'pendingValidation' },
      { label: 'Purchase Timeframe', key: 'purchaseTimeframe' },
      { label: 'Red List', key: 'redList' },
      { label: 'Submission', key: 'submission' },
      { label: 'Suprise Gift', key: 'supriseGiftParsed' },
      { label: 'Test Drive Date', key: 'testDriveDate' },
      { label: 'Test Drive Date1', key: 'testDriveDate1' },
      { label: 'Test Drive Date2', key: 'testDriveDate2' },
      { label: 'Test Drive Length', key: 'testDriveLength' },
      { label: 'Test Drive VIN', key: 'testDriveVIN' },
      { label: 'Competitive Vehicle Brand', key: 'currentVehicle' },
      { label: 'Competitive Vehicle VIN', key: 'currentCompetitiveVehicleVIN' },
      { label: 'Unique ID', key: 'uid' },
      { label: 'Validated', key: 'validated' },
      { label: 'Vehicle Of Interest', key: 'vehicleInterest' },
      { label: 'Purchased a Lincoln after LDD', key: 'madePurchaseAfterLDD' },
      { label: 'LDD through a Referral Certificate', key: 'isReferralCert' },
      { label: 'Referral Certificate #', key: 'referralCertNum' },
      { label: 'Updated On', key: 'updatedOn' },
    ]

    const updateTotals = option => {
      let currentData =
        option.value === 'All'
          ? clients
          : clientMap.get(Number(option.value)) || clients
      setSelectedYear(option)
      setSortedTableData(currentData)
      setData(option.value === 'All' ? generateClientJsx(clients) : currentData)
    }

    const filterOptions = column => {
      switch (column) {
        case 'validated':
          return [
            { value: '', label: 'Show All' },
            { value: 'null', label: '--' },
            { value: 'true', label: 'Approved' },
            { value: 'false', label: 'Not Approved' },
          ]
        case 'maxLddMonth':
          return [
            { value: '', label: 'Show All' },
            { value: 'null', label: '--' },
            { value: '24h', label: '24h' },
            { value: '48h', label: '48h' },
          ]
        case 'hasPurchased':
          return [
            { value: '', label: 'Show All' },
            { value: 'true', label: 'Purchased' },
            { value: 'false', label: 'Not Purchased' },
          ]
        default:
          return []
      }
    }

    const filterType = column => {
      if (
        column === 'validated' ||
        column === 'maxLddMonth' ||
        column === 'hasPurchased'
      ) {
        return 'select'
      }
      return 'input'
    }

    return (
      <React.Fragment>
        <Header />
        <div className="container pt-60 pb-60" style={{ position: 'relative' }}>
          {processing && (
            <div
              style={{
                backdropFilter: 'blur(10px)',
                width: '100vw',
                height: '100vh',
                left: 0,
                position: 'fixed',
                zIndex: 10,
                top: 0,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <BallTriangle
                color="#c1bb00"
                height={70}
                width={70}
                wrapperStyle={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
              <h3 style={{ paddingTop: '1em', textAlign: 'center' }}>
                Processing
              </h3>
            </div>
          )}
          <div className="row">
            <div className="col-sm-12 registration-section-title pb-20">
              <h4 className="section-title">Admin Report</h4>
            </div>
            <div className="col-sm-12 pb-20">
              <h4>Welcome to the Lincoln Discovery Drive Dashboard!</h4>
              <p>
                Below you will see a summary of Lincoln Discovery Drives to
                date, including Parts Statements credits.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 registration-section-title pb-20">
              <Select
                id="submissionYear"
                options={clientOptionArray}
                onChange={value => updateTotals(value)}
                value={selectedYear}
              />
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-sm-12">
              <div className="row gutters">
                <div className="col-md-4 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Total Registered{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}
                      <br />
                      Test drives
                    </p>
                    <p className="count">{totalTestDrives}</p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Total{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}
                      <br />
                      Approved{' '}
                    </p>
                    <p className="count">{numberOfValidatedDealers}</p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className=" number-feature">
                    <p className="title">
                      Total{' '}
                      {selectedYear.value !== 'All' ? selectedYear.value : ''}{' '}
                      <br />
                      Retailer Credits
                    </p>
                    <p className="count">${totalCreds}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CSV Download */}

          <DownloadDiv>
            {profile.userRole === 'Admin' ? (
              <CSVLink
                headers={csvHeaders}
                filename={'dsi-export-' + csvMonth + '-' + csvYear + '.csv'}
                className="btn btn-primary"
                data={sortedTableData}
                target="_blank"
              >
                Export to CSV
              </CSVLink>
            ) : null}

            {sortedTableData.length > 0 && (
              <NumberOfRecords>{`${sortedTableData.length} records`}</NumberOfRecords>
            )}
          </DownloadDiv>
          {usersMap && clients ? (
            <>
              <TableDiv>
                <table
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <th
                            className={
                              header.column.getCanSort()
                                ? header.column.getIsSorted().toString()
                                : undefined
                            }
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              width: header.getSize(),
                              textTransform: 'uppercase',
                              color: 'white',
                              backgroundColor: '#c1bb00',
                              borderBottom: '1px solid #c1bb00',
                              borderTop: '1px solid #c1bb00',
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <>
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                    style: {
                                      height: '50%',
                                      width: '100%',
                                      wordBreak: 'break-all',
                                    },
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  <div
                                    {...{
                                      onMouseDown: header.getResizeHandler(),
                                      onTouchStart: header.getResizeHandler(),
                                      className: `resizer ${
                                        header.column.getIsResizing()
                                          ? 'isResizing'
                                          : ''
                                      }`,
                                    }}
                                  ></div>
                                </div>
                                {header.column.getCanFilter() && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                      height: '50%',
                                      width: '100%',
                                    }}
                                  >
                                    <Filter
                                      column={header.column}
                                      filterType={filterType(header.column.id)}
                                      options={filterOptions(header.column.id)}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map(row => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map(cell => {
                            if (
                              cell.column.id === 'adminEdit' ||
                              cell.column.id === 'validationLink'
                            ) {
                              return <td key={cell.id}>{cell.getValue()}</td>
                            }
                            return (
                              <td
                                key={cell.id}
                                style={{ wordBreak: 'break-all' }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </TableDiv>

              <div className="pagination">
                <div className="previous">
                  <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    Previous
                  </button>
                </div>
                <div className="center">
                  <span className="pageInfo">
                    {'Page '}
                    <div className="pageJump">
                      <input
                        type="number"
                        onChange={e => {
                          let page = e.target.value
                            ? Number(e.target.value) - 1
                            : 0
                          page =
                            page > table.getPageCount() - 1
                              ? table.getPageCount() - 1
                              : page
                          table.setPageIndex(page)
                        }}
                        value={table.getState().pagination.pageIndex + 1}
                        className="border p-1 rounded w-16"
                      />
                    </div>
                    {' of '}
                    <span className="totalPages">{table.getPageCount()}</span>
                  </span>
                  <span className="select-wrap pageSizeOptions">
                    <select
                      value={table.getState().pagination.pageSize}
                      onChange={e => {
                        table.setPageSize(Number(e.target.value))
                      }}
                    >
                      {[5, 10, 20, 25, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize} rows
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
                <div className="next">
                  <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <ToastContainer
          position="top-center"
          hideProgressBar="true"
          autoClose="5000"
          style={{ width: '600px' }}
        />
        <Footer />
      </React.Fragment>
    )
  } else {
    return (
      <div
        style={{
          minWidth: '100% !important',
          minHeight: '100% !important',
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          top: '120px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '5em' }}>
          <BallTriangle
            color="#c1bb00"
            height={80}
            width={80}
            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
          />
          <h1 style={{ paddingTop: '1em' }}>Loading</h1>
        </div>
      </div>
    )
  }
}

AdminReportFr.propTypes = {
  clients: PropTypes.array,
  auth: PropTypes.object,
  profile: PropTypes.object,
  updateAdminReportFilter: PropTypes.func,
  adminReportFilter: PropTypes.array,
  updateAdminReportSort: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    adminReportFilter: state.reports.adminReportFilter,
    adminReportSort: state.reports.adminReportSort,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAdminReportFilter: filterParams =>
      dispatch(updateAdminReportFilter(filterParams)),
    updateAdminReportSort: sortParams =>
      dispatch(updateAdminReportSort(sortParams)),
    deleteClient: (
      client,
      deleteClientSucccessCallback,
      deleteClientErrorCallback
    ) =>
      dispatch(
        deleteClient(
          client,
          deleteClientSucccessCallback,
          deleteClientErrorCallback
        )
      ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => [{ collection: 'clients' }])
)(AdminReportFr)
