import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const AdminMenuFr = props => {
  const uisList = ['el4VWYwksAS9MryJ3ogkCqIAQmg1']
  return (
    <React.Fragment>
      {/* <li>
        <NavLink to="/">Home</NavLink>
      </li> */}
      <li>
        <Link to="#!">PROGRAMME</Link>
        <ul className="sub-menu">
          <li>
            <NavLink to="/whats-new-fr">ACTIFS DU PROGRAMME</NavLink>
          </li>
          <li>
            <NavLink to="/program-parameters-fr">DÉTAILS DU PROGRAMME</NavLink>
          </li>
          <li>
            <NavLink to="/faq-fr">FAQ</NavLink>
          </li>
          <li>
            <NavLink to="/quick-tips-fr">ASTUCES RAPIDES</NavLink>
          </li>
          {/* <li>
              <NavLink to="/support-fr">Soutien</NavLink>
            </li> */}
        </ul>
      </li>
      {props && props.auth && uisList.indexOf(props.auth.uid) === -1 ? (
        <li>
          <Link to="#!">Formulaires</Link>
          <ul className="sub-menu">
            <li>
              <NavLink to="/client-new-fr">Formulaire d’inscription</NavLink>
            </li>
            {/*<li>
            <NavLink to="/client-validation-fr">Formulaire de validation</NavLink>
          </li>*/}
            <li>
              <NavLink to="/client-update-fr">
                Mettre à jour un formulaire d’inscription
              </NavLink>
            </li>
          </ul>
        </li>
      ) : null}
      {props &&
      props.auth &&
      uisList.indexOf(props.auth.uid) === -1 &&
      props.profile &&
      props.profile.userRole &&
      props.profile.userRole === 'Admin' ? (
        <li>
          <Link to="#!">User Management</Link>
          <ul className="sub-menu">
            <li>
              <NavLink to="/create-account">Create User</NavLink>
            </li>
            <li>
              <NavLink to="/user-accounts">Update Users</NavLink>
            </li>
          </ul>
        </li>
      ) : null}
      <li>
        <Link to="#!">Rapports</Link>
        <ul className="sub-menu">
          {props && props.auth && uisList.indexOf(props.auth.uid) === -1 ? (
            <li>
              <NavLink to="/admin-report-fr">Admin Report</NavLink>
            </li>
          ) : null}
          <li>
            <NavLink to="/regional-report-fr">Regional Report</NavLink>
          </li>
          <li>
            <NavLink to="/national-report-fr">National Report</NavLink>
          </li>
        </ul>
      </li>
    </React.Fragment>
  )
}

export default AdminMenuFr
