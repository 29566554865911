const initState = {
  authError: null,
  authErrorResponse: {},
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      console.log('Login Failed')
      return {
        ...state,
        authError: 'Login Failed',
        authErrorResponse: action.payload,
      }
    case 'LOGIN_SUCCESS':
      console.log('Login Success')
      return {
        ...state,
        authError: null,
        authErrorResponse: {},
      }
    case 'SIGNOUT_SUCCESS':
      console.log('Signed Out Successully')
      return state
    case 'SIGNUP_SUCCESS':
      console.log('User Registered Successfully')
      return {
        ...state,
        authError: null,
        authErrorResponse: {},
      }
    case 'SIGNUP_ERROR':
      console.log('signup error')
      return {
        ...state,
        authError: action.err.message,
      }
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        authError: null,
        authErrorResponse: {},
      }
    case 'UPDATE_USER':
      return {
        ...state,
        authError: null,
        authErrorResponse: {},
      }
    case 'UPDATE_USER_ERROR':
      return state
    default:
      return state
  }
}

export default authReducer
