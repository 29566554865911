import React, { Component } from 'react'
import { connect } from 'react-redux'
import NavbarFr from '../components/navbarFr'
import BannerFr from '../components/bannerFr'

class HeaderFr extends Component {
  render() {
    const { profile, auth } = this.props

    return (
      <React.Fragment>
        <NavbarFr profile={profile} auth={auth} />
        <BannerFr />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(HeaderFr)
