import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

export const config = {
  apiKey: 'AIzaSyBwJ6D2FSP0g0nBnC8kpskmhhKqsSwDZcM',
  authDomain: 'lincoln-discover.firebaseapp.com',
  databaseURL: 'https://lincoln-discover.firebaseio.com',
  projectId: 'lincoln-discover',
  storageBucket: '',
  messagingSenderId: '240035088620',
}

firebase.initializeApp(config)
const auth = firebase.auth()
export const firestore = firebase.firestore()

export default firebase
