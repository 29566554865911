import React from 'react'
import SupportEN from '../components/supportEN'
import Header from '../containers/header'
import Footer from '../components/footer'

const Support = () => {
  const Support = <SupportEN />

  return (
    <React.Fragment>
      <Header />
      {Support}
      <Footer />
    </React.Fragment>
  )
}

export default Support
