import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import {
  reduxFirestore,
  getFirestore,
  createFirestoreInstance,
} from 'redux-firestore'
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import './styles/index.css'
import Routers from './config/routes'
import rootReducer from './data/reducers/rootReducer'
import reportWebVitals from './reportWebVitals'
import firebase from './config/fbConfig'

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users', // where profiles are stored in database
  useFirestoreForProfile: true,
  presence: 'presence', // where list of online users is stored in database
  sessions: 'sessions', // where list of user sessions is stored in database (presence must be enabled)
}

const initialState = {}
const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })]

const createStoreWithFirebase = compose(
  reduxFirestore(firebase, {}) // firebase instance as first argument, rfConfig as optional second
)(createStore)

const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware))
)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

const container = document.getElementById('app')

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Routers />
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, container)

reportWebVitals()
