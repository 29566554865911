const initState = {
  adminReportFilter: [],
  adminReportSort: {
    id: 'submission',
    desc: true,
  },
}

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADMIN_REPORT_FILTER':
      return {
        ...state,
        adminReportFilter: action.payload,
      }
    case 'ADMIN_REPORT_SORT':
      return {
        ...state,
        adminReportSort: action.payload,
      }
    default:
      return state
  }
}
export default reportReducer
