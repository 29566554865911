import axios from 'axios'

export const createClient = (
  client,
  createClientSuccessCallback,
  createClientErrorCallback
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    firestore
      .collection('clients')
      .add({
        ...client,
      })
      .then(docRef => {
        // Email Notification System
        console.log(docRef.id.toString())
        axios
          .post('https://lincoln-dashboard.herokuapp.com/register-email', {
            firstName: client.clientFirstName,
            lastName: client.clientLastName,
            dealerName: client.dealerName,
            uid: docRef.id,
          })
          .then(response => {
            console.log(response)
            if (createClientSuccessCallback) {
              try {
                createClientSuccessCallback()
              } catch (err) {
                console.log(err)
              }
            }
            dispatch({ type: 'CREATE_CLIENT', client })
          })
          .catch(error => {
            console.log(error)
            if (createClientErrorCallback) {
              try {
                createClientErrorCallback(error)
              } catch (err) {
                console.log(err)
              }
            }
          })
      })
      .catch(err => {
        dispatch({ type: 'CREATE_CLIENT_ERROR' })
        if (createClientErrorCallback) {
          try {
            createClientErrorCallback(err)
          } catch (err) {
            console.log(err)
          }
        }
      })
  }
}

export const validateClient = (
  client,
  validateClientSuccessCallback,
  validateClientErrorCallback
) => {
  const { id } = client
  //sanitize JSX
  if (client.adminEdit) {
    client.adminEdit = null
  }
  if (client.details) {
    client.details = null
  }
  if (client.hasPurchasedParsed) {
    client.hasPurchasedParsed = null
  }
  if (client.isValid) {
    client.isValid = null
  }
  if (client.update) {
    client.update = null
  }
  if (client.validationLink) {
    client.validationLink = null
  }
  console.log(id)
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    firestore
      .collection('clients')
      .doc(id)
      .set({
        ...client,
      })
      .then(() => {
        // Email Notification System
        axios
          .post('https://lincoln-dashboard.herokuapp.com/validation-email', {
            firstName: client.clientFirstName,
            lastName: client.clientLastName,
            dealerName: client.dealerName,
            uid: id,
          })
          .then(response => {
            console.log(response)
            if (validateClientSuccessCallback) {
              try {
                validateClientSuccessCallback()
              } catch (err) {
                console.log(err)
              }
            }
          })
          .catch(error => {
            console.log(error)
            if (validateClientErrorCallback) {
              try {
                validateClientErrorCallback(error)
              } catch (err) {
                console.log(err)
              }
            }
          })

        dispatch({ type: 'VALIDATE_CLIENT_SUCCESS' })
      })
      .catch(err => {
        dispatch({ type: 'VALIDATE_CLIENT_ERROR', err })
        if (validateClientErrorCallback) {
          try {
            validateClientErrorCallback(err)
          } catch (err) {
            console.log(err)
          }
        }
      })
  }
}

export const updateClient = (
  client,
  updateClientSucccessCallback,
  updateClientErrorCallback
) => {
  const { id } = client
  //sanitize JSX
  if (client.adminEdit) {
    client.adminEdit = null
  }
  if (client.details) {
    client.details = null
  }
  if (client.hasPurchasedParsed) {
    client.hasPurchasedParsed = null
  }
  if (client.isValid) {
    client.isValid = null
  }
  if (client.update) {
    client.update = null
  }
  if (client.validationLink) {
    client.validationLink = null
  }

  console.log(id)
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    firestore
      .collection('clients')
      .doc(id)
      .set({
        ...client,
      })
      .then(() => {
        // Email Notification System
        axios
          .post('https://lincoln-dashboard.herokuapp.com/update-email', {
            firstName: client.clientFirstName,
            lastName: client.clientLastName,
            dealerName: client.dealerName,
            uid: id,
          })
          .then(response => {
            console.log(response)
            if (updateClientSucccessCallback) {
              try {
                updateClientSucccessCallback()
              } catch (err) {
                console.log(err)
              }
            }
          })
          .catch(error => {
            console.log(error)
            if (updateClientErrorCallback) {
              try {
                updateClientErrorCallback(error)
              } catch (err) {
                console.log(err)
              }
            }
          })

        dispatch({ type: 'UPDATED_CLIENT_SUCCESS' })
      })
      .catch(err => {
        dispatch({ type: 'UPDATED_CLIENT_ERROR', err })
        if (updateClientErrorCallback) {
          try {
            updateClientErrorCallback(err)
          } catch (err) {
            console.log(err)
          }
        }
      })
  }
}

export const deleteClient = (
  client,
  deleteClientSucccessCallback,
  deleteClientErrorCallback
) => {
  const { id } = client
  //sanitize JSX
  if (client.adminEdit) {
    client.adminEdit = null
  }
  if (client.details) {
    client.details = null
  }
  if (client.hasPurchasedParsed) {
    client.hasPurchasedParsed = null
  }
  if (client.isValid) {
    client.isValid = null
  }
  if (client.update) {
    client.update = null
  }
  if (client.validationLink) {
    client.validationLink = null
  }

  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    firestore
      .collection('clients')
      .doc(id)
      .delete()
      .then(() => {
        if (deleteClientSucccessCallback) {
          try {
            deleteClientSucccessCallback()
          } catch (err) {
            console.error(err)
          }
        }

        dispatch({ type: 'DELETE_CLIENT_SUCCESS' })
      })
      .catch(err => {
        dispatch({ type: 'DELETE_CLIENT_ERROR', err })
        if (deleteClientErrorCallback) {
          try {
            deleteClientErrorCallback(err)
          } catch (err) {
            console.error(err)
          }
        }
      })
  }
}
