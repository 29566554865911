import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../data/actions/authActions'
import DealerMenuFr from '../components/dealerMenuFr'
import RegionalMenuFr from '../components/regionalMenuFr'
import AdminMenuFr from '../components/adminMenuFr'

const LoggedInMenuFr = props => {
  const { profile, signOut, auth } = props

  let links

  if (profile.userRole === 'Dealer') {
    links = <DealerMenuFr auth={auth} profile={profile} />
  }

  if (profile.userRole === 'Regional' || profile.userRole === 'National') {
    links = <RegionalMenuFr auth={auth} profile={profile} />
  }

  if (profile.userRole === 'Admin') {
    links = <AdminMenuFr auth={auth} profile={profile} />
  }

  return (
    <ul>
      {links}
      <li>
        <Link
          to="#!"
          onClick={e => {
            signOut()
            window.location.href = '/fr'
          }}
        >
          Se Déconnecter
        </Link>
      </li>
    </ul>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInMenuFr)
