import React from 'react'
import ZipImg from '../images/zip.png'
import DocImg from '../images/docx.png'

const WhatsNewFr = () => {
  const titleStyles = {
    height: '105px',
  }

  return (
    <React.Fragment>
      <section class="card-area pt-60 pb-60">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="card-section-title text-center">
                <h4 class="section-title mb-45">Actifs du Programme</h4>
                <p>
                  Vous avez accès ci-dessous à tout le matériel publicitaire du
                  programme Expérience découverte Lincoln ainsi qu’à la lettre
                  de bienvenue au client et aux cartes de référence rapide des
                  véhicules nécessaires pour chaque expérience de conduite.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="single-card mt-30">
                <div class="card-titles" style={titleStyles}>
                  <h3>
                    MATÉRIEL PUBLICITAIRE DE L’EXPÉRIENCE DÉCOUVERTE LINCOLN
                  </h3>
                </div>
                <div class="card-text">
                  <p>
                    Le matériel publicitaire destiné aux Détaillant comprend :
                    les logos, les bannières à en-tête et les bannières Web du
                    programme Expérience découverte Lincoln.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-card mt-30">
                <div class="card-titles" style={titleStyles}>
                  <h3>
                    LETTRE DE BIENVENUE AU CLIENT <br /> DE L’EXPÉRIENCE
                    DÉCOUVERTE LINCOLN
                  </h3>
                </div>
                <div class="card-text">
                  <p>
                    La lettre de bienvenue de l’Expérience découverte Lincoln
                    est personnalisable pour chaque client et devra être signée
                    par le Détaillant. Elle devra être déposée sur le siège de
                    la conducteur (à côté du carte de référence rapide) au
                    moment de chaque Expérience découverte.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-card mt-30">
                <div class="card-titles" style={titleStyles}>
                  <h3>
                    CARTES DE RÉFÉRENCE RAPIDE DES VÉHICULES DE L’EXPÉRIENCE
                    DÉCOUVERTE LINCOLN
                  </h3>
                </div>
                <div class="card-text">
                  <p>
                    Un carte de référence rapide existe pour chaque modèle de
                    véhicule d’essai routier dans le cadre de l’Expérience
                    découverte Lincoln et devra être déposé sur le siège du
                    conducteur (à côté de la lettre de bienvenue du client) au
                    moment de chaque Expérience découverte.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">ENGLISH</h6>
                <a href="downloads/Retailer Assets - English.zip">
                  <img src={ZipImg} alt="" />
                  CLIQUEZ ICI
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">ENGLISH</h6>
                <a href="downloads/LDD Client Welcome Letter.docx">
                  <img src={DocImg} alt="" />
                  CLIQUEZ ICI
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2023 ENGLISH</h6>
                <a href="downloads/2023 Vehicle Quick Reference Cards.zip">
                  <img src={ZipImg} alt="" />
                  <span>CLIQUEZ ICI</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2024 ENGLISH</h6>
                <a href="downloads/LDD Client Spec Card - EN.pdf">
                  <img src={ZipImg} alt="" />
                  <span>CLIQUEZ ICI</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">FRANÇAIS</h6>
                <a href="downloads/Retailer Assets - French.zip">
                  <img src={ZipImg} alt="" />
                  CLIQUEZ ICI
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="single-download mt-50">
                <h6 class="mb-25">FRANÇAIS</h6>
                <a href="downloads/Lettre de Bienvenue du Client.docx">
                  <img src={DocImg} alt="" />
                  CLIQUEZ ICI
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2023 FRANÇAIS</h6>
                <a href="downloads/2023 Cartes de Référence Rapide des Véhicules.zip">
                  <img src={ZipImg} alt="" />
                  <span>CLIQUEZ ICI</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="single-download mt-50">
                <h6 class="mb-25">2024 FRANÇAIS</h6>
                <a href="downloads/LDD Client Spec Card - FR.pdf">
                  <img src={ZipImg} alt="" />
                  <span>CLIQUEZ ICI</span>
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default WhatsNewFr
