import React from 'react'

const ProgramParametersFr = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row mt-30">
          <div className="col-lg-12">
            <div className="faq-section-title text-center col-lg-12 mb-20">
              <h3 className="section-title">
                Détails du programme d’essai routier à la découverte de Lincoln 2024
              </h3>
            </div>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Période du programme</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <p className="static-dark-grey">
              Du 1<sup>er</sup> janvier au 31 décembre 2024
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Admissibilité des détaillants
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Tous les détaillants Lincoln peuvent participer au programme d’essai routier à la découverte de Lincoln.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Pour participer au programme 2024 et être admissibles au soutien de Lincoln Canada, les détaillants DOIVENT remplir le formulaire d’inscription/Surprise et Enchantement, et le soumettre au Secrétariat du programme.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Nombre d’essais routiers mensuels alloués par détaillant : visité – 20 par mois; non visité – 10 par mois.{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Admissibilité des véhicules</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Corsair, Nautilus, Aviator, Navigator 2023 neufs{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Corsair, Nautilus, Aviator, Navigator 2024 neufs{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              NOUVEAU POUR 2024
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Matériel publicitaire actualisé – de nouveaux éléments publicitaires et une nouvelle lettre de bienvenue au client sont disponibles sur le portail Expérience découverte Lincoln et dans la boîte d’outils publicitaires des détaillants.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Le remboursement de Lincoln Canada s’élève à 200 $ pour les essais de 24 heures et de 48 heures.<br/>
                  {`\u2003`} o Les détaillants sont toujours tenus d’investir au moins 50 $ pour chaque essai; la contribution Surprise et Enchantement doit s’élever à au moins 100 $.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Clients admissibles : uniquement les propriétaires d’un modèle concurrent.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Autres points saillants du programme
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Les éléments du programme illustreront la vision de Lincoln d’une expérience chaleureuse, humaine et personnalisée.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Le programme sera en vedette à trois endroits : sur le site Web fr.lincolncanada.com, sur le site Web des détaillants Lincoln et dans les salles de montre des détaillants Lincoln.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les détaillants participants pourront recevoir des demandes issues du site fr.LincolnCanada.com (le cas échéant).
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Un ambassadeur bilingue du programme répondra aux demandes issues du site Web national (et des autres points de contact de détail) et collaborera avec les détaillants participants pour organiser des expériences d’essais routiers.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les détaillants participants peuvent accéder à la lettre de bienvenue au client et au matériel publicitaire du programme pour leur site Web et leurs réseaux sociaux.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span>Nous demandons aux détaillants d’offrir à chacun des participants au programme une expérience de conduite unique et personnalisée, ainsi qu’un élément Surprise et Enchantement qui reflète la marque Lincoln de façon appropriée.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les détaillants sont invités à adapter l’élément Surprise et Enchantement à la réalité de leur collectivité.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> L’outil d’inscription et de suivi en ligne assurera une gestion efficace du programme et de la clientèle.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Chaque essai routier Expérience découverte comptera comme un jour de prêt de véhicule afin de respecter l’exigence d’utilisation de 50 %.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Le recours au service de collecte et de livraison Lincoln est fortement recommandé.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Le véhicule personnel du client sera lavé.{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Modèle de financement de l’Expérience découverte
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Essai routier de 24 heures : remboursement de 200 $ de Lincoln Canada*{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Essai routier de 48 heures : remboursement de 200 $ de Lincoln Canada*{' '}
                </p>
              </li>
            </ul>
            <p className="static-dark-grey">
              * Nous demandons aux détaillants d’investir aussi au moins 50 $ pour chacun des essais routiers. Le remboursement de Lincoln Canada apparaîtra sur votre relevé de pièces.
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Secrétariat et ambassadeur du programme Expérience découverte
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <p className="static-dark-grey">
              Heures d’ouverture du Secrétariat du programme : 9 h à 17 h (HE){' '}
              <br />
              Courriel :{' '}
              <a href="mailto:lddambassador@lincolndiscoverydrive.com">
                lddambassador@lincolndiscoverydrive.com
              </a>{' '}
              <br />
              Téléphone: 833-717-0017 <br />
              <b>
                Veuillez noter que l’ambassadeur de l’Expérience découverte Lincoln est en télétravail et que le courriel constitue le meilleur moyen de communication.
              </b>
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Soutien du Secrétariat du programme
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Le Secrétariat reçoit la demande de fr.lincolncanada.com et confie le client potentiel au détaillant Lincoln le plus près.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Il travaille avec le concierge Lincoln afin d’assurer une transition harmonieuse de la demande à l’essai routier.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Il reçoit toutes les données relatives aux essais routiers des détaillants, en vérifie la conformité et approuve le paiement.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Le Secrétariat effectue un appel de suivi auprès des participants d’un essai routier pour connaître leur impression et leur intérêt pour le produit et pour la marque Lincoln.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Il traite le paiement des factures des services aux concessionnaires pour tous les essais routiers.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Il soumet les données des essais routiers aux fins du suivi des ventes.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10 mb-50">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Responsabilités du détaillant participant
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Les détaillants reçoivent les demandes d’essai routier de l’ambassadeur du programme et communiquent avec les clients afin de réserver leur essai routier.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> ET : ils présentent l’expérience d’essai routier aux clients potentiels d’un modèle concurrent au moyen de publicités ou de partenariats, ou aux clients qui visitent le détaillant.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Ils remplissent le formulaire d’inscription en ligne (coordonnées du participant, dates, durée de l’essai routier et modèle concurrent actuel) et le transmettent au Secrétariat du programme en vue du traitement.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Ils offrent si possible le service de collecte et de livraison, afin de démontrer l’incroyable service à la clientèle de Lincoln.{' '}
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Ils lavent le véhicule du client potentiel ayant effectué l’essai routier (le cas échéant).
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Il dépose une lettre personnalisée sur le siège du conducteur du véhicule servant à l’essai routier.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Ils ajoutent un élément Surprise et Enchantement qui reflète la marque Lincoln de façon appropriée.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span>{' '}
                  <strong>
                    <u>
                      Assurez le suivi des activités de votre programme d’essai routier à la découverte de Lincoln et de votre taux
                      de conversion au moyen de l’outil en ligne du programme!
                    </u>
                  </strong>{' '}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgramParametersFr
