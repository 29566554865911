import React from 'react'

const Footer = () => {
  return (
    <div className="footer-area pt-30 pb-30 custom-footer">
      <div className="container">
        <div className="row">
          <div className="text-center mb-25">
            <p className="">
              For support or assistance with the Lincoln Discovery Drive
              program, please contact the Lincoln Discovery Drive Ambassador at
              LDD Headquarters:
            </p>
            <h5>
              <a href="mailto:LDDambassador@Lincolndiscoverydrive.com">
                LDDambassador@Lincolndiscoverydrive.com
              </a>{' '}
              1.833.717.0017
            </h5>
          </div>
          <div className="col-lg-12">
            <p style={{ textAlign: 'center' }}>
              Lincoln Canada can decide at any time to change or cancel this
              program. Retailer participation is voluntary. LDD Headquarters
              will partner participants with the closest participating Retailer.
              This system is restricted to authorized users including: Lincoln
              Canada Retailers, their clients and interested retail luxury
              shoppers. Individuals who attempt unauthorized access will be
              prosecuted.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
