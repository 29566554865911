import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import * as yup from 'yup'

import Header from '../containers/header'
import Footer from '../components/footer'

import '../styles/TestDriveForm.css'
import ThankYou from '../images/thank-you.png'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup
  .object()
  .shape(
    {
      firstName: yup.string().required('Required'),
      lastName: yup.string().required('Required'),
      address: yup.string().required('Required'),
      postalCode: yup.string().required('Required'),
      city: yup.string().required('Required'),
      province: yup.string().required('Required'),
      email: yup.string().required('Required').email('Email is invalid'),
      phoneNumber: yup.string().when('phoneNumber', (val, schema) => {
        if (val?.length > 0 && val[0] !== '') {
          return yup.string().matches(phoneRegExp, 'Phone number is invalid')
        } else {
          return yup.string().notRequired()
        }
      }),
      preferedContact: yup.string().required('Required'),
      testDriveModel: yup.string().required('Required'),
      currentMakeModel: yup.string().required('Required'),
      specialRequest: yup.string(),
    },
    [
      ['firstName', 'firstName'],
      ['lastName', 'lastName'],
      ['address', 'address'],
      ['postalCode', 'postalCode'],
      ['city', 'city'],
      ['province', 'province'],
      ['email', 'email'],
      ['phoneNumber', 'phoneNumber'],
      ['preferedContact', 'preferedContact'],
      ['testDriveModel', 'testDriveModel'],
      ['currentMakeModel', 'currentMakeModel'],
      ['specialRequest', 'specialRequest'],
    ]
  )
  .required()

const TestDriveForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })
  const [isSuccess, setIsSuccess] = React.useState(false)
  const onSubmit = data => {
    axios
      .post(
        'https://lincoln-dashboard.herokuapp.com/register-test-drive-request',
        data
      )
      .then(response => {
        setIsSuccess(true)
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <>
      <Header showNavbar={false} />
      {isSuccess ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '80px',
            paddingBottom: '80px',
          }}
        >
          <h3 className="title text-center">
            Thank you for your interest in Lincoln.
          </h3>
          <img
            style={{ marginTop: '30px', marginBottom: '30px' }}
            src={ThankYou}
            alt="Thank you"
          />
          <h3 className="bottom-title text-center" style={{ maxWidth: '40%' }}>
            You will be contacted shortly regarding your request to participate
            in the Lincoln Discovery Drive Program.
          </h3>
        </div>
      ) : (
        <div className="drive-form container">
          <section className="intro-area pt-60 pb-10">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="intro-title">
                    <h3 className="title text-center">BOOK A TEST DRIVE</h3>
                    <h5 className="sub-title text-center mt-30">
                      Thank you for your interest in Lincoln Discovery Drive. As
                      an owner of a competitive brand, we are excited for you to
                      experience everything Lincoln has to offer. Simply
                      complete this form and our Lincoln Discovery Drive
                      Ambassador will arrange for an extended test drive at the closest
                      participating Lincoln Retailer to the address you provide.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <form className="container" onSubmit={handleSubmit(onSubmit)}>
            <label>
              <div className="field-label">
                First Name
                {errors.firstName && (
                  <p className="error-label">{errors.firstName.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="First name"
                {...register('firstName')}
              />
            </label>
            <label>
              <div className="field-label">
                Last Name
                {errors.lastName && (
                  <p className="error-label">{errors.lastName.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Last name"
                {...register('lastName')}
              />
            </label>
            <label>
              <div className="field-label">
                Address
                {errors.address && (
                  <p className="error-label">{errors.address.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Address"
                {...register('address')}
              />
            </label>
            <label>
              <div className="field-label">
                Postal Code
                {errors.postalCode && (
                  <p className="error-label">{errors.postalCode.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Postal Code"
                {...register('postalCode')}
              />
            </label>
            <label>
              <div className="field-label">
                City
                {errors.city && (
                  <p className="error-label">{errors.city.message}</p>
                )}
              </div>
              <input type="text" placeholder="City" {...register('city')} />
            </label>
            <label>
              <div className="field-label">
                Province
                {errors.province && (
                  <p className="error-label">{errors.province.message}</p>
                )}
              </div>
              <select defaultValue={''} {...register('province')}>
                <option value="">Select</option>
                <option value="Alberta">Alberta</option>
                <option value="British Columbia">British Columbia</option>
                <option value="Manitoba">Manitoba</option>
                <option value="New Brunswick">New Brunswick</option>
                <option value="Newfoundland and Labrador">
                  Newfoundland and Labrador
                </option>
                <option value="Northwest Territories">
                  Northwest Territories
                </option>
                <option value="Nova Scotia">Nova Scotia</option>
                <option value="Nunavut">Nunavut</option>
                <option value="Ontario">Ontario</option>
                <option value="Prince Edward Island">
                  Prince Edward Island
                </option>
                <option value="Quebec">Quebec</option>
                <option value="Saskatchewan">Saskatchewan</option>
                <option value="Yukon Territory">Yukon Territory</option>
              </select>
            </label>
            <label>
              <div className="field-label">
                Email Address
                {errors.email && (
                  <p className="error-label">{errors.email.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Email Address"
                {...register('email')}
              />
            </label>
            <label>
              <div className="field-label">
                Phone Number
                <span className="optional-label">(Optional)</span>
                {errors.phoneNumber && (
                  <p className="error-label">{errors.phoneNumber.message}</p>
                )}
              </div>
              <input
                type="tel"
                placeholder="Phone number"
                {...register('phoneNumber')}
              />
            </label>

            <label>
              <div className="field-label">
                Prefered Method of Contact
                {errors.preferedContact && (
                  <p className="error-label">
                    {errors.preferedContact.message}
                  </p>
                )}
              </div>
              <select {...register('preferedContact')}>
                <option value="">Select</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
              </select>
            </label>

            <label>
              <div className="field-label">
                Test Drive Model
                {errors.testDriveModel && (
                  <p className="error-label">{errors.testDriveModel.message}</p>
                )}
              </div>
              <select defaultValue={''} {...register('testDriveModel')}>
                <option value="">Select</option>
                <option value="Corsair">Corsair</option>
                <option value="Aviator">Aviator</option>
                <option value="Nautilus">Nautilus</option>
                <option value="Navigator">Navigator</option>
              </select>
            </label>

            <label>
              <div className="field-label">
                Current Make/Model
                {errors.currentMakeModel && (
                  <p className="error-label">
                    {errors.currentMakeModel.message}
                  </p>
                )}
              </div>
              <input
                type="text"
                placeholder="Current Make/Model"
                {...register('currentMakeModel')}
              />
            </label>

            <label className="field-label special-requests">
              Special Requests
              <textarea {...register('specialRequest')} />
            </label>
            <div className="submit-section">
              <button type="submit">Submit</button>
            </div>
          </form>
          <div className="container footer-section">
            <p className="text-center mt-30">
              Upon submission of your form, you will be contacted by our Lincoln
              Discovery Drive Ambassador within 24 hours to arrange your test
              drive experience.
            </p>
          </div>
        </div>
      )}
      <Footer />
    </>
  )
}

export default TestDriveForm
