import React from 'react'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'
import FaqFR from '../components/faqFR'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'

const FaqFr = props => {
  const { auth } = props
  if (!auth.uid) return <Navigate to="/fr" />

  const Faq = <FaqFR />

  return (
    <React.Fragment>
      <HeaderFr />
      {Faq}
      <FooterFr />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default compose(connect(mapStateToProps))(FaqFr)
