import React from 'react'

const WelcomeEn = () => {
  return (
    <section className="intro-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="intro-title pt-60 pb-60 text-center">
              <h3 className="title">
                Welcome to the Lincoln Discovery Drive registration and tracking
                tool
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeEn
