import React from 'react'
// import ZipImg from '../images/zip.png'
import { Link } from 'react-router-dom'

const ProgramAssets = () => {
  return (
    <React.Fragment>
      <section className="card-area pt-30 pb-30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card-section-title text-center">
                <h4 className="section-title mb-30">
                  LDD Registration and Tracking
                </h4>
                <p>
                  Below you have access to all Lincoln Discovery Drive forms,
                  up-to-date
                  <br />
                  Lincoln Discovery Drive reporting stats and information for
                  your Dealership
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-card mt-30">
                <div className="card-titles">
                  <h3>Register a Discovery Drive</h3>
                </div>
                <div className="card-text">
                  <p>
                    <strong>Go to the online Registration Form</strong>
                  </p>
                  <p style={{ height: '95px' }}>
                    This form must be filled out in full for each Client taking
                    a Lincoln Discovery Drive
                  </p>
                  <Link to="/client-new">Click Here</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-card mt-30">
                <div className="card-titles">
                  <h3>Existing Form Search</h3>
                </div>
                <div className="card-text">
                  <p>
                    <strong>Update a Registration</strong>
                  </p>
                  <p>
                    Use the Registration generated Order Number (random alpha
                    numeric code) to search for a previously completed LDD
                    Registration
                  </p>
                  <Link to="/client-validation">Click Here</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-card mt-30">
                <div className="card-titles">
                  <h3>Dealership LDD Report</h3>
                </div>
                <div className="card-text">
                  <p>
                    <strong>Go to your Dealership's Report Dashboard</strong>
                  </p>
                  <p>
                    Here you will find up-to-date information on your Lincoln
                    Discovery Drives to date, as well as links to each Client's
                    information
                  </p>
                  <Link to="/dealer-report">Click Here</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ProgramAssets
