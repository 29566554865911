import React from 'react'
// import QuickTipsFr from './QuickTipsFr';

const FaqFR = () => {
  return (
    <section className="faq-area pt-60 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="faq-section-title text-center">
              <h3 className="section-title">
                EXPÉRIENCE DÉCOUVERTE LINCOLN 2024 – FOIRE AUX QUESTIONS
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="single-faq mt-50">
              <h4>QUESTION #1 – Pour commencer</h4>
              <h6>
                Comment puis-je m’inscrire à l’Expérience découverte Lincoln?
              </h6>
              <p>Il y a deux étapes simples à suivre pour commencer.</p>
              <ul>
                <li>
                  <span>1.</span> Remplissez le formulaire d’inscription, sans
                  oublier d’inscrire les options Surprise et Enchantement, et
                  envoyez-le par courriel à l’adresse
                  <a href="mailto:LDDambassador@Lincolndiscoverydrive.com">
                    LDDambassador@Lincolndiscoverydrive.com
                  </a>{' '}
                  pour inscrire votre détaillant au programme.
                </li>
                <li>
                  <span>2.</span> Une fois votre détaillant inscrit,
                  l’ambassadeur de l’Expérience découverte Lincoln vous enverra
                  un nom d’utilisateur et un mot de passe pour accéder à l’outil
                  en ligne de l’Expérience découverte Lincoln à partir duquel
                  vous pourrez inscrire vos clients et faire le suivi de vos
                  activités.
                </li>
              </ul>
            </div>
            <div className="single-faq mt-50">
              <h4>
                QUESTION #2 – Exigences concernant l’Expérience découverte
                Lincoln
              </h4>
              <h6>
                Que dois-je faire pour le programme Expérience découverte
                Lincoln?
              </h6>
              <p>Le programme comporte cinq éléments obligatoires.</p>
              <ul>
                <li>
                  <span>1.</span> Lorsque possible, fournissez le service de
                  collecte et de livraison.
                </li>
                <li>
                  <span>2.</span> Lavez le véhicule actuel du client durant
                  l’essai routier (lorsque possible)
                </li>
                <li>
                  <span>3.</span> Remettez au client une lettre de bienvenue
                  personnalisée que vous déposerez sur le siège du véhicule
                  essayé.
                </li>
                <li>
                  <span>4.</span> Offrez au client un élément Surprise et
                  Enchantement (d’une valeur minimale de 100 $).
                </li>
                <li>
                  <span>5.</span> Rendez-vous sur l’outil en ligne et remplissez
                  le formulaire d’inscription de l’Expérience découverte Lincoln
                  au plus tard 48 heures suivant l’essai routier.
                </li>
              </ul>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #3 – Durée de l’Expérience découverte Lincoln</h4>
              <h6>
                Puis-je offrir des essais routiers de différentes durées, selon
                l’intérêt des clients?
              </h6>
              <p>
                Oui, mais le programme d’essai routier à la découverte de
                Lincoln exige un essai routier d’au moins 24 heures. Vous pouvez
                aussi prêter le véhicule pendant plus de 48 heures, mais le
                remboursement est plafonné à 48 heures. La portion Surprise et
                Enchantement de l’expérience doit toujours refléter la marque
                Lincoln et impressionner chaque client potentiel qui participe
                au programme.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #4 – Élément Surprise et Enchantement approprié</h4>
              <h6>
                Dans le passé, j’ai offert une carte-cadeau pour un repas au
                restaurant. Puis-je continuer à le faire?
              </h6>
              <p>
                Nous vous décourageons fortement d’offrir uniquement une
                carte-cadeau. Nous voulons que l’élément Surprise et
                Enchantement reflète notre vision de la chaleur humaine et du
                savoir-faire personnalisé. Prévoyez d’offrir un élément Surprise
                et Enchantement plus personnalisé et plus mémorable. Quelques
                idées de départ : un coffret ou un panier-cadeau contenant des
                articles d’un détaillant local; un partenariat avec un
                restaurant local pour un dîner spécial pour deux « à l’image de
                Lincoln »; un week-end en chambre d’hôtes avec visite d’un
                vignoble ou d’un brasseur local. N’oubliez pas qu’il est
                obligatoire d’offrir un élément Surprise et Enchantement « digne
                de Lincoln » pour obtenir le financement, conformément aux
                conditions de l’Expérience découverte Lincoln.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #5 – Propriétaires actuels de Lincoln</h4>
              <h6>
                Dans les informations sur le programme, il est question de
                nouveaux acheteurs potentiels. Pouvons-nous offrir cette
                expérience aux propriétaires actuels de véhicules Lincoln?
              </h6>
              <p>
                Non. L’Expérience découverte Lincoln a été développée comme
                puissant outil de conquête pour vous aider à hausser la
                perception de la marque et à conclure plus de ventes auprès des
                clients de modèles concurrents. Vous pouvez très certainement
                offrir la même expérience à vos clients fidèles, mais vous ne
                pourrez pas demander de remboursement.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>
                QUESTION #6 – Renseignements concernant le rapport de
                l’Expérience découverte Lincoln
              </h4>
              <h6>
                Est-ce que l’outil d’inscription nous permettra de faire le
                suivi des réclamations approuvées et du volume?
              </h6>
              <p>
                Oui. Tous les détaillants auront accès à une liste du cumul de
                leurs événements et au montant total des réclamations acceptées.
                Vous verrez aussi un crochet dans la colonne « Approbations » du
                rapport de votre détaillant dans l’outil de l’Expérience
                découverte Lincoln. L’absence de crochet signifie que
                l’Expérience découverte Lincoln n’est pas approuvée et peut être
                en attente de renseignements supplémentaires. L’ambassadeur de
                l’Expérience découverte Lincoln enverra par courriel toute
                question ou tout problème à la personne-ressource Lincoln
                désignée de votre détaillant.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>
                QUESTION #7 – Suivi de l’Expérience découverte Lincoln auprès
                des clients
              </h4>
              <h6>
                Pouvons-nous faire un suivi auprès des participants après
                l’essai routier?
              </h6>
              <p>
                Vous pouvez faire un suivi auprès de votre client, mais le
                Secrétariat du programme assurera aussi un suivi auprès des
                participants afin d’évaluer leur opinion sur la marque et
                l’expérience globale. Ces commentaires figureront sur le portail
                du programme d’essai routier à la découverte de Lincoln à des
                fins d’examen et de suivi du programme.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqFR
