import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import * as yup from 'yup'

import Header from '../containers/headerFr'
import Footer from '../components/footerFr'

import '../styles/TestDriveForm.css'
import ThankYou from '../images/thank-you.png'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup
  .object()
  .shape(
    {
      firstName: yup.string().required('obligatoire'),
      lastName: yup.string().required('obligatoire'),
      address: yup.string().required('obligatoire'),
      postalCode: yup.string().required('obligatoire'),
      city: yup.string().required('obligatoire'),
      province: yup.string().required('obligatoire'),
      email: yup.string().required('obligatoire').email('Email is invalid'),
      phoneNumber: yup.string().when('phoneNumber', (val, schema) => {
        if (val?.length > 0 && val[0] !== '') {
          return yup.string().matches(phoneRegExp, 'Phone number is invalid')
        } else {
          return yup.string().notRequired()
        }
      }),
      preferedContact: yup.string().required('obligatoire'),
      testDriveModel: yup.string().required('obligatoire'),
      currentMakeModel: yup.string().required('obligatoire'),

      specialRequest: yup.string(),
    },
    [
      ['firstName', 'firstName'],
      ['lastName', 'lastName'],
      ['address', 'address'],
      ['postalCode', 'postalCode'],
      ['city', 'city'],
      ['province', 'province'],
      ['email', 'email'],
      ['phoneNumber', 'phoneNumber'],
      ['preferedContact', 'preferedContact'],
      ['testDriveModel', 'testDriveModel'],
      ['currentMakeModel', 'currentMakeModel'],
      ['specialRequest', 'specialRequest'],
    ]
  )
  .required()

const TestDriveForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })
  const [isSuccess, setIsSuccess] = React.useState(false)
  const onSubmit = data => {
    axios
      .post(
        'https://lincoln-dashboard.herokuapp.com/register-test-drive-request',
        data
      )
      .then(response => {
        setIsSuccess(true)
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <>
      <Header showNavbar={false} />
      {isSuccess ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '80px',
            paddingBottom: '80px',
          }}
        >
          <h3 className="title text-center">
            Merci pour votre intérêt envers Lincoln.
          </h3>
          <img
            style={{ marginTop: '30px', marginBottom: '30px' }}
            src={ThankYou}
            alt="Thank you"
          />
          <h3 className="bottom-title text-center" style={{ maxWidth: '40%' }}>
            Un ambassadeur communiquera avec vous sous peu concernant votre
            demande de participation au programme d’essai routier à la
            découverte de Lincoln.
          </h3>
        </div>
      ) : (
        <div className="drive-form container">
          <section className="intro-area pt-60 pb-10">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="intro-title">
                    <h3 className="title text-center">
                      RÉSERVER UN ESSAI ROUTIER
                    </h3>
                    <h5 className="sub-title text-center mt-30">
                      Nous vous remercions de l’intérêt que vous portez au
                      programme d’essai routier à la découverte de Lincoln. En
                      tant que propriétaire d’une marque compétitive, nous
                      sommes ravis que vous puissiez découvrir tout ce que
                      Lincoln a à offrir. Il vous suffit de remplir ce
                      formulaire et un ambassadeur de l’Expérience découverte
                      Lincoln planifiera un essai routier prolongé au détaillant Lincoln
                      participant le plus près de l’adresse que vous indiquerez.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <form className="container" onSubmit={handleSubmit(onSubmit)}>
            <label>
              <div className="field-label">
                Prénom
                {errors.firstName && (
                  <p className="error-label">{errors.firstName.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Prénom"
                {...register('firstName')}
              />
            </label>
            <label>
              <div className="field-label">
                Nom de famille
                {errors.lastName && (
                  <p className="error-label">{errors.lastName.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Nom de famille"
                {...register('lastName')}
              />
            </label>
            <label>
              <div className="field-label">
                Adresse
                {errors.address && (
                  <p className="error-label">{errors.address.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Adresse"
                {...register('address')}
              />
            </label>
            <label>
              <div className="field-label">
                Code postal
                {errors.postalCode && (
                  <p className="error-label">{errors.postalCode.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Code postal"
                {...register('postalCode')}
              />
            </label>
            <label>
              <div className="field-label">
                Ville
                {errors.city && (
                  <p className="error-label">{errors.city.message}</p>
                )}
              </div>
              <input type="text" placeholder="Ville" {...register('city')} />
            </label>
            <label>
              <div className="field-label">
                Province
                {errors.province && (
                  <p className="error-label">{errors.province.message}</p>
                )}
              </div>
              <select defaultValue={''} {...register('province')}>
                <option value="">Sélectionner</option>
                <option value="Alberta">Alberta</option>
                <option value="British Columbia">British Columbia</option>
                <option value="Manitoba">Manitoba</option>
                <option value="New Brunswick">New Brunswick</option>
                <option value="Newfoundland and Labrador">
                  Newfoundland and Labrador
                </option>
                <option value="Northwest Territories">
                  Northwest Territories
                </option>
                <option value="Nova Scotia">Nova Scotia</option>
                <option value="Nunavut">Nunavut</option>
                <option value="Ontario">Ontario</option>
                <option value="Prince Edward Island">
                  Prince Edward Island
                </option>
                <option value="Quebec">Quebec</option>
                <option value="Saskatchewan">Saskatchewan</option>
                <option value="Yukon Territory">Yukon Territory</option>
              </select>
            </label>
            <label>
              <div className="field-label">
                Adresse courriel
                {errors.email && (
                  <p className="error-label">{errors.email.message}</p>
                )}
              </div>
              <input
                type="text"
                placeholder="Adresse courriel"
                {...register('email')}
              />
            </label>
            <label>
              <div className="field-label">
                Téléphone
                <span className="optional-label">(facultatif)</span>
                {errors.phoneNumber && (
                  <p className="error-label">{errors.phoneNumber.message}</p>
                )}
              </div>
              <input
                type="tel"
                placeholder="Téléphone"
                {...register('phoneNumber')}
              />
            </label>

            <label>
              <div className="field-label">
                Moyen de communication privilégié
                {errors.preferedContact && (
                  <p className="error-label">
                    {errors.preferedContact.message}
                  </p>
                )}
              </div>
              <select {...register('preferedContact')}>
                <option value="">Sélectionner</option>
                <option value="Email">Adresse courriel</option>
                <option value="Phone">Téléphone</option>
              </select>
            </label>

            <label>
              <div className="field-label">
                Modèle pour l’essai routier
                {errors.testDriveModel && (
                  <p className="error-label">{errors.testDriveModel.message}</p>
                )}
              </div>
              <select defaultValue={''} {...register('testDriveModel')}>
                <option value="">Sélectionner</option>
                <option value="Corsair">Corsair</option>
                <option value="Aviator">Aviator</option>
                <option value="Nautilus">Nautilus</option>
                <option value="Navigator">Navigator</option>
              </select>
            </label>

            <label>
              <div className="field-label">
              Marque/modèle du véhicule
                {errors.currentMakeModel && (
                  <p className="error-label">
                    {errors.currentMakeModel.message}
                  </p>
                )}
              </div>
              <input
                type="text"
                placeholder="Marque/modèle du véhicule"
                {...register('currentMakeModel')}
              />
            </label>

            <label className="special-requests">
              <div className="field-label">
                Demandes spéciales
                <span className="optional-label">(facultatif)</span>
              </div>
              <textarea {...register('specialRequest')} />
            </label>
            <div className="submit-section">
              <button type="submit">Soumettre</button>
            </div>
          </form>
          <div className="container footer-section">
            <p className="text-center mt-30">
              Une fois que vous aurez soumis votre formulaire, un ambassadeur de
              l’Expérience découverte Lincoln communiquera avec vous dans les 24
              heures pour planifier votre essai routier.
            </p>
          </div>
        </div>
      )}
      <Footer />
    </>
  )
}

export default TestDriveForm
