import React, { Component } from 'react'
import { register, resetAuthErrorState } from '../data/actions/authActions'
import { connect } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import Header from '../containers/header'
import Footer from '../components/footer'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class Register extends Component {
  state = {
    email: '',
    password: '',
    dealerName: '',
    dealerCode: '',
    region: '',
    dealerType: '',
    userRole: '',
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  registerSuccessCallback = () => {
    return <Navigate to="/thank-you" />
  }

  registerErrorCallback = err => {
    if (err && err.message) {
      this.showErrorToast(`${err.message}`)
    } else {
      this.showErrorToast(
        'An unexpeccted error occurred. Please review the form fields and retry.'
      )
    }
    this.props.resetAuthErrorState()
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.formValidate()) {
      return false
    }
    this.props.register(
      this.state,
      this.registerSuccessCallback,
      this.registerErrorCallback
    )
  }

  showErrorToast(message) {
    toast.error(message)
    this.props.resetAuthErrorState()
  }

  formValidate = () => {
    if (!this.state.email) {
      toast.error('Email is required')
      return false
    }
    if (!this.state.password) {
      toast.error('Password is required')
      return false
    }
    if (!this.state.dealerName) {
      toast.error('Retailer Name is required')
      return false
    }
    if (!this.state.dealerCode) {
      toast.error('Retailer Code is required')
      return false
    }
    if (!this.state.region) {
      toast.error('Region is required')
      return false
    }
    if (!this.state.dealerType) {
      toast.error('Retailer Type is required')
      return false
    }
    if (!this.state.userRole) {
      toast.error('Role is required')
      return false
    }
    return true
  }

  render() {
    const { auth } = this.props
    if (!auth.uid) return <Navigate to="/" />
    return (
      <React.Fragment>
        <Header />
        <div className="container mt-20 mb-20">
          <form onSubmit={this.handleSubmit}>
            <h5 className="grey-text text-darken-3">Create Account</h5>
            <div className="input-field">
              <label htmlFor="dealerName">Retailer Name</label>
              <input
                required
                className="form-control"
                type="text"
                id="dealerName"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-field">
              <label htmlFor="dealerCode">Retailer Code</label>
              <input
                required
                className="form-control"
                type="text"
                id="dealerCode"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-field">
              <label htmlFor="region">Region</label>
              {/* <input required className="form-control" type="text" id="region" onChange={this.handleChange} /> */}
              <select
                required
                className="form-control"
                type="text"
                id="region"
                name="region"
                onChange={this.handleChange}
                defaultValue=""
              >
                <option value="">Please select</option>
                <option value="Central">Central</option>
                <option value="Eastern">Eastern</option>
                <option value="Western">Western</option>
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="dealerType">Retailer Type</label>
              {/* <input required className="form-control" type="text" id="dealerType" onChange={this.handleChange} /> */}
              <select
                required
                className="form-control"
                type="text"
                id="dealerType"
                name="dealerType"
                onChange={this.handleChange}
                defaultValue=""
              >
                <option value="">Please select</option>
                <option value="Contact">Contact</option>
                <option value="Non-Contact">Non-Contact</option>
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="userRole">User Role</label>
              {/* <input className="form-control" type="text" id="userRole" onChange={this.handleChange} /> */}
              <select
                required
                className="form-control"
                type="text"
                id="userRole"
                name="userRole"
                onChange={this.handleChange}
                defaultValue=""
              >
                <option value="">Please select</option>
                <option value="Admin">Admin</option>
                <option value="Dealer">Dealer</option>
                <option value="Regional">Regional</option>
                <option value="National">National</option>
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                required
                className="form-control"
                type="email"
                id="email"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                required
                className="form-control"
                type="password"
                id="password"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-field">
              <button className="btn btn-primary">Register Retailer</button>
            </div>
          </form>
          <p className="grey-text center">
            Already have an account?{' '}
            <Link to="/" className="teal-text">
              Log In
            </Link>
          </p>
        </div>
        <Footer />
        <ToastContainer
          position="top-center"
          hideProgressBar="true"
          autoClose="5000"
          style={{ width: '600px' }}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    register: (newUser, registerSuccessCallback, registerErrorCallback) =>
      dispatch(
        register(newUser, registerSuccessCallback, registerErrorCallback)
      ),
    resetAuthErrorState: () => dispatch(resetAuthErrorState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
