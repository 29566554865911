import React from 'react'
import { CSVLink } from 'react-csv'
import _ from 'lodash'

const NationalCSV = props => {
  const { users, clients, profile, filename, isFrench } = props

  //  Varriables
  const [csvDealer, setCSVDealer] = React.useState([])
  // Functions

  React.useEffect(() => {
    if (users) {
      const _csvDealer = []
      users.forEach(user => {
        user = _.cloneDeep(user)
        if (user.userRole && user.userRole === 'Dealer') {
          let clientMatch = []
          let clientApproved = []
          let clientCredits = [0]
          user.confirmedSales = 0
          user.lddMonth = user.dealerType === 'Contact' ? 240 : 120

          if (clients) {
            clients.forEach(client => {
              client = _.cloneDeep(client)
              if (client.dealerCode === user.dealerCode) {
                clientMatch.push(client)
                if (client.validated === 'true') {
                  clientApproved.push(client)
                  let contact
                  contact = client.maxLddMonth === '24h' ? 150 : 250
                  clientCredits.push(contact)
                }
                if (
                  client.hasPurchased &&
                  (client.hasPurchased === 'on' ||
                    client.hasPurchased === 'true' ||
                    client.hasPurchased === true)
                ) {
                  user.confirmedSales += 1
                }
              }
            })
          }

          if (profile.userRole === 'Regional') {
            if (profile.region === user.region) {
              user.registeredTestDrives = clientMatch.length
              user.approvedTestDrives = clientApproved.length
              user.totalDealerCredits = clientCredits.reduce((a, b) => a + b)
              _csvDealer.push(user)
            }
          }
          if (profile.userRole === 'Admin' || profile.userRole === 'National') {
            user.registeredTestDrives = clientMatch.length
            user.approvedTestDrives = clientApproved.length
            user.totalDealerCredits = clientCredits.reduce((a, b) => a + b)
            _csvDealer.push(user)
          }
        }
      })
      setCSVDealer(_csvDealer)
    }
  }, [props])

  // Final Varriables
  const csvHeaders = [
    { label: 'Dealer Code', key: 'dealerCode' },
    { label: 'Dealer Name', key: 'dealerName' },
    { label: 'Region', key: 'region' },
    { label: 'Total Registered Test Drives', key: 'registeredTestDrives' },
    { label: 'Approved Test Drives', key: 'approvedTestDrives' },
    { label: 'Dealer LDD Allocation', key: 'lddMonth' },
    { label: 'Total Dealer Credits', key: 'totalDealerCredits' },
    { label: 'Confirmed Sales', key: 'confirmedSales' },
  ]

  return (
    <CSVLink
      className="btn-primary"
      filename={filename + '.csv'}
      data={csvDealer}
      headers={csvHeaders}
      target="_blank"
    >
      {isFrench ? 'TÉLÉCHARGEZ LE RAPPORT' : 'Download CSV Report'}
    </CSVLink>
  )
}

export default NationalCSV
