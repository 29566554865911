import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import '../styles/login.css'
import { signIn, resetAuthErrorState } from '../data/actions/authActions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Login = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleChangeEmail = e => {
    setEmail(e.target.value)
  }

  const handleChangePassword = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.signIn({ email, password })
    // this.props.history.push('/test')
  }

  const showToast = message => {
    toast.error(message)
    props.resetAuthErrorState()
  }

  useEffect(() => {
    if (props.authErrorResponse && props.authErrorResponse.message) {
      showToast(
        `${props.authErrorResponse.message} Please contact the LDD Ambassador at LDDambassador@Lincolndiscoverydrive.com`
      )
    }
  }, [props.authErrorResponse])

  const { auth } = props
  if (auth.uid) return <Navigate to="/" />
  return (
    <React.Fragment>
      <div className="log-in-area pt-60 pb-40">
        <div className="container">
          <div className="row">
            <div id="#login" className="col-lg-8 offset-lg-1">
              <h4 className="log-in-title">Login</h4>
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="single-log-in clearfix pt-30{{ $errors->has('username') ? ' has-error' : '' }}">
                  <div className="user">
                    <label htmlFor="username">User name</label>
                  </div>
                  <div className="log-inform">
                    <input
                      id="email"
                      onChange={handleChangeEmail}
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      required
                      autoFocus
                    />
                  </div>
                </div>
                <div className="single-log-in clearfix pt-30 {{ $errors->has('password') ? ' has-error' : '' }}">
                  <div className="user">
                    <label htmlFor="">Password</label>
                  </div>
                  <div className="log-inform">
                    <input
                      id="password"
                      onChange={handleChangePassword}
                      type="password"
                      className="form-control"
                      name="password"
                      required
                    />
                  </div>
                </div>
                {/* add error handeler here  */}
                <div className="single-log-in clearfix pt-50">
                  <div className="log-inform text-center">
                    <button>submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="intro-text text-center">
                <p>
                  Get started by logging in here with your User Name and unique
                  password provided by LDD Headquarters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        hideProgressBar="true"
        autoClose="5000"
        style={{ width: '600px' }}
      />
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  signIn: creds => dispatch(signIn(creds)),
  resetAuthErrorState: () => dispatch(resetAuthErrorState()),
})

const mapStateToProps = state => ({
  authError: state.auth.authError,
  auth: state.firebase.auth,
  authErrorResponse: state.auth.authErrorResponse,
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
