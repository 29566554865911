import React, { Component } from 'react'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'
import UpdatedClientFormFr from '../components/clientFormUpdateFr'
import { connect } from 'react-redux'
import { updateClient } from './../data/actions/clientActions'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import dateFormat from 'dateformat'
import { Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BallTriangle } from 'react-loader-spinner'

const updatedOnFormatted = dateFormat(new Date(), 'dd/mm/yy h:MM TT')
class ClientUpdateFormFr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      client: {
        clientFirstName: '',
        clientLastName: '',
        clientAddress: '',
        clientCity: '',
        clientProvince: '',
        clientPostalCode: '',
        clientEmail: '',
        clientCellPhone: '',
        broughtDealership: '',
        currentVehicle: '',
        vehicleInterest: '',
        purchaseTimeframe: '',
        testDriveDate1: '',
        testDriveDate2: '',
        dropoffLocation: '',
        dropoffAltAddress: '',
        communicationMethod: '',
        timeStart: '',
        timeEnd: '',
        supriseGift: '',
        comments: '',
        dealerComments: '',
        orderID: '',
        pendingValidation: '',
        validated: false,
        hasPurchased: '',
        followUp: '',
        maxLddMonth: '',
        updatedOn: updatedOnFormatted,
        referralCertNum: '',
        isReferralCert: false,
      },
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.clients !== previousProps.clients) {
      let clientData
      const { id } = this.props.match.params
      const { clients } = this.props
      clients.forEach(client => {
        client = { ...client }
        if (client.id === id) {
          client.updatedOn = client.updatedOn
            ? client.updatedOn
            : updatedOnFormatted
          if (!client.timeStart && client.client && client.client.timeStart) {
            client.timeStart = client.client.timeStart
          }
          if (!client.timeEnd && client.client && client.client.timeEnd) {
            client.timeEnd = client.client.timeEnd
          }
          clientData = client
        }
      })
      this.setState({
        client: clientData,
      })
    }
  }

  componentDidMount() {
    let clientData
    const { id } = this.props.match.params
    const { clients } = this.props
    if (clients) {
      clients.forEach(client => {
        client = { ...client }
        if (client.id === id) {
          client.updatedOn = client.updatedOn
            ? client.updatedOn
            : updatedOnFormatted
          clientData = client
        }
      })
      this.setState({
        client: clientData,
      })
      this.forceUpdate()
    }
  }

  handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    const { client } = this.state
    this.setState({
      client: {
        ...client,
        [name]: value,
      },
    })
  }

  handleRadioChange = e => {
    this.setState({
      [e.target.name]: e.target.id,
    })
  }

  updateClientSucccessCallback = () => {
    this.state({ processing: false })

    return <Navigate to="/thank-you-fr" />
  }

  updateClientErrorCallback = err => {
    this.setState({ processing: false })

    if (err && err.message) {
      toast.error(`${err.message}`)
    } else {
      toast.error(
        'An unexpeccted error occurred. Please review the form fields and retry.'
      )
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.state.client.followUp) {
      toast.error('Follow Up date is required.')
      return false
    }
    this.setState({ processing: true })

    const { client } = this.state

    this.props.updateClient(
      client,
      this.updateClientSucccessCallback,
      this.updateClientErrorCallback
    )
    //this.props.history.push("/thank-you-fr");
  }

  handleCheckbox = e => {
    const { name } = e.target
    const { client } = this.state
    let val = client[name]
    this.setState({
      client: {
        ...client,
        [name]: !val,
      },
    })
  }

  render() {
    const { auth, profile } = this.props
    if (!auth.uid) return <Navigate to="/fr" />
    let { client } = this.state

    const form = (
      <UpdatedClientFormFr
        handleCheckbox={this.handleCheckbox}
        auth={auth}
        profile={profile}
        client={client}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleRadioChange={this.handleRadioChange}
      />
    )

    return (
      <React.Fragment>
        <HeaderFr />
        {this.processing && (
          <div
            style={{
              backdropFilter: 'blur(10px)',
              width: '100vw',
              height: '100vh',
              left: 0,
              position: 'fixed',
              zIndex: 10,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <BallTriangle
              color="#c1bb00"
              height={70}
              width={70}
              wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
            />
            <h3 style={{ paddingTop: '1em', textAlign: 'center' }}>
              Processing
            </h3>
          </div>
        )}
        {form}
        <ToastContainer
          position="top-center"
          hideProgressBar="true"
          autoClose="5000"
          style={{ width: '600px' }}
        />
        <FooterFr />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateClient: (
      client,
      updateClientSucccessCallback,
      updateClientErrorCallback
    ) =>
      dispatch(
        updateClient(
          client,
          updateClientSucccessCallback,
          updateClientErrorCallback
        )
      ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'clients' }, { collection: 'users' }])
)(ClientUpdateFormFr)
