import React, { Component } from 'react'
import Header from '../containers/header'
import Footer from '../components/footer'
import DealerValidation from '../components/dealerValidation'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

class ClientValidation extends Component {
  render() {
    const form = <DealerValidation />

    const { auth } = this.props
    if (!auth.uid) return <Navigate to="/" />

    return (
      <React.Fragment>
        <Header />
        {form}
        <Footer />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'clients' }])
)(ClientValidation)
