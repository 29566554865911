import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createClient } from './../data/actions/clientActions'
import { Link } from 'react-router-dom'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'

class ClientUpdateSearchFr extends Component {
  state = {
    clients: [],
    actionID: '',
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  render() {
    const actionLink = '/client-update-fr/' + this.state.actionID

    return (
      <React.Fragment>
        <HeaderFr />
        <div className="container pb-60 pt-60">
          <form className="interior-form">
            <div className="row">
              <div className="col-sm-12 registration-section-title pb-20">
                <h4 className="section-title">FORMULAIRE DE RECHERCHE</h4>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">NUMÉRO DE COMMANDE</p>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="actionID"
                  onChange={this.handleChange}
                />
                <p>
                  <Link to="/dealer-report-fr">CLIQUEZ ICI</Link> POUR TROUVER
                  LE NUMÉRO DE COMMANDE DE VOTRE CLIENT
                </p>
              </div>
              {/* <div className="col-md-1 form-label">
                  <p className="label">Or</p>
                </div> */}
            </div>
            {/* <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Client First Name</p>
                </div>
                <div className="col-md-9">
                  <input type="text" className="form-control" name="first_name" value=""/>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Client Last Name</p>
                </div>
                <div className="col-md-9">
                  <input type="text" className="form-control" name="last_name" value="" />
                </div>
              </div> */}
            <div className="row">
              <div className="offset-md-3 col-md-3">
                <Link to={actionLink}>
                  <button className="btn btn-primary">Soumettre</button>
                </Link>
              </div>
            </div>
          </form>
        </div>
        <FooterFr />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createClient: client => dispatch(createClient(client)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientUpdateSearchFr)
