export const updateAdminReportFilter = params => {
  return dispatch => {
    dispatch({
      type: 'ADMIN_REPORT_FILTER',
      payload: params,
    })
  }
}

export const updateAdminReportSort = params => {
  return dispatch => {
    dispatch({
      type: 'ADMIN_REPORT_SORT',
      payload: params,
    })
  }
}
