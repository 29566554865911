import React from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import styled from 'styled-components'
import dateFormat from 'dateformat'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BallTriangle } from 'react-loader-spinner'

import { updateClient } from './../data/actions/clientActions'

import Header from '../containers/header'
import Footer from '../components/footer'

const UpdatedOnInput = styled.input`
  width: 60%;
  border: none;
`

const updatedOnFormatted = dateFormat(new Date(), 'dd/mm/yy h:MM TT')

const ClientUpdateForm = props => {
  const [processing, setProcessing] = React.useState(false)
  const [client, setClient] = React.useState({
    clientFirstName: '',
    clientLastName: '',
    clientAddress: '',
    clientCity: '',
    clientProvince: '',
    clientPostalCode: '',
    clientEmail: '',
    clientCellPhone: '',
    currentVehicle: '',
    testDriveVIN: '',
    broughtDealership: '',
    currentVehicle: '',
    vehicleInterest: '',
    purchaseTimeframe: '',
    testDriveDate1: '',
    testDriveDate2: '',
    dropoffLocation: '',
    dropoffAltAddress: '',
    communicationMethod: '',
    timeStart: '',
    timeEnd: '',
    supriseGift: '',
    comments: '',
    dealerComments: '',
    orderID: '',
    pendingValidation: '',
    validated: false,
    hasPurchased: '',
    followUp: '',
    maxLddMonth: '',
    updatedOn: updatedOnFormatted,
    referralCertNum: '',
    isReferralCert: false,
  })
  const params = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    const { id } = params
    const { clients } = props
    if (!clients) {
      return
    }
    const clientData = clients.find(client => client.id === id)
    if (clientData) {
      const updatedClient = { ...clientData }
      updatedClient.updatedOn = updatedClient.updatedOn
        ? updatedClient.updatedOn
        : updatedOnFormatted
      if (
        !updatedClient.timeStart &&
        updatedClient.client &&
        updatedClient.client.timeStart
      ) {
        client.timeStart = updatedClient.client.timeStart
      }
      if (
        !updatedClient.timeEnd &&
        updatedClient.client &&
        updatedClient.client.timeEnd
      ) {
        updatedClient.timeEnd = updatedClient.client.timeEnd
      }
      setClient(updatedClient)
    }
  }, [props.clients])

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setClient({
      ...client,
      [name]: value,
    })
  }

  const updateClientSucccessCallback = () => {
    setProcessing(false)
    navigate('/thank-you')
  }

  const updateClientErrorCallback = err => {
    setProcessing(false)
    if (err && err.message) {
      toast.error(`${err.message}`)
    } else {
      toast.error(
        'An unexpeccted error occurred. Please review the form fields and retry.'
      )
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!client.followUp) {
      toast.error('Follow Up date is required.')
      return false
    }
    setProcessing(true)
    props.updateClient(
      client,
      updateClientSucccessCallback,
      updateClientErrorCallback
    )
    //props.history.push("/thank-you");
  }

  if (client) {
    const { auth } = props
    if (!auth.uid) return <Navigate to="/" />

    const follow = !client.followUp ? (
      <input
        type="date"
        className="form-control"
        id="followUp"
        name="followUp"
        required
        onChange={handleChange}
        value={client.followUp}
        //defaultValue={client.followUp}
      />
    ) : (
      <input
        type="date"
        className="form-control"
        id="followUp"
        name="followUp"
        onChange={handleChange}
        // defaultValue={client.followUp}
        required
        value={client.followUp}
      />
    )

    return (
      <React.Fragment>
        <Header />
        <div className="container pt-60 pb-60" style={{ position: 'relative' }}>
          {processing && (
            <div
              style={{
                backdropFilter: 'blur(10px)',
                width: '100vw',
                height: '100vh',
                left: 0,
                position: 'fixed',
                zIndex: 10,
                top: 0,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <BallTriangle
                color="#c1bb00"
                height={70}
                width={70}
                wrapperStyle={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
              <h3 style={{ paddingTop: '1em', textAlign: 'center' }}>
                Processing
              </h3>
            </div>
          )}
          <form className="interior-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 registration-section-title pb-20">
                <h4 className="section-title">Client Update Form</h4>
              </div>
              <div className="col-sm-12">
                <div className="alert alert-danger pt-20" role="alert">
                  If this page loads empty please refresh the page to load in
                  the correct data. <i className="fas fa-redo-alt"></i>
                </div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Dealer Code</p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="dealerCode"
                  onChange={handleChange}
                  name="dealerCode"
                  defaultValue={client.dealerCode}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Client First Name</p>
              </div>
              <div className="col-md-9 input-form">
                <input
                  type="text"
                  className="form-control"
                  id="clientFirstName"
                  onChange={handleChange}
                  name="clientFirstName"
                  defaultValue={client.clientFirstName}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Client Last Name</p>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  id="clientLastName"
                  className="form-control"
                  onChange={handleChange}
                  name="clientLastName"
                  defaultValue={client.clientLastName}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Street Address</p>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="clientAddress"
                  name="clientAddress"
                  onChange={handleChange}
                  defaultValue={client.clientAddress}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">City</p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  id="clientCity"
                  name="clientCity"
                  defaultValue={client.clientCity}
                  required
                />
              </div>
              <div className="col-md-1 form-label">
                <p className="label">Province</p>
              </div>
              <div className="col-md-2">
                <input
                  required
                  maxLength={2}
                  type="text"
                  className="form-control"
                  id="clientProvince"
                  onChange={handleChange}
                  name="clientProvince"
                  defaultValue={client.clientProvince}
                />
              </div>
              <div className="col-md-1 form-label">
                <p className="label">Postal Code</p>
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  id="clientPostalCode"
                  onChange={handleChange}
                  name="clientPostalCode"
                  defaultValue={client.clientPostalCode}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Client Email</p>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  id="clientEmail"
                  name="clientEmail"
                  defaultValue={client.clientEmail}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">Client Cell Phone</p>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="clientCellPhone"
                  onChange={handleChange}
                  name="clientCellPhone"
                  defaultValue={client.clientCellPhone}
                  required
                />
              </div>
            </div>
            <div className="row form-group mt-30">
              <div className="col-md-3 form-label">
                <p className="label">*CURRENT COMPETITIVE VEHICLE BRAND</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="currentVehicle"
                  onChange={handleChange}
                  name="currentVehicle"
                  defaultValue={client.currentVehicle}
                  placeholder="Current Competitive Brand"
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-3 form-label">
                <p className="label">*CURRENT COMPETITIVE VEHICLE VIN</p>
              </div>
              <div className="col-md-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="testDriveVIN"
                  onChange={handleChange}
                  name="testDriveVIN"
                  defaultValue={client.testDriveVIN}
                  // pattern="[A-Za-z0-9]{17}"
                  placeholder="VIN must be a 17-digit alphanumeric value"
                  maxLength={17}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">Start Time &amp; Date</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <span>
                        {client.dateStart} : {client.timeStart}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">End Time &amp; Date</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <span>
                        {client.dateEnd} : {client.timeEnd}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-group mt-15">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">Completed Follow-Up</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      {follow}
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="fa fa-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">24h or 48h</p>
                  </div>
                  <div className="col-md-9">
                    <div className="input-group">
                      <select
                        value={client.maxLddMonth || ''}
                        className="form-control"
                        onChange={handleChange}
                        multiple={false}
                        name="maxLddMonth"
                        id="maxLddMonth"
                      >
                        <option value="" disabled>
                          Select Option
                        </option>
                        <option value="24h">24h</option>
                        <option value="48h">48h</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-2 form-label">
                <p className="label">Dealer Comments</p>
              </div>
              <div className="col-md-10">
                {/* <textarea
                    rows="7"
                    type="text"
                    className="form-control"
                    id="dealerComments"
                    onChange={handleChange}
                    name="dealerComments"
                    value={client.dealerComments}
                  >
                    Enter your comments here...
                  </textarea> */}
                <p>{client.dealerComments}</p>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-2 form-label">
                <p className="label">Comments</p>
              </div>
              <div className="col-md-10">
                <textarea
                  rows="7"
                  type="text"
                  className="form-control"
                  id="comments"
                  onChange={handleChange}
                  name="comments"
                  value={client.comments}
                >
                  Enter your comments here...
                </textarea>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">Approved or Denied</p>
                  </div>
                  <div className="col-md-3">
                    {/* <div className="input-group">
                        <input
                          type="checkbox"
                          name="validated"
                          id="validated"
                          onChange={handleChange}
                          value={validValue}
                        />
                      </div> */}
                    <div className="input-group approved">
                      <select
                        value={client.validated || ''}
                        className="form-control"
                        onChange={handleChange}
                        multiple={false}
                        name="validated"
                        id="validated"
                      >
                        <option value="">Select Option</option>
                        <option value="true">Approved</option>
                        <option value="false">Denied</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="col-md-4">
                   <div className="row">
                    <div className="col-md-3 form-label">
                      <p className="label">Denied</p>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="checkbox"
                          name="hasPurchased"
                          id="hasPurchased"
                          onChange={handleChange}
                          value={deniedValue}
                        />
                      </div>
                    </div>
                  </div> 
                </div>*/}
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 form-label">
                    <p className="label">Purchased Vehicle</p>
                  </div>
                  <div className="col-md-3">
                    <div className="input-group">
                      <input
                        type="checkbox"
                        name="hasPurchased"
                        id="hasPurchased"
                        onChange={event => {
                          setClient({
                            ...client,
                            hasPurchased: !client.hasPurchased,
                          })
                        }}
                        checked={
                          client.hasPurchased === 'on' ||
                          client.hasPurchased === 'true' ||
                          client.hasPurchased === true
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-group mt-15 pb-10">
              <div className="col-md-12">
                <div className="row">
                  <div className="form-label col-md-6">
                    <p className="label">
                      Was this LDD through a Referral Certificate?
                    </p>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={() => {
                        setClient({
                          ...client,
                          isReferralCert: !client.isReferralCert,
                        })
                      }}
                      id="isReferralCert"
                      name="isReferralCert"
                      checked={
                        client.isReferralCert === 'on' ||
                        client.isReferralCert === 'true' ||
                        client.isReferralCert === true
                      }
                    />
                  </div>
                  <div className="form-label col-md-6">
                    <p className="label">If YES enter Referral Certificate #</p>
                    <input
                      disabled={!client.isReferralCert}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      id="referralCertNum"
                      name="referralCertNum"
                      value={client.referralCertNum}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-sm-12 text-center">
                <p>
                  All information must be completed in full in order to submit.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 text-right">
                <button className="btn btn-primary">Submit</button>
              </div>
              <div className="col-sm-6 text-right">
                <p>
                  Updated on:{' '}
                  <UpdatedOnInput
                    type="text"
                    name="updatedOn"
                    id="updatedOn"
                    value={client.updatedOn}
                    readOnly
                  />
                </p>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer
          position="top-center"
          hideProgressBar="true"
          autoClose="5000"
          style={{ width: '600px' }}
        />
        <Footer />
      </React.Fragment>
    )
  } else {
    return <div>Opps no client details here!</div>
  }
}

const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateClient: (
      client,
      updateClientSucccessCallback,
      updateClientErrorCallback
    ) =>
      dispatch(
        updateClient(
          client,
          updateClientSucccessCallback,
          updateClientErrorCallback
        )
      ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'clients' }, { collection: 'users' }])
)(ClientUpdateForm)
