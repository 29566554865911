import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Header from './header'
import Footer from '../components/footer'
import { validateClient } from '../data/actions/clientActions'
import { Navigate } from 'react-router-dom'
import M from 'materialize-css'
import '../styles/timepicker.css'
// import 'materialize-css/dist/css/materialize.min.css'
import { BallTriangle } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class ClientUpdateValidation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client: {
        broughtDealership: '',
        clientAddress: '',
        clientCellPhone: '',
        clientCity: '',
        clientEmail: '',
        clientFirstName: '',
        clientLastName: '',
        clientPostalCode: '',
        clientProvince: '',
        dealerComments: '',
        communicationMethod: '',
        currentVehicle: '',
        dateEnd: '',
        dateStart: '',
        timeStart: '',
        timeEnd: '',
        dealerCode: '',
        dealerName: '',
        dropoffEmail: '',
        dropoffLocation: '',
        hasPurchased: false,
        id: '',
        orderID: '',
        pendingValidation: false,
        purchaseTimeframe: '',
        supriseGift: '',
        testDriveDate1: '',
        testDriveDate2: '',
        testDriveVIN: '',
        uid: '',
        validated: null,
        vehicleInterest: '',
        submission: '',
        maxLddMonth: '',
      },
    }
    // this.handleChange = this.handleChange.bind(this)
    // this.handleChange = this.handleDateChange.bind(this)
    // this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.clients !== previousProps.clients) {
      let clientData
      const { id } = this.props.match.params
      const { clients } = this.props
      clients.forEach(client => {
        if (client.id === id) {
          if (!client.timeStart && client.client && client.client.timeStart) {
            client.timeStart = client.client.timeStart
          }
          if (!client.timeEnd && client.client && client.client.timeEnd) {
            client.timeEnd = client.client.timeEnd
          }
          clientData = client
        }
      })
      this.setState({
        client: clientData,
      })
    }
  }

  componentDidMount() {
    let clientData
    const { id } = this.props.match.params
    const { clients } = this.props
    if (clients) {
      clients.forEach(client => {
        if (client.id === id) {
          clientData = client
        }
      })
      this.setState({
        client: clientData,
      })
    }
  }

  //   handleChange(e){
  //     e.preventDefault()
  //     this.setState({
  //       client: {
  //         [e.target.id]: e.target.value
  //       }
  //     })
  //  }

  handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({
      client: {
        ...this.state.client,
        [name]: value,
      },
    })
  }

  // handleDateChange = (date) => {
  //   this.setState({
  //     startDate: date
  //   })
  // }

  handleRadioChange = e => {
    this.setState({
      [e.target.name]: e.target.id,
    })
  }

  validateClientSuccessCallback = () => {
    return <Navigate to="/thank-you" />
  }

  validateClientErrorCallback = err => {
    if (err && err.message) {
      toast.error(`${err.message}`)
    } else {
      toast.error(
        'An unexpeccted error occurred. Please review the form fields and retry.'
      )
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({
      client: {
        ...this.state.client,
        pendingValidation: false,
      },
    })
    this.props.validateClient(
      this.state.client,
      this.validateClientSuccessCallback,
      this.validateClientErrorCallback
    )
    //this.props.history.push("/thank-you")
  }

  setTimeField = (s, e) => {
    this.setState({
      client: {
        ...this.state.client,
        timeStart: s.value,
        timeEnd: e.value,
      },
    })
  }

  render() {
    const { auth } = this.props
    if (!auth.uid) return <Navigate to="/" />

    const { client } = this.state
    if (client) {
      const elems = document.querySelectorAll('.timepicker')
      const ts = document.getElementById('timeStart')
      const te = document.getElementById('timeEnd')
      const options = {
        onCloseStart: () => {
          this.setTimeField(ts, te)
        },
      }
      M.Timepicker.init(elems, options)

      return (
        <React.Fragment>
          <Header />
          <div className="container pt-60 pb-60">
            <form className="interior-form" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-sm-12 registration-section-title pb-20">
                  <h4 className="section-title">Registration Form</h4>
                  <p className="pt-20">
                    This form must be filled out in full within 48 hours of a
                    Client completing a Lincoln Discovery Drive, in order to
                    receive credit from Lincoln Canada
                  </p>
                </div>
                <div className="col-sm-12">
                  <div className="alert alert-danger pt-20" role="alert">
                    If this page is empty, please refresh the page to load
                    current data. <i className="fas fa-redo-alt"></i>
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Dealer Code</p>
                </div>
                <div className="col-md-3">
                  <p className="read-only text-right">{client.dealerCode}</p>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Order Number</p>
                </div>
                <div className="col-md-3">
                  <p className="read-only text-right">{client.id}</p>
                </div>
              </div>
              {/* <input  type="text" className="form-control" id="uid" onChange={this.handleChange}  name="uid"  /> */}
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Client First Name</p>
                </div>
                <div className="col-md-9 input-form">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="clientFirstName"
                    onChange={this.handleChange}
                    name="clientFirstName"
                    defaultValue={client.clientFirstName}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Client Last Name</p>
                </div>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    id="clientLastName"
                    className="form-control"
                    onChange={this.handleChange}
                    name="clientLastName"
                    defaultValue={client.clientLastName}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Address</p>
                </div>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="clientAddress"
                    name="clientAddress"
                    onChange={this.handleChange}
                    defaultValue={client.clientAddress}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">City</p>
                </div>
                <div className="col-md-3">
                  <input
                    required
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    id="clientCity"
                    name="clientCity"
                    defaultValue={client.clientCity}
                  />
                </div>
                <div className="col-md-1 form-label">
                  <p className="label">Province</p>
                </div>
                <div className="col-md-2">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="clientProvince"
                    onChange={this.handleChange}
                    name="clientProvince"
                    defaultValue={client.clientProvince}
                  />
                </div>
                <div className="col-md-1 form-label">
                  <p className="label">Postal Code</p>
                </div>
                <div className="col-md-2">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="clientPostalCode"
                    onChange={this.handleChange}
                    name="clientPostalCode"
                    defaultValue={client.clientPostalCode}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Client Email</p>
                </div>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    id="clientEmail"
                    name="clientEmail"
                    defaultValue={client.clientEmail}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Client Phone</p>
                </div>
                <div className="col-md-9">
                  <input
                    required
                    type="tel"
                    className="form-control"
                    id="clientCellPhone"
                    onChange={this.handleChange}
                    name="clientCellPhone"
                    defaultValue={client.clientCellPhone}
                  />
                </div>
              </div>

              <div className="row form-group mt-15 pb-10">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 form-label">
                      <p className="label">Test Drive Start Date &amp; Time</p>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          required
                          type="date"
                          placeholder="MM/DD/YYYY"
                          className="form-control"
                          id="dateStart"
                          name="dateStart"
                          defaultValue={client.dateStart}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="input-group">
                        <input
                          required
                          type="text"
                          className="timepicker form-control"
                          id="timeStart"
                          name="timeStart"
                          defaultValue={client.timeStart}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 pt-10">
                  <div className="row">
                    <div className="col-md-3 form-label">
                      <p className="label">Test Drive End Date &amp; Time</p>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          required
                          placeholder="MM/DD/YYYY"
                          type="date"
                          className="form-control "
                          name="dateEnd"
                          id="dateEnd"
                          defaultValue={client.dateEnd}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="input-group">
                        <input
                          required
                          type="text"
                          className="timepicker form-control"
                          name="timeEnd"
                          id="timeEnd"
                          defaultValue={client.timeEnd}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Test Drive VIN</p>
                </div>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="testDriveVIN"
                    onChange={this.handleChange}
                    name="testDriveVIN"
                    defaultValue={client.testDriveVIN}
                    // pattern="\[a-zA-Z0-9]+{2}[\-]\[a-zA-Z0-9]+{8}"
                    placeholder="First 2 - Last 8 (e.g. K1-GUL2S342)"
                  />
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">
                    Suprise And Delight Element Description
                  </p>
                </div>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="supriseGift"
                    onChange={this.handleChange}
                    name="supriseGift"
                    placeholder="Enter Description"
                    defaultValue={client.supriseGift}
                  />
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-3 form-label">
                  <p className="label">Comments</p>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    id="dealerComments"
                    onChange={this.handleChange}
                    name="dealerComments"
                    defaultValue={client.dealerComments}
                    placeholder="Enter Comments Here..."
                  />
                </div>
              </div>

              <div className="row mb-10">
                <div className="col-sm-12 text-center">
                  <p>
                    All information must be completed in full in order to
                    submit.
                  </p>
                </div>
              </div>
              {/* Approval Fields */}

              <div className="row">
                <div className="col-sm-12 text-center">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <ToastContainer
            position="top-center"
            hideProgressBar="true"
            autoClose="5000"
            style={{ width: '600px' }}
          />
          <Footer />
        </React.Fragment>
      )
    } else {
      return (
        <div
          style={{
            minWidth: '100% !important',
            minHeight: '100% !important',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: '120px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ padding: '5em' }}>
            <BallTriangle
              color="#c1bb00"
              height={80}
              width={80}
              wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
            />
            <h1 style={{ paddingTop: '1em' }}>Loading</h1>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    validateClient: (
      client,
      validateClientSuccessCallback,
      validateClientErrorCallback
    ) =>
      dispatch(
        validateClient(
          client,
          validateClientSuccessCallback,
          validateClientErrorCallback
        )
      ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'clients' }])
)(ClientUpdateValidation)
