import React, { Component } from 'react'
import Header from '../containers/header'
import Footer from '../components/footer'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

class Thankyou extends Component {
  render() {
    const { auth } = this.props
    if (!auth.uid) return <Navigate to="/" />

    return (
      <React.Fragment>
        <Header />
        <section className="support pt-80 pb-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="support text-center">
                  <h3>Thank you for your submission.</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(Thankyou)
