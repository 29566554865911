import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import Header from './header'
import Footer from '../components/footer'
import ReactTable, { ReactTableDefaults } from 'react-table'
import 'react-table/react-table.css'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import styled from 'styled-components'
import { BallTriangle } from 'react-loader-spinner'
import { CSVLink } from 'react-csv'

const TableDiv = styled.div`
  width: 100;
`

const DownloadDiv = styled.div`
  width: 100;
  margin-top: 2em;
  margin-bottom: 1em;
`

const Users = props => {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [sortedTableData, setSortedTableData] = useState([])
  const sortedTableDataRef = useRef(null)

  useEffect(() => {
    if (props.users && props.users.length >= 0) {
      setUsers(props.users)
    }
  }, [props.users])

  const csvHeaders = [
    { label: 'Retailer Name', key: 'dealerName' },
    { label: 'Region', key: 'region' },
    { label: 'Retailer Type', key: 'dealerType' },
    { label: 'Retailer Code', key: 'dealerCode' },
    { label: 'userRole', key: 'userRole' },
    { label: 'USER ID', key: 'id' },
    { label: 'Email', key: 'email' },
  ]
  const columnDefaults = {
    ...ReactTableDefaults.column,
    headerClassName: 'wordwrap',
  }
  const { auth } = props
  const userJsx = row => {
    return (
      <span
        style={{ cursor: 'pointer', color: '#0079C1' }}
        onClick={() => {
          navigate('/user-account/' + row.id, {
            state: {
              user: row,
            },
          })
        }}
      >
        <div
          className="userRow"
          dangerouslySetInnerHTML={{ __html: 'Update User' }}
        />
      </span>
    )
  }

  if (!auth.uid) return <Navigate to="/" />
  if (
    props.users &&
    props.users.length > 0 &&
    auth &&
    auth.isLoaded &&
    props.profile &&
    props.profile.userRole &&
    props.profile.userRole === 'Admin'
  ) {
    return (
      <React.Fragment>
        <Header />
        {users ? (
          <TableDiv>
            <DownloadDiv>
              {props.profile.userRole === 'Admin' ? (
                <CSVLink
                  headers={csvHeaders}
                  filename={'lddActiveUsersList.csv'}
                  className="btn btn-primary"
                  data={users}
                  target="_blank"
                >
                  Export to CSV
                </CSVLink>
              ) : null}
            </DownloadDiv>
            <ReactTable
              column={columnDefaults}
              defaultPageSize={10}
              className="-striped -highlight"
              filterable
              showPageSizeOptions={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              data={users}
              onFilteredChange={() => {
                let data =
                  sortedTableDataRef.current.getResolvedState().sortedData
                let sortedData
                if (data) {
                  sortedData = data.map(ele => ele._original)
                }
                setSortedTableData(sortedData || users)
              }}
              ref={sortedTableDataRef}
              onFetchData={(state, instance) => {
                const data = state.sortedData
                let loadedData
                if (data) {
                  loadedData = data.map(ele => ele._original)
                }
                setSortedTableData(loadedData || users)
              }}
              columns={[
                {
                  columns: [
                    {
                      Header: 'EMAIL',
                      accessor: 'email',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        //return String(row[filter.id]).startsWith(filter.value) || String(row[filter.id]).endsWith(filter.value);
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'USER ID',
                      accessor: 'id',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        //return String(row[filter.id]).startsWith(filter.value) || String(row[filter.id]).endsWith(filter.value);
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'Retailer',
                      accessor: 'dealerCode',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        //return String(row[filter.id]).startsWith(filter.value) || String(row[filter.id]).endsWith(filter.value);
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'Retailer NAME',
                      accessor: 'dealerName',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        //return String(row[filter.id]).startsWith(filter.value) || String(row[filter.id]).endsWith(filter.value);
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'Retailer TYPE ',
                      accessor: 'dealerType',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'REGION',
                      accessor: 'region',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'USER ROLE',
                      accessor: 'userRole',
                      Cell: row => (
                        <div style={{ textAlign: 'center' }}>{row.value}</div>
                      ),
                      filterMethod: (filter, row) => {
                        return (
                          row[filter.id] &&
                          filter.id &&
                          row[filter.id]
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                        )
                      },
                    },
                    {
                      Header: 'ACTION',
                      accessor: 'id',
                      sortable: false,
                      filterable: false,
                      Cell: row => userJsx(row.original),
                    },
                  ],
                },
              ]}
            />
          </TableDiv>
        ) : null}
        <Footer />
      </React.Fragment>
    )
  } else {
    return (
      <div
        style={{
          minWidth: '100% !important',
          minHeight: '100% !important',
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          top: '120px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '5em' }}>
          <BallTriangle
            color="#c1bb00"
            height={80}
            width={80}
            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
          />
          <h1 style={{ paddingTop: '1em' }}>Loading</h1>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    users: state.firestore.ordered.users,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'users' }])
)(Users)
